import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { BaseComponent } from '../../../shared/components/abstract-base-component';
import { StatutPointInspection } from '../../../features/inspection/models/statut-point-inspection.enum';
import { StatutPointAudit } from '../../../features/audit/models/statut-point-audit.enum';
import { State } from '../../../state/app.state';
import * as AuditActions from '../../../features/audit/state/audit.actions';
import * as InspectionActions from '../../../features/inspection/state/inspection.actions';
import * as OfflineActions from '../../../features/offline/state/offline.actions';
import { EnumToListe } from '../../../shared/models/enum-to-liste.model';
import { PointAuditDto, PointInspectionDto } from '../../../core/api/client/models';
import { MotifIgnore, motifIgnoreListe } from '../../models/motif-ignore.enum';
import { UserInformation } from '../../../shared/models/user-informations.model'; // TODO: Changer UserInformation pour IdentiteUtilisateur

@Component({
    selector: 'app-ignore-point-panel',
    templateUrl: './ignore-point-panel.component.html',
    styleUrls: ['./ignore-point-panel.component.scss']
})

export class IgnorePointPanelComponent extends BaseComponent implements AfterViewInit, OnInit {
    public haveBeenIngored: boolean = false;
    public selectedPointInspection: PointInspectionDto = null;
    public selectedPointAudit: PointAuditDto;
    public motifIgnoreListe: EnumToListe[] = motifIgnoreListe;
    public ignorePointForm: FormGroup;
    public remarqueRequise = false;
    public fetchJustification = '';

    @Input() set pointInspection(value: PointInspectionDto) {
        if (value) {
            this.selectedPointInspection = value;
            this.haveBeenIngored = value.statut === StatutPointInspection.ignore;
            if (this.haveBeenIngored) {
                const foundItem = motifIgnoreListe.find(motif => motif.champ === value.justification);
                if (foundItem) {
                    this.fetchJustification = foundItem.valeur;
                }
            }
        }
    }
    @Input() set pointAudit(value: PointAuditDto) {
        if (value) {
            this.selectedPointAudit = value;
        }
    }
    @Input() isAuditeur: boolean;
    @Input() currentIdentiteUtilisateur: UserInformation;
    @Input() visible: boolean;
    @Output() visibleChange = new EventEmitter<boolean>();

    constructor(
        private store: Store<State>,
        private cdr: ChangeDetectorRef
    ) {
        super();
        this.initForm();
    }

    ngOnInit(): void {
        this.initFormValues();
    }

    private initForm() {
        this.ignorePointForm = new FormGroup({
            motifIgnore: new FormControl(null, [Validators.required]),
            remarque: new FormControl(null),
        });

        this.store.dispatch(OfflineActions.getProjetsDownloaded());
        this.store.dispatch(OfflineActions.getProjetsAuditDownloaded());
        this.subscribeToFormValueChanges();
        this.selectedPointInspection = null;
    }

    private initFormValues() {
        let motifIgnore: string = null;
        let remarque: string = null;
        if (this.isAuditeur) {
            if (this.selectedPointAudit) {
                motifIgnore = this.selectedPointAudit.justification ? this.selectedPointAudit.justification : null;
                remarque = this.selectedPointAudit.remarque ? this.selectedPointAudit.remarque : null;
            }
        } else if (this.selectedPointInspection) {
            motifIgnore = this.selectedPointInspection.justification ? this.selectedPointInspection.justification : null;
            remarque = this.selectedPointInspection.remarque ? this.selectedPointInspection.remarque : null;
        }

        this.ignorePointForm.controls.motifIgnore.setValue(motifIgnore);
        this.ignorePointForm.controls.remarque.setValue(remarque);
    }

    public onSaveIgnorePointForm() {
        this.ignorePointForm.updateValueAndValidity();
        if (this.ignorePointForm.valid) {

            const formValue = this.ignorePointForm.value;
            const justification = formValue.motifIgnore;
            const remarque = formValue.remarque;

            if (this.isAuditeur) {
                const pointAudit: PointAuditDto = {
                    ...this.selectedPointAudit,
                    statut: StatutPointAudit.ignore,
                    statutGlobal: StatutPointAudit.ignore,
                    justification: justification,
                    remarque: remarque,
                    auditeLe: new Date().getTime(),
                    auditePar: this.currentIdentiteUtilisateur.courriel
                };

                this.store.dispatch(AuditActions.updatePointAudit({ pointAudit }));

            } else {
                const pointInspection: PointInspectionDto = {
                    ...this.selectedPointInspection,
                    statut: StatutPointInspection.ignore,
                    justification: justification,
                    remarque: remarque,
                    inspecteLe: this.selectedPointInspection.inspecteLe === 0 ? new Date().getTime() : this.selectedPointInspection.inspecteLe
                };

                this.store.dispatch(InspectionActions.updatePointInspection({ pointInspection }));
            }

            this.onCancelIgnorePointForm();
        }
    }

    public onCancelIgnorePointForm() {
        this.initFormValues();
        this.closeIgnorePointPanel();
    }

    public closeIgnorePointPanel() {
        this.visible = false;
        this.visibleChange.emit(this.visible);
    }

    private subscribeToFormValueChanges() {
        this.ignorePointForm.valueChanges.pipe(
            takeUntil(this.destroyed)
        ).subscribe((value) => {
            if (value.motifIgnore === MotifIgnore.autre) {
                this.ignorePointForm.controls['remarque'].setValidators([Validators.minLength(1), Validators.required]);
                this.ignorePointForm.controls['remarque'].updateValueAndValidity({ onlySelf: true, emitEvent: true });
                this.remarqueRequise = true;
            } else {
                this.ignorePointForm.controls['remarque'].clearValidators();
                this.ignorePointForm.controls['remarque'].updateValueAndValidity({ onlySelf: true, emitEvent: true });
                this.remarqueRequise = false;
            }
        });
    }

    ngAfterViewInit(): void {
        this.cdr.detectChanges();
    }
}
