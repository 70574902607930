import { Component } from '@angular/core';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import * as projetsActions from '../../../state/projets.actions';
import { State } from '../../../../../state/app.state';
import { ProjetInspectionActions } from '../../../models/projets-inspection-actions.enum';
import { getTaxonomiePourGroupes, getTaxonomiePourGroupesParFirme } from '../../../../../core/store/selectors/taxonomie.selectors';
import { PatchProjetInspectionDto, ProjetCompletDto } from '../../../../../core/api/client/models';
import { TaxonomieGroupeValue } from '../../../../pilotage/models/taxonomie-groupe.enum';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProjetsActionBaseDialog } from '../projets-action-base-dialog';
import { ConfirmationService } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { selectUtilisateurFirme } from '../../../../../state/shared/shared.selectors';

@Component({
    selector: 'app-assign-projet-inspection-dialog',
    templateUrl: './assign-projet-inspection-dialog.component.html',
    styleUrls: ['./assign-projet-inspection-dialog.component.scss']
})
export class AssignProjetInspectionDialogComponent extends ProjetsActionBaseDialog {
    public form: FormGroup;
    public assignText: string = '';
    public itemList: string[] = [];

    private projet: ProjetCompletDto;
    private action: ProjetInspectionActions;
    private set taxonomieGroupes(value: string[]) {
        if (value) {
            this.subscribeToItemList(value);
        }
    }

    constructor(
        private store: Store<State>,
        private config: DynamicDialogConfig,
        private fb: FormBuilder,
        protected ref: DynamicDialogRef,
        protected confirmationService: ConfirmationService,
    ) {
        super(ref, confirmationService);
        this.initForm();
        this.projet = this.config.data?.projet;
        this.assignText = this.config.data?.assignText;
        this.taxonomieGroupes = this.config.data?.taxonomieGroupes;
        this.action = this.config.data?.action;
    }

    private initForm(): void {
        this.form = this.fb.group({
            selection: [null, Validators.required]
        });
    }

    private subscribeToItemList(groupes: string[]) {
        const observable = this.store
            .pipe(
                select(selectUtilisateurFirme),
                switchMap(firmeUsagerCourant => {
                    const selector = (groupes.length === 1 && groupes[0] === TaxonomieGroupeValue.FIRME) ?
                        getTaxonomiePourGroupes(groupes) :
                        getTaxonomiePourGroupesParFirme(groupes, firmeUsagerCourant.id!);

                    const result = this.store.pipe(select(selector));
                    return result;
                }),
                map(users => users.map(user => user.toLowerCase()).sort()),
                takeUntil(this.destroyed)
            );

        observable.subscribe(_itemList =>
            this.itemList = _itemList
        );

        return observable;
    }

    public onSave() {
        if (this.form.valid) {
            const data: PatchProjetInspectionDto = { action: this.action, assignea: this.form.value.selection };
            this.store.dispatch(projetsActions.assignProjetInspection({ projetId: this.projet.id, assignData: data }));
            this.closeDialog();
        }
    }
}
