export enum MontageValue {
    a0 = 'A0',
    a1 = 'A1',
    a2 = 'A2',
    a3 = 'A3',
    a4 = 'A4',
    a5 = 'A5',
    a6 = 'A6',
    a7 = 'A7',
    a8 = 'A8',
    a9 = 'A9',
    b0 = 'B0',
    b1 = 'B1',
    b2 = 'B2',
    b3 = 'B3',
    b4 = 'B4',
    b5 = 'B5',
    b6 = 'B6',
    b7 = 'B7',
    b8 = 'B8',
    b9 = 'B9',
    c0 = 'C0',
    c5 = 'C5',
    c6 = 'C6',
    c7 = 'C7',
    c8 = 'C8',
    c9 = 'C9',
    d0 = 'D0',
    d1 = 'D1',
    d2 = 'D2',
    d3 = 'D3',
    d5 = 'D5',
    d6 = 'D6',
    d9 = 'D9',
    e0 = 'E0',
    e1 = 'E1',
    e2 = 'E2',
    e3 = 'E3',
    e5 = 'E5',
    e6 = 'E6',
    e9 = 'E9',
    f0 = 'F0',
    f1 = 'F1',
    f2 = 'F2',
    f3 = 'F3',
    f5 = 'F5',
    f6 = 'F6',
    f9 = 'F9',
    g0 = 'G0',
    g1 = 'G1',
    g2 = 'G1',
    g3 = 'G3',
    g5 = 'G5',
    g6 = 'G6',
    g9 = 'G9',
    h0 = 'H0',
    h1 = 'H1',
    h2 = 'H2',
    h3 = 'H3',
    h4 = 'H4',
    h5 = 'H5',
    h6 = 'H6',
    h7 = 'H7',
    h8 = 'H8',
    h9 = 'H9',
    vide = '',
    inconnu = 'Inconnu',
}

export enum Montage {
    a0 = 'a0',
    a1 = 'a1',
    a2 = 'a2',
    a3 = 'a3',
    a4 = 'a4',
    a5 = 'a5',
    a6 = 'a6',
    a7 = 'a7',
    a8 = 'a8',
    a9 = 'a9',
    b0 = 'b0',
    b1 = 'b1',
    b2 = 'b2',
    b3 = 'b3',
    b4 = 'b4',
    b5 = 'b5',
    b6 = 'b6',
    b7 = 'b7',
    b8 = 'b8',
    b9 = 'b9',
    c0 = 'c0',
    c5 = 'c5',
    c6 = 'c6',
    c7 = 'c7',
    c8 = 'c8',
    c9 = 'c9',
    d0 = 'd0',
    d1 = 'd1',
    d2 = 'd2',
    d3 = 'd3',
    d5 = 'd5',
    d6 = 'd6',
    d9 = 'd9',
    e0 = 'e0',
    e1 = 'e1',
    e2 = 'e2',
    e3 = 'e3',
    e5 = 'e5',
    e6 = 'e6',
    e9 = 'e9',
    f0 = 'f0',
    f1 = 'f1',
    f2 = 'f2',
    f3 = 'f3',
    f5 = 'f5',
    f6 = 'f6',
    f9 = 'f9',
    g0 = 'g0',
    g1 = 'g1',
    g2 = 'g1',
    g3 = 'g3',
    g5 = 'g5',
    g6 = 'g6',
    g9 = 'g9',
    h0 = 'h0',
    h1 = 'h1',
    h2 = 'h2',
    h3 = 'h3',
    h4 = 'h4',
    h5 = 'h5',
    h6 = 'h6',
    h7 = 'h7',
    h8 = 'h8',
    h9 = 'h9',
    vide = 'vide',
    inconnu = 'inconnu',
}

export const montageListe = Object.keys(Montage).map(key => ({ champ: key, valeur: MontageValue[key as keyof typeof Montage] }));

export const montageMap = new Map<Montage, string>(
    [
        [Montage.a0, MontageValue.a0],
        [Montage.a1, MontageValue.a1],
        [Montage.a2, MontageValue.a2],
        [Montage.a3, MontageValue.a3],
        [Montage.a4, MontageValue.a4],
        [Montage.a5, MontageValue.a5],
        [Montage.a6, MontageValue.a6],
        [Montage.a7, MontageValue.a7],
        [Montage.a8, MontageValue.a8],
        [Montage.a9, MontageValue.a9],
        [Montage.b0, MontageValue.b0],
        [Montage.b1, MontageValue.b1],
        [Montage.b2, MontageValue.b2],
        [Montage.b3, MontageValue.b3],
        [Montage.b4, MontageValue.b4],
        [Montage.b5, MontageValue.b5],
        [Montage.b6, MontageValue.b6],
        [Montage.b7, MontageValue.b7],
        [Montage.b8, MontageValue.b8],
        [Montage.b9, MontageValue.b9],
        [Montage.c0, MontageValue.c0],
        [Montage.c5, MontageValue.c5],
        [Montage.c6, MontageValue.c6],
        [Montage.c7, MontageValue.c7],
        [Montage.c8, MontageValue.c8],
        [Montage.c9, MontageValue.c9],
        [Montage.d0, MontageValue.d0],
        [Montage.d1, MontageValue.d1],
        [Montage.d2, MontageValue.d2],
        [Montage.d3, MontageValue.d3],
        [Montage.d5, MontageValue.d5],
        [Montage.d6, MontageValue.d6],
        [Montage.d9, MontageValue.d9],
        [Montage.e0, MontageValue.e0],
        [Montage.e1, MontageValue.e1],
        [Montage.e2, MontageValue.e2],
        [Montage.e3, MontageValue.e3],
        [Montage.e5, MontageValue.e5],
        [Montage.e6, MontageValue.e6],
        [Montage.e9, MontageValue.e9],
        [Montage.f0, MontageValue.f0],
        [Montage.f1, MontageValue.f1],
        [Montage.f2, MontageValue.f2],
        [Montage.f3, MontageValue.f3],
        [Montage.f5, MontageValue.f5],
        [Montage.f6, MontageValue.f6],
        [Montage.f9, MontageValue.f9],
        [Montage.g0, MontageValue.g0],
        [Montage.g1, MontageValue.g1],
        [Montage.g2, MontageValue.g2],
        [Montage.g3, MontageValue.g3],
        [Montage.g5, MontageValue.g5],
        [Montage.g6, MontageValue.g6],
        [Montage.g9, MontageValue.g9],
        [Montage.h0, MontageValue.h0],
        [Montage.h1, MontageValue.h1],
        [Montage.h2, MontageValue.h2],
        [Montage.h3, MontageValue.h3],
        [Montage.h4, MontageValue.h4],
        [Montage.h5, MontageValue.h5],
        [Montage.h6, MontageValue.h6],
        [Montage.h7, MontageValue.h7],
        [Montage.h8, MontageValue.h8],
        [Montage.h9, MontageValue.h9],
        [Montage.vide, MontageValue.vide],
        [Montage.inconnu, MontageValue.inconnu],
    ]
);
