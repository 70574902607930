import { LayerGroupe } from '../../enums/layer-groupe';
import { StatutProjetAudit, StatutProjetAuditValue } from '../../features/audit/models/statut-projet-audit.enum';
import { ProjetProperties } from '../../features/projets/models/projet-properties.enum';
import { FeatureSource } from '../../map/models/feature-source.enum';
import { LayerType } from '../../map/models/layer-type.enum';
import { mapStyleConfig } from '../../map/models/map-style-config.const';
import { StyleLayer } from '../../map/models/style-layer.model';

export const PROJETS_AUDIT_STYLE_LEGENDE: StyleLayer[] = [
    generateStyle(StatutProjetAudit.cree, 'auditCree'),
    generateStyle(StatutProjetAudit.assigne, 'auditAssigne'),
    generateStyle(StatutProjetAudit.enCours, 'auditEnCours'),
    generateStyle(StatutProjetAudit.annule, 'auditAnnule'),
    generateStyle(StatutProjetAudit.auditComplete, 'auditComplete'),
    generateStyle(StatutProjetAudit.nonApprouve, 'auditNonApprouve'),
    generateStyle(StatutProjetAudit.approuve, 'auditApprouve')
];

function generateStyle(statut: StatutProjetAudit, color: string): StyleLayer {
    return {
        'nomGroupe': LayerGroupe.PROJETS_AUDIT,
        'value': {
            'id': StatutProjetAuditValue[statut],
            'type': LayerType.FILL,
            'source': FeatureSource.PROJETS_AUDIT,
            'minzoom': 5,
            'filter': ['==', ['get', ProjetProperties.STATUT], statut],
            'layout': { 'visibility': 'visible' },
            'paint': {
                /* eslint-disable @typescript-eslint/naming-convention */
                'fill-color': mapStyleConfig.colors[color],
                'fill-outline-color': mapStyleConfig.colors[color],
                /* eslint-enable @typescript-eslint/naming-convention */
            }
        }
    };
}
