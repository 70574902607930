export enum PointLabels {
    ID = `Id`,
    ACCESSIBLE_CAMION = `Accessible camion nacelle`,
    ADRESSE_TRAVAUX = `Adresse des travaux`,
    ANNEE_INSTALLATION = `Année d'installation`,
    ANNEE_FABRICATION = `Année de fabrication`,
    CLASSE = `Classe`,
    ESSENCE = 'Essence du poteau',
    HAUTEUR_HORS_SOL = `Hauteur hors sol (mètre)`,
    INCLINAISON = `Inclinaison (degré)`,
    IND_EQUIPEMENT_MAJEUR = `Présence d'appareil lourd`,
    LCLCL_DISTANT = `LCLCL distant`,
    LCLCL_POTEAU = `LCLCL poteau`,
    FABRICANT = 'Fabricant',
    REMARQUE = `Remarque`,
    REMARQUE_POTEAU = `Remarque poteau`,
    INSPECTE_LE = `Inspecté le`,
    INSPECTE_PAR = `Inspecté par`,
    MODIFIE_LE = `Modifié le`,
    MODIFIE_PAR = `Modifié par`,
    LOCALISATION = `Localisation`,
    LONGUEUR = `Longueur (pied)`,
    MATERIAU = `Matériau`,
    MONTAGE = `Montage`,
    PROPRIETAIRE = `Propriétaire`,
    STATUT = `Statut`,
    STATUT_POTEAU = `Statut (poteau)`,
    USAGE = `Usage`,
    TRAITEMENT_INITIAL = `Traitement initial`,
    TYPE_POSITION = `Type de position`,
    AUDITE_PAR = `Audité par`,
    AUDITE_LE = `Audité le`,
    TYPE_NON_CONFORMITE = `Type de non-conformité`,
    NOTE_AUDITEUR = `Note auditeur`,
}
