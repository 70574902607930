export enum ClasseValue {
    inconnu = 'Inconnu',
    n1 = '1',
    n2 = '2',
    n3 = '3',
    n4 = '4',
    n5 = '5',
    n6 = '6',
    n7 = '7',
    at = 'AT',
    bt = 'BT',
    c = 'C',
    d = 'D',
    e = 'E',
    f = 'F',
    g = 'G',
    h = 'H',
    h1 = 'H1',
    h2 = 'H2',
    h3 = 'H3',
    j = 'J',
    k = 'K',
    l = 'L',
    m = 'M',
}

export enum Classe {
    inconnu = 'inconnu',
    n1 = 'n1',
    n2 = 'n2',
    n3 = 'n3',
    n4 = 'n4',
    n5 = 'n5',
    n6 = 'n6',
    n7 = 'n7',
    at = 'at',
    bt = 'bt',
    c = 'c',
    d = 'd',
    e = 'e',
    f = 'f',
    g = 'g',
    h = 'h',
    h1 = 'h1',
    h2 = 'h2',
    h3 = 'h3',
    j = 'j',
    k = 'k',
    l = 'l',
    m = 'm',
}

export const classeListe = Object.keys(Classe).filter((key: string) => key !== 'inconnu').map(key =>
    ({ champ: key, valeur: ClasseValue[key as keyof typeof Classe] }));

export const classeMap = new Map<Classe, string>(
    [
        [Classe.inconnu, ClasseValue.inconnu],
        [Classe.n1, ClasseValue.n1],
        [Classe.n2, ClasseValue.n2],
        [Classe.n3, ClasseValue.n3],
        [Classe.n4, ClasseValue.n4],
        [Classe.n5, ClasseValue.n5],
        [Classe.n6, ClasseValue.n6],
        [Classe.n7, ClasseValue.n7],
        [Classe.at, ClasseValue.at],
        [Classe.bt, ClasseValue.bt],
        [Classe.c, ClasseValue.c],
        [Classe.d, ClasseValue.d],
        [Classe.e, ClasseValue.e],
        [Classe.f, ClasseValue.f],
        [Classe.g, ClasseValue.g],
        [Classe.h, ClasseValue.h],
        [Classe.h1, ClasseValue.h1],
        [Classe.h2, ClasseValue.h2],
        [Classe.h3, ClasseValue.h3],
        [Classe.j, ClasseValue.j],
        [Classe.k, ClasseValue.k],
        [Classe.l, ClasseValue.l],
        [Classe.m, ClasseValue.m],
    ]
);

