export enum MateriauValue {
    bois = 'Bois',
    acier = 'Acier',
    beton = 'Béton',
    lamine = 'Laminé',
    composite = 'Composite',
    autre = 'Autre',
    inconnu = 'Inconnu',
}

export enum Materiau {
    bois = 'bois',
    acier = 'acier',
    beton = 'beton',
    lamine = 'lamine',
    composite = 'composite',
    autre = 'autre',
    inconnu = 'inconnu',
}

export const materiauListe = Object.keys(Materiau).map(key => ({ champ: key, valeur: MateriauValue[key as keyof typeof Materiau] }));

export const materiauMap = new Map<Materiau, string>(
    [
        [Materiau.bois, MateriauValue.bois],
        [Materiau.acier, MateriauValue.acier],
        [Materiau.beton, MateriauValue.beton],
        [Materiau.lamine, MateriauValue.lamine],
        [Materiau.composite, MateriauValue.composite],
        [Materiau.autre, MateriauValue.autre],
        [Materiau.inconnu, MateriauValue.inconnu],
    ]
);
