import { Injectable } from '@angular/core';
import { Observable, Subject, map, of } from 'rxjs';
import { AnomalieService, InspectionService, PointsAuditService, UtilitaireService } from '../core/api/client/services';
import { TaillePhoto } from '../shared/enums/taille-photo.enum';
import { EsriAccessToken } from '../shared/models/esri-access-token.model';
import { AnomaliePilotageDtoSearchResult, ExtractionInfosDTO, IdentiteUtilisateur } from '../core/api/client/models';
import { LocalStorageService } from './local-storage.service';
import { LocalStorageIndex } from '../shared/enums/local-storage-index.enum';
import { UserInformation } from '../shared/models/user-informations.model';

@Injectable({
    providedIn: 'root'
})

export class SharedService {

    private _disconnectGPSEvent = new Subject<void>();
    public disconnectGPSEvent$ = this._disconnectGPSEvent.asObservable();

    constructor(
        private utilitaireApiService: UtilitaireService,
        private inspectionService: InspectionService,
        private anomalieService: AnomalieService,
        private pointAuditsService: PointsAuditService,
        private localStorageService: LocalStorageService
    ) { }

    public selectEsriAccessToken(): Observable<EsriAccessToken> {
        return this.utilitaireApiService.getApiV1UtilitairesToken()
            .pipe(
                map(data => {
                    return JSON.parse(data);
                })
            );
    }

    public selectAnomaliesPilotage(params: UtilitaireService.GetApiV1UtilitairesAnomaliePilotageParams): Observable<AnomaliePilotageDtoSearchResult> {
        return this.utilitaireApiService.getApiV1UtilitairesAnomaliePilotage(params);
    }

    public getPointInspectionPhotosById(inspectionId: string, photoId: string, size?: TaillePhoto) {
        const params: InspectionService.GetApiV1InspectionsInspectionIdPhotosIdentifiantPhotoParams = {
            inspectionId: inspectionId,
            identifiantPhoto: photoId
        };

        if (size) {
            params.taille = size;
        }

        return this.inspectionService.getApiV1InspectionsInspectionIdPhotosIdentifiantPhoto(params);
    }

    public getAnomaliePhotosById(anomalieId: string, photoId: string, size?: TaillePhoto) {
        const params: AnomalieService.GetApiV1AnomaliesAnomalieIdPhotosIdentifiantPhotoParams = {
            anomalieId: anomalieId,
            identifiantPhoto: photoId
        };

        if (size) {
            params.taille = size;
        }

        return this.anomalieService.getApiV1AnomaliesAnomalieIdPhotosIdentifiantPhoto(params);
    }

    public getPointAuditPhotosById(pointAuditId: string, photoId: string, size?: TaillePhoto) {
        const params: PointsAuditService.GetApiV1PointsauditPointAuditIdPhotosIdPhotoParams = {
            pointAuditId: pointAuditId,
            idPhoto: photoId
        };

        if (size) {
            params.taille = size;
        }

        return this.pointAuditsService.getApiV1PointsauditPointAuditIdPhotosIdPhoto(params);
    }

    public getAnomalieAuditPhotosById(pointAudiId: string, anomalieAuditId: string, photoId: string, size?: TaillePhoto) {
        const params: PointsAuditService.GetApiV1PointsauditIdAnomaliesIdAnomalieAuditPhotosIdPhotoParams = {
            id: pointAudiId,
            idAnomalieAudit: anomalieAuditId,
            idPhoto: photoId
        };

        if (size) {
            params.taille = size;
        }

        return this.pointAuditsService.getApiV1PointsauditIdAnomaliesIdAnomalieAuditPhotosIdPhoto(params);
    }

    public startDataExtraction(params: UtilitaireService.PostApiV1UtilitairesExtractionParams): Observable<string> {
        return this.utilitaireApiService.postApiV1UtilitairesExtraction(params);
    }

    public selectDataExtraction(key: string): Observable<ExtractionInfosDTO> {
        return this.utilitaireApiService.getApiV1UtilitairesExtractionKey(key);
    }

    public disconnectGPS() {
        this._disconnectGPSEvent.next();
    }

    public fetchIdentiteUtilisateur(identiteUtilisateur: IdentiteUtilisateur | null): Observable<UserInformation> {
        if (identiteUtilisateur) {
            this.localStorageService.setItem(LocalStorageIndex.FIRME, identiteUtilisateur.firme);
            this.localStorageService.setItem(LocalStorageIndex.USER_ROLES, JSON.stringify(identiteUtilisateur.groupes));
            return of(identiteUtilisateur as UserInformation); // TODO: Changer UserInformation pour IdentiteUtilisateur
        } else {
            const firme = this.localStorageService.getItem(LocalStorageIndex.FIRME) as string;
            const groupes = JSON.parse(this.localStorageService.getItem(LocalStorageIndex.USER_ROLES) as string);
            return of({ firme, groupes });
        }
    }

    public setActiveRole(role: string): Observable<boolean> {
        this.localStorageService.setItem(LocalStorageIndex.ACTIVE_ROLE, role);
        const activeRole = this.localStorageService.getItem(LocalStorageIndex.ACTIVE_ROLE);
        return of(activeRole === role);
    }
}
