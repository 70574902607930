import { LayerGroupe } from '../../enums/layer-groupe';
import { LayerType } from '../../map/models/layer-type.enum';
import { mapStyleConfig } from '../../map/models/map-style-config.const';
import { StyleLayer } from '../../map/models/style-layer.model';

export const ENVIRONNEMENT_STYLE_LEGENDE: StyleLayer[] = [
    {
        'nomGroupe': LayerGroupe.ENVIRONNEMENT,
        'value': {
            'id': 'Zone de protection - Rainette faux-grillon',
            'type': LayerType.FILL,
            'source': 'environnement',
            'minzoom': 5,
            'filter': ['==', ['get', 'hq_etiquette'], 'Rainette faux-grillon de l\'ouest'],
            'layout': { 'visibility': 'visible' },
            'paint': {
                /* eslint-disable @typescript-eslint/naming-convention */
                'fill-color': 'rgba(0, 255, 0, 0.4)',
                'fill-outline-color': mapStyleConfig.colors.vegetation,
                /* eslint-enable @typescript-eslint/naming-convention */
            }
        }
    }
];
