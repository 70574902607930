export enum StatutProjet {
    enCreation = 'enCreation',
    nouveau = 'nouveau',
    inspectionAssigne = 'inspectionAssigne',
    inspectionEnCours = 'inspectionEnCours',
    inspectionCompletee = 'inspectionCompletee',
    attenteApprobationHQ = 'attenteApprobationHQ',
    approbationFinaleHQ = 'approbationFinaleHQ',
    pause = 'pause',
    annule = 'annule',
    erreur = 'erreur',
    auditEnCours = 'auditEnCours',
    aqAssigne = 'aqAssigne',
    aqEnCours = 'aqEnCours',
    aqCompletee = 'aqCompletee',
    nonConforme = 'nonConforme',
    correctionAssignee = 'correctionAssignee',
    correctionEnCours = 'correctionEnCours',
    correctionCompletee = 'correctionCompletee',
    conforme = 'conforme',
    audite = 'audite'
}

export enum StatutProjetValue {
    enCreation = 'En création',
    nouveau = 'Nouveau',
    inspectionAssigne = 'Inspection assignée',
    inspectionEnCours = 'Inspection en cours',
    inspectionCompletee = 'Inspection complétée',
    attenteApprobationHQ = 'Attente approbation HQ',
    approbationFinaleHQ = 'Approbation finale HQ',
    pause = 'Pause',
    annule = 'Annulé',
    erreur = 'Erreur',
    auditEnCours = 'Audit en cours',
    aqAssigne = 'AQ assigné',
    aqEnCours = 'AQ en cours',
    aqCompletee = 'AQ completée',
    nonConforme = 'Inspection non conforme',
    correctionAssignee = 'Correction assignée',
    correctionEnCours = 'Correction en cours',
    correctionCompletee = 'Correction complétée',
    conforme = 'Inspection conforme',
    audite = 'Audité'
}

export const statutProjetMap = new Map<StatutProjet, string>([
    [StatutProjet.enCreation, StatutProjetValue.enCreation],
    [StatutProjet.nouveau, StatutProjetValue.nouveau],
    [StatutProjet.inspectionAssigne, StatutProjetValue.inspectionAssigne],
    [StatutProjet.inspectionEnCours, StatutProjetValue.inspectionEnCours],
    [StatutProjet.inspectionCompletee, StatutProjetValue.inspectionCompletee],
    [StatutProjet.attenteApprobationHQ, StatutProjetValue.attenteApprobationHQ],
    [StatutProjet.approbationFinaleHQ, StatutProjetValue.approbationFinaleHQ],
    [StatutProjet.pause, StatutProjetValue.pause],
    [StatutProjet.annule, StatutProjetValue.annule],
    [StatutProjet.erreur, StatutProjetValue.erreur],
    [StatutProjet.auditEnCours, StatutProjetValue.auditEnCours],
    [StatutProjet.aqAssigne, StatutProjetValue.aqAssigne],
    [StatutProjet.aqEnCours, StatutProjetValue.aqEnCours],
    [StatutProjet.aqCompletee, StatutProjetValue.aqCompletee],
    [StatutProjet.nonConforme, StatutProjetValue.nonConforme],
    [StatutProjet.correctionAssignee, StatutProjetValue.correctionAssignee],
    [StatutProjet.correctionEnCours, StatutProjetValue.correctionEnCours],
    [StatutProjet.correctionCompletee, StatutProjetValue.correctionCompletee],
    [StatutProjet.conforme, StatutProjetValue.conforme],
    [StatutProjet.audite, StatutProjetValue.audite],
]);
