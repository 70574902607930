<ng-container *ngIf="projetsLoading$ | async" else projectsLoaded>
    <app-loading-spinner class="loading" [size]="75" [backdrop]="true"></app-loading-spinner>
</ng-container>
<ng-container #projectsLoaded *ngIf="projets?.length">
    <p-contextMenu #menu [model]="items" appendTo="body" triggerEvent="click">
    </p-contextMenu>
    <p-menu #menuExtract [popup]="true" [model]="extractMenuItems" appendTo="body"></p-menu>
    <ng-container *ngIf="!isUtilisateurMobile">
        <p-table #dt [autoLayout]="true" [columns]="colonnes" exportFilename='Liste des projets' [value]="projets"
            [rowHover]="true" [paginator]="true" [rows]="10" [rowsPerPageOptions]="[10,25,50,100,200]"
            (onPage)="paginate($event)" [first]="first" responsiveLayout="scroll"
            [globalFilterFields]="['nom', 'type', 'statut', 'dateOperation', 'numeroOrdreDeTravail', 'creeLe', 'firme', 'territoire', 'inspectionAnnee', 'assigneA', 'assigneLe', 'assigneAQA', 'nombreTotalPoteaux']">
            <!-- NE PAS rendre le contenu de globalFilterFields dans une varialble, il semble y avoir un bug, donc non fonctionnel -->
            <ng-template pTemplate="caption" class="p-pb-0">
                <div class="myTable__header">
                    <div class="search">
                        <i class="fas fa-search search__icon"></i>
                        <input type="text" class="search__input" pInputText size="30" placeholder="Rechercher"
                            aria-placeholder="Rechercher"
                            (input)="dt.filterGlobal($any($event.target).value, 'contains')">
                    </div>
                    <div class="header__action">
                        <div class="action__export">
                            <button type="button" pButton pRipple icon="far fa-file-archive"
                                pTooltip="Extraire les données" (click)="menuExtract.toggle($event)"
                                tooltipPosition="bottom" class="data-extraction"
                                [loading]="dataExtractionLoading"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-o" pTooltip="CSV"
                                (click)="exportToCsv()" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" class="p-button-success"
                                pTooltip="XLS" (click)="exportToExcel()" tooltipPosition="bottom"></button>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th scope="col" *ngFor="let col of columns" [style.width]="col.width" [pSortableColumn]="col.field">
                        <div class="p-d-flex"> {{ col.header }}
                            <p-sortIcon [field]="col.field" aria-label="Trier"
                                aria-label-desc="Trier en ordre descendant" aria-label-asc="Trier en ordre ascendant">
                            </p-sortIcon>
                        </div>
                    </th>
                    <th class="sticky-column-transparente">Actions</th>
                </tr>
                <tr>
                    <th *ngFor="let col of columns" [ngSwitch]="col.field">
                        <div *ngSwitchCase="'territoire'">
                            <p-dropdown appendTo="body" [options]="territoireList" autoWidth="false"
                                [style]="{'minWidth':'75px', 'width': '75px'}" placeholder="Sélectionner un territoire"
                                (onChange)="dt.filter($event.value, col.field, 'equals')" styleClass="p-column-filter"
                                pTooltip="Sélectionner un territoire" tooltipPosition="top" [showClear]="true">
                                <ng-template let-option pTemplate="item">
                                    <span>{{option}}</span>
                                </ng-template>
                            </p-dropdown>
                        </div>
                        <div *ngSwitchCase="'type'">
                            <p-dropdown appendTo="body" [options]="projetTypesListe" optionLabel="valeur"
                                optionValue="champ" autoWidth="false" [style]="{'minWidth':'100px', 'width': '100px'}"
                                (onChange)="dt.filter($event.value, col.field, 'equals')" styleClass="p-column-filter"
                                placeholder="Sélectionner un type" pTooltip="Sélectionner un type" tooltipPosition="top"
                                [showClear]="true">
                            </p-dropdown>
                        </div>
                        <div>
                            <p-multiSelect *ngSwitchCase="'statut'" [appendTo]="'body'"
                                defaultLabel="Sélectionner un statut" [(ngModel)]="filtersEntries"
                                [ngModelOptions]="{standalone: true}" [options]="tableFiltersItems" optionLabel="name"
                                [maxSelectedLabels]="1" [showHeader]="true" [filterBy]="'name, code'"
                                [filterPlaceHolder]="'Rechercher statut'" [selectedItemsLabel]="'{0} éléments'"
                                class="p-column-filter" (onChange)="onFilterChange($event.value, col.field)">
                            </p-multiSelect>
                        </div>
                        <div *ngSwitchDefault>
                            <input pInputText
                                *ngIf="!(col.field === 'nombreTotalPoteaux') && !(col.field === 'pourcentageCompletion')"
                                type="text" styleClass="p-column-filter"
                                (input)="dt.filter($any($event.target).value, col.field, 'contains')"
                                placeholder="Filtrer" aria-placeholder="Filtrer">
                        </div>
                    </th>
                    <th class="sticky-column"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-project let-index="rowIndex">
                <tr [pContextMenuRow]="project">
                    <td>{{project.nom}}</td>
                    <td>{{projetTypesMap.get(project.type.toLowerCase())}}</td>
                    <td [class.text-erreur]="project.statut === statutProjetEnum.nonConforme">
                        {{statutProjetName.get(project.statut)}}</td>
                    <td style="min-width: 110px;">{{project.statutModifieLe}}</td>
                    <td>{{project.numeroOrdreDeTravail}}</td>
                    <td style="min-width: 110px;">{{project.creeLe}}</td>
                    <td style="min-width: 120px;">{{project.firmeName}}</td>
                    <td>{{project.territoire}}</td>
                    <td>{{project.inspectionAnnee}}</td>
                    <td>{{project.assigneA}}</td>
                    <td style="min-width: 110px;">{{project.assigneLe}}</td>
                    <td>{{project.assigneAQA}}</td>
                    <td *ngIf="!isInspecteur">{{project.nombreTotalPoteaux}}</td>
                    <td *ngIf="!isInspecteur">{{project.pourcentageCompletion | myFloorPercent}}</td>
                    <td class="sticky-column-transparente">
                        <div class="sticky-column__container">
                            <button #ellipsisv pButton pRipple icon="pi pi-ellipsis-v"
                                class="p-button-rounded p-button-secondary p-p-0 assigne-button sticky-column-transparente"
                                (click)="onActionsButtonClick($event, menu, project)"></button>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </ng-container>
</ng-container>

<app-assign-projet-dialog [projet]="selectedProjet" [taxonomieGroupes]="taxonomieGroupes"
    [action]="assigneProjetDialogAction" [headerText]="assigneProjetDialogHeader" [assignText]="assigneProjetDialogText"
    [(visible)]="assigneProjetDialogVisible" (close)="closeAssigneProjetDialog()">
</app-assign-projet-dialog>

<app-rejeter-projet-dialog [projet]="selectedProjet" [(visible)]="rejeterProjetDialogVisible" />

<p-confirmDialog #cdAssign header="Confirmation d'assignation" icon="pi pi-exclamation-triangle"
    key='assigneProjetConfirm'>
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-times" class="p-button-text p-mr-4" label="Non"
            (click)="cdAssign.reject()"></button>
        <button type="button" pButton icon="pi pi-check" class="p-button-text p-ml-4" label="Oui"
            (click)="cdAssign.accept()"></button>
    </ng-template>
</p-confirmDialog>

<p-confirmDialog #cv header="Confirmation d'annulation" icon="pi pi-exclamation-triangle" key='annuleConfirm'>
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-times" class="p-button-text p-mr-4" label="Non"
            (click)="cv.reject()"></button>
        <button type="button" pButton icon="pi pi-check" class="p-button-text p-ml-4" label="Oui"
            (click)="cv.accept()"></button>
    </ng-template>
</p-confirmDialog>

<p-dialog (onHide)="closeModificationDialog()" *ngIf="modificationForm" [formGroup]="modificationForm" [modal]=true
    header="Modifier le projet" [(visible)]="displayModificationProjet" [draggable]=false>
    <div class="modification-dialog">
        <div class="modification">Numéro d'ordre de travail:
            <input type="text" pInputText formControlName="numeroOrdreDeTravail" class="modification-field"
                [minlength]="8" [maxlength]="8" onKeyPress="if(this.value.length==8) return false; else return true;" />
        </div>
    </div>
    <ng-container *ngIf="modificationForm.controls?.numeroOrdreDeTravail?.errors?.minlength">
        <br><span class="text-erreur">Le numéro d'ordre de travail doit avoir exactement 8 chiffres</span>
    </ng-container>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Annuler" icon="pi pi-times" class="p-button-text"
            (click)="closeModification()"></button>
        <button pButton pRipple label="Enregistrer" icon="pi pi-check" class="p-button-text"
            [disabled]="!modificationForm.controls['numeroOrdreDeTravail'].valid"
            (click)="onModifierCurrentProjet()"></button>
    </ng-template>
</p-dialog>

<p-confirmDialog #vp header="Validation du projet" icon="pi pi-exclamation-triangle" key='validationProjetConfirm'>
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-times" class="p-button-text p-mr-4" label="Annuler"
            (click)="vp.reject()"></button>
        <button type="button" pButton icon="pi pi-check" class="p-button-text p-ml-4" label="Oui"
            (click)="vp.accept()"></button>
    </ng-template>
</p-confirmDialog>

<p-confirmDialog #cp header="Rapport de projet" key="completeProjetConfirm">
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-flag" class="p-button-text" label="Compléter"
            (click)="cp.accept()"></button>
    </ng-template>
</p-confirmDialog>
