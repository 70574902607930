/* eslint-disable @typescript-eslint/naming-convention */
export enum MotifIgnoreValue {
    branchementUniquement = 'Poteau de branchement',
    nonHq = 'Poteau non HQ',
    animaux = 'Animaux',
    accesBarre = 'Accès barré',
    refusClient = 'Refus du client',
    voieFerree = 'Voie ferrée',
    vegetation = 'Végétation',
    terrain = 'Terrain',
    inexistant = 'Inexistant',
    autre = 'Autre',
}

export enum MotifIgnore {
    branchementUniquement = 'branchementUniquement',
    nonHq = 'nonHq',
    animaux = 'animaux',
    accesBarre = 'accesBarre',
    refusClient = 'refusClient',
    voieFerree = 'voieFerree',
    vegetation = 'vegetation',
    terrain = 'terrain',
    inexistant = 'inexistant',
    autre = 'autre',
}

export const motifIgnoreListe = Object.keys(MotifIgnore).map(key => ({ champ: key, valeur: MotifIgnoreValue[key as keyof typeof MotifIgnore] }));

export const motifIgnoreMap = new Map<MotifIgnore, string>(
    [
        [MotifIgnore.branchementUniquement, MotifIgnoreValue.branchementUniquement],
        [MotifIgnore.nonHq, MotifIgnoreValue.nonHq],
        [MotifIgnore.animaux, MotifIgnoreValue.animaux],
        [MotifIgnore.accesBarre, MotifIgnoreValue.accesBarre],
        [MotifIgnore.refusClient, MotifIgnoreValue.refusClient],
        [MotifIgnore.voieFerree, MotifIgnoreValue.voieFerree],
        [MotifIgnore.vegetation, MotifIgnoreValue.vegetation],
        [MotifIgnore.terrain, MotifIgnoreValue.terrain],
        [MotifIgnore.inexistant, MotifIgnoreValue.inexistant],
        [MotifIgnore.autre, MotifIgnoreValue.autre],
    ]
);


