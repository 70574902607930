export enum PrioriteValue {
    anomaliePrioriteC = '5 jours et moins',
    anomaliePrioriteE = '1 mois et moins',
    anomaliePrioriteG = '3 mois et moins',
    anomaliePrioriteK = '6 mois et moins',
    anomaliePrioriteM = '12 mois et moins',
    anomaliePrioriteN = '24 mois et moins',
}

export enum Priorite {
    anomaliePrioriteC = 'anomaliePrioriteC',
    anomaliePrioriteE = 'anomaliePrioriteE',
    anomaliePrioriteG = 'anomaliePrioriteG',
    anomaliePrioriteK = 'anomaliePrioriteK',
    anomaliePrioriteM = 'anomaliePrioriteM',
    anomaliePrioriteN = 'anomaliePrioriteN',
}

export const prioriteMap = new Map<Priorite, string>(
    [
        [Priorite.anomaliePrioriteC, PrioriteValue.anomaliePrioriteC],
        [Priorite.anomaliePrioriteE, PrioriteValue.anomaliePrioriteE],
        [Priorite.anomaliePrioriteG, PrioriteValue.anomaliePrioriteG],
        [Priorite.anomaliePrioriteK, PrioriteValue.anomaliePrioriteK],
        [Priorite.anomaliePrioriteM, PrioriteValue.anomaliePrioriteM],
        [Priorite.anomaliePrioriteN, PrioriteValue.anomaliePrioriteN],
    ]
);
