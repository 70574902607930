import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { PointInspectionProperties } from '../../../../features/inspection/models/point-inspection-properties.enum';
import { filter, Observable, take, takeUntil, tap } from 'rxjs';
import { State } from '../../../../state/app.state';
import { getPointInspectionById, getProjetInspectionById } from '../../../../features/inspection/state/inspection.selectors';
import { Store, select } from '@ngrx/store';
import { BaseComponent } from '../../../../shared/components/abstract-base-component';
import { flatMap } from 'lodash';
import { Photo } from '../../../../shared/models/photo.model';
import { StatutPointInspection, statutPointInspectionMap } from '../../../../features/inspection/models/statut-point-inspection.enum';
import { Proprietaire, proprietaireMap } from '../../../models/proprietaire.enum';
import { Inclinaison, inclinaisonMap } from '../../../models/inclinaison.enum';
import { Classe, classeMap } from '../../../models/classe.enum';
import { Usage, usageMap } from '../../../models/usage.enum';
import * as InspectionActions from '../../../../features/inspection/state/inspection.actions';
import { PhotoService } from '../../../../services/photo.service';
import { AnomalieBaseDto, PointInspectionDto, ProjetCompletDto } from '../../../../core/api/client/models';
import { PopUpInfoCloseEvent } from '../../../models/pop-up-info-close-event.model';
import { dateTimeChange, generatePhotos } from '../../../../shared/utils';
import { ObjectType } from '../../../../shared/enums/photo-object-type.enum';
import { PointLabels } from '../../../../features/projets/models/point-labels.enum';
import { AccessibleCamion, accessibleCamionMap } from '../../../models/accessible-camion.enum';
import { Essence, essenceMap } from '../../../models/essence.enum';
import { Fabricant, fabricantMap } from '../../../models/fabricant.enum';
import { TraitementInitial, traitementInitialMap } from '../../../models/traitement-initial.enum';
import { Materiau, materiauMap } from '../../../models/materiau.enum';
import { Montage, montageMap } from '../../../models/montage.enum';
import { AnomalieLabels } from '../../../../features/anomalie/models/anomalie-labels.enum';
import { selectIsAppOnline, selectUtilisateurCanEditPointInspectionByProjet } from '../../../../state/shared/shared.selectors';

@Component({
    selector: 'app-info-point-inspection',
    templateUrl: './info-point-inspection.component.html',
    styleUrls: ['./info-point-inspection.component.scss']
})
export class InfoPointInspectionComponent extends BaseComponent implements OnChanges {
    public selectedPointInspection: PointInspectionDto | null = null;
    public subTitle: string = `Point d'inspection`;
    public projet$: Observable<ProjetCompletDto | null> = null;
    public codeBarres: string = '';
    public verticalProperties: string[] = [];

    public currentFeature: mapboxgl.MapboxGeoJSONFeature;
    public data: { [name: string]: any } = {};
    public equipementsMineurs: { [name: string]: string } = {};
    public currentProjet: ProjetCompletDto | null = null;
    public anomalies: AnomalieBaseDto[] = [];
    public photosPointInspection: Photo[] = [];
    public numberOfPhotos: number = 0;

    public imagesPointInspection: HTMLImageElement[] = [];
    public userGroups: string[] = [];
    public isOffline = true;
    public offlineMessage = 'Connectez-vous à internet pour modifier';
    public showEditInspection = false;

    @Input() feature: mapboxgl.MapboxGeoJSONFeature | null = null;
    @Output() closed: EventEmitter<PopUpInfoCloseEvent> = new EventEmitter<PopUpInfoCloseEvent>();

    constructor(
        private store: Store<State>,
        private photoService: PhotoService,
    ) {
        super();
        this.subscribeToIsAppOffline();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.feature.currentValue) {
            this.currentFeature = changes.feature.currentValue;
            this.subscribeToPointInspectionById(this.currentFeature);
        }
    }

    public close() {
        this.closed.emit({ closed: true });
        this.ngOnDestroy();
    }

    public editInspection() {
        this.closed.emit({
            closed: true,
            pointInspectionId: this.selectedPointInspection?.id,
            pointInspectionFeature: this.currentFeature
        });
        this.ngOnDestroy();
    }

    private subscribeToPointInspectionById(feature: mapboxgl.MapboxGeoJSONFeature) {

        this.store.select(getPointInspectionById(feature.properties[PointInspectionProperties.ID]))
            .pipe(
                filter(pointInspection => !!pointInspection),
                take(1)
            ).subscribe(pointInspection => {
                this.store.dispatch(InspectionActions.setSelectedPointInspection({ pointInspection }));
                this.selectedPointInspection = pointInspection;
                this.initComponent(pointInspection);
            });
    }

    private subscribeUtilisateurCanEditPointInspectionByProjet(projet: ProjetCompletDto) {
        this.store.select(selectUtilisateurCanEditPointInspectionByProjet(projet)).pipe(
            takeUntil(this.destroyed)
        ).subscribe(canEditPointInspectionByProjet => {
            this.showEditInspection = this.currentProjet ? canEditPointInspectionByProjet : false;
        });
    }

    private getProjet(pointInspection: PointInspectionDto) {
        if (pointInspection.projetId) {
            this.projet$ = this.store.pipe(
                select(getProjetInspectionById(pointInspection.projetId)),
                tap((projet) => {
                    this.currentProjet = projet;
                    this.subscribeUtilisateurCanEditPointInspectionByProjet(this.currentProjet);
                }),
                takeUntil(this.destroyed)
            );
        }
    }

    private initEquipmentMineurData(pointInspection: PointInspectionDto) {
        const data = {
            [AnomalieLabels.MALT]: pointInspection.poteau?.malt ? 'Oui' : 'Non',
            [AnomalieLabels.TUTEUR]: pointInspection.poteau?.tuteur ? 'Oui' : 'Non',
            [AnomalieLabels.LUMINAIRE]: pointInspection.poteau?.luminaire ? 'Oui' : 'Non',
            [AnomalieLabels.JAMBE_DE_FORCE]: pointInspection.poteau?.jambeDeForce ? 'Oui' : 'Non',
            [AnomalieLabels.LIAISON_AEROSOUTERRAINE]: pointInspection.poteau?.liaisonAerosouterraine ? 'Oui' : 'Non',
            [AnomalieLabels.PANNEAU]: pointInspection!.poteau?.panneau ? 'Oui' : 'Non',
        };

        if (data) {
            this.equipementsMineurs = data;
        }
    }

    private initPhotosHtml() {
        const photosPointInspection = this.photoService.convertToPhotoContainer(this.photosPointInspection);
        const photosAnomalies = this.photoService.convertToPhotoContainer(flatMap(this.anomalies, anomalie => (anomalie.photos || []) as Photo[]));

        this.numberOfPhotos = photosPointInspection.length + photosAnomalies.length;
        this.imagesPointInspection = this.photoService.convertToPhotoHtml(photosPointInspection);
        this.photoService.setCarousselPhotos([...photosPointInspection, ...photosAnomalies]);
    }

    private initPhotos(pointInspection: PointInspectionDto) {
        if (pointInspection.photos) {
            this.photosPointInspection = !navigator.onLine
                ? pointInspection.photos as Photo[]
                : generatePhotos(pointInspection.photos ? pointInspection.photos : [],
                    pointInspection.id, ObjectType.INSPECTIONS, 'PointInspection');
        }
    }

    private initData(point: PointInspectionDto) {
        this.data = {
            ...(point.accessibleCamion !== 'inconnu' ? { [PointLabels.ACCESSIBLE_CAMION]: accessibleCamionMap.get(point.accessibleCamion as AccessibleCamion) } : {}),
            ...(point.poteau?.adresseTravaux ? { [PointLabels.ADRESSE_TRAVAUX]: point.poteau?.adresseTravaux } : {}),
            ...(point.poteau?.anneeInstallation ? { [PointLabels.ANNEE_INSTALLATION]: dateTimeChange(point?.poteau?.anneeInstallation?.toString(), 'YYYY') } : {}),
            ...(point.poteau?.anneeFabrication ? { [PointLabels.ANNEE_FABRICATION]: dateTimeChange(point?.poteau?.anneeFabrication?.toString(), 'YYYY') } : {}),
            ...(point.poteau?.classe ? { [PointLabels.CLASSE]: classeMap.get(point.poteau?.classe as Classe) } : {}),
            ...(point.poteau?.hauteurHorsSol ? { [PointLabels.HAUTEUR_HORS_SOL]: point.poteau?.hauteurHorsSol } : {}),
            ...(point.poteau?.inclinaison ? { [PointLabels.INCLINAISON]: inclinaisonMap.get(point.poteau?.inclinaison as Inclinaison) } : {}),
            ...(point.indEquipementMajeur ? { [PointLabels.IND_EQUIPEMENT_MAJEUR]: point.indEquipementMajeur ? 'Oui' : 'Non' } : {}),
            ...(point.id ? { [PointLabels.ID]: point.id } : {}),
            ...(point.poteau?.lclclDistant ? { [PointLabels.LCLCL_DISTANT]: point.poteau?.lclclDistant } : {}),
            ...(point.poteau?.lclclPoteau ? { [PointLabels.LCLCL_POTEAU]: point.poteau?.lclclPoteau } : {}),
            ...(point.remarque ? { [PointLabels.REMARQUE]: point.remarque } : {}),
            ...(point.remarquePoteau ? { [PointLabels.REMARQUE_POTEAU]: point.remarquePoteau } : {}),
            ...(point.inspecteLe ? { [PointLabels.INSPECTE_LE]: dateTimeChange(point?.poteau && point.inspecteLe?.toString(), 'dd/MM/YYYY') } : {}),
            ...(point.inspectePar ? { [PointLabels.INSPECTE_PAR]: point.inspectePar } : {}),
            ...(point.modifieLe ? { [PointLabels.MODIFIE_LE]: dateTimeChange(point?.poteau && point.modifieLe?.toString(), 'dd/MM/YYYY') } : {}),
            ...(point.modifiePar ? { [PointLabels.MODIFIE_PAR]: point.modifiePar } : {}),
            ...(point.poteau?.localisation ? { [PointLabels.LOCALISATION]: point.poteau?.localisation } : {}),
            ...(point.poteau?.longueur ? { [PointLabels.LONGUEUR]: point.poteau?.longueur } : {}),
            ...(point.poteau?.materiau ? { [PointLabels.MATERIAU]: materiauMap.get(point.poteau?.materiau as Materiau) } : {}),
            ...(point.poteau?.montage ? { [PointLabels.MONTAGE]: montageMap.get(point.poteau?.montage as Montage) } : {}),
            ...(point.poteau?.proprietaire ? { [PointLabels.PROPRIETAIRE]: proprietaireMap.get(point.poteau?.proprietaire as Proprietaire) } : {}),
            ...(point.statut ? { [PointLabels.STATUT]: statutPointInspectionMap.get(point.statut as StatutPointInspection) } : {}),
            ...(point.poteau?.essence ? { [PointLabels.ESSENCE]: essenceMap.get(point.poteau?.essence as Essence) } : {}),
            ...(point.poteau?.typePosition ? { [PointLabels.TYPE_POSITION]: point.poteau?.typePosition } : {}),
            ...(point.poteau?.fabricant ? { [PointLabels.FABRICANT]: fabricantMap.get(point.poteau?.fabricant as Fabricant) } : {}),
            ...(point.poteau?.traitementInitial ?
                { [PointLabels.TRAITEMENT_INITIAL]: traitementInitialMap.get(point.poteau?.traitementInitial as TraitementInitial) } : {}),
            ...(point.poteau?.usage ? { [PointLabels.USAGE]: usageMap.get(point.poteau?.usage as Usage) } : {}),
        };

        this.verticalProperties = [
            PointLabels.ADRESSE_TRAVAUX
        ];
    }

    private subscribeToIsAppOffline() {
        this.store.select(selectIsAppOnline).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_isAppOnline => {
            this.isOffline = !_isAppOnline;
        });
    }

    private initComponent(pointInspection: PointInspectionDto) {
        this.getProjet(pointInspection);
        this.initData(pointInspection);
        this.initEquipmentMineurData(pointInspection);
        this.codeBarres = pointInspection.poteau?.codeABarres ?? 'Code à barres inconnu';
        this.anomalies = pointInspection.anomalies ?? [];
        this.initPhotos(pointInspection);
        this.initPhotosHtml();
    }
}
