export enum ProprietaireValue {
    hqUsageCommun = 'HQ usage commun',
    hqSeul = 'HQ seul',
    telephoneSeul = 'Téléphone seul',
    telephoneUsageCommun = 'Téléphone usage commun',
    prive = 'Privé',
    transEnergie = 'TransÉnergie',
    inconnu = 'Inconnu',
}

export enum Proprietaire {
    hqUsageCommun = 'hqUsageCommun',
    hqSeul = 'hqSeul',
    telephoneSeul = 'telephoneSeul',
    telephoneUsageCommun = 'telephoneUsageCommun',
    prive = 'prive',
    transEnergie = 'transEnergie',
    inconnu = 'inconnu',
}

export const proprietaireListe = Object.keys(Proprietaire).map(key => ({ champ: key, valeur: ProprietaireValue[key as keyof typeof Proprietaire] }));

export const proprietaireMap = new Map<Proprietaire, string>(
    [
        [Proprietaire.hqUsageCommun, ProprietaireValue.hqUsageCommun],
        [Proprietaire.hqSeul, ProprietaireValue.hqSeul],
        [Proprietaire.telephoneSeul, ProprietaireValue.telephoneSeul],
        [Proprietaire.telephoneUsageCommun, ProprietaireValue.telephoneUsageCommun],
        [Proprietaire.prive, ProprietaireValue.prive],
        [Proprietaire.transEnergie, ProprietaireValue.transEnergie],
        [Proprietaire.inconnu, ProprietaireValue.inconnu],
    ]
);
