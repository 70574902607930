import { Action, createReducer, on } from '@ngrx/store';
import * as AuditActions from './audit.actions';
import { AuditState } from './audit.state';
import { PhotoEntity } from '../../../core/api/client/models';

export const auditInitialState: AuditState = {
    projetAuditList: null,
    projetAuditListLoading: false,
    loadProjetAuditListError: null,
    projetAuditHistory: null,
    projetAuditHistoryError: null,
    createProjetAudit: null,
    createProjetAuditLoading: false,
    createProjetAuditSuccess: false,
    createProjetAuditError: null,
    assignProjetAudit: null,
    assignProjetAuditSuccess: false,
    assignProjetAuditError: null,
    activateProjetAudit: null,
    activateProjetAuditSuccess: false,
    activateProjetAuditError: null,
    currentActiveProjetAudit: null,
    selectedPointAudit: null,
    cancelProjetAudit: null,
    cancelProjetAuditSuccess: false,
    cancelProjetAuditError: null,
    rejectProjetAudit: null,
    rejectProjetAuditSuccess: false,
    rejectProjetAuditError: null,
    completeProjetAudit: null,
    completeProjetAuditSuccess: false,
    completeProjetAuditError: null,
    completeProjetAuditLoading: false,
    approveProjetAudit: null,
    approveProjetAuditSuccess: false,
    approveProjetAuditError: null,
    approveProjetAuditLoading: false,
    createPointAudit: null,
    createPointAuditSuccess: false,
    createPointAuditError: null,
    updatePointAudit: null,
    updatePointAuditSuccess: false,
    updatePointAuditError: null,
    pointsAudit: [],
    pointsAuditLoading: false,
    loadPointsAuditError: null,
    addPointAudit: null,
    addPointAuditSuccess: false,
    addPointAuditError: null,
    addPointAuditPhotoSuccess: false,
    addPointAuditPhotoError: null,
    addAnomalieAuditPhotoSuccess: false,
    addAnomalieAuditPhotoError: null,
    deletePointAuditPhotoSuccess: false,
    deletePointAuditPhotoError: null,
    deleteAnomalieAuditPhotoSuccess: false,
    deleteAnomalieAuditPhotoError: null,
};

export const auditReducer = createReducer<AuditState>(
    auditInitialState,
    on(AuditActions.loadProjetAuditList, (state) => {
        return {
            ...state,
            projetAuditList: null,
            projetAuditListLoading: true,
            loadProjetAuditListError: null
        };
    }),
    on(AuditActions.loadProjetAuditListSuccess, (state, action) => {
        return {
            ...state,
            projetAuditList: action.projetAuditList,
            projetAuditListLoading: false,
            loadProjetAuditListError: null
        };
    }),
    on(AuditActions.loadProjetAuditListError, (state, action) => {
        return {
            ...state,
            projetAuditList: null,
            projetAuditListLoading: false,
            loadProjetAuditListError: action.error,
        };
    }),
    on(AuditActions.loadProjetAuditHistory, (state) => {
        return {
            ...state,
            projetAuditHistory: null,
            projetAuditHistoryError: null,
        };
    }),
    on(AuditActions.loadProjetAuditHistorySuccess, (state, action) => {
        return {
            ...state,
            projetAuditHistory: action.auditHistory,
            projetAuditHistoryError: null
        };
    }),
    on(AuditActions.loadProjetAuditHistoryError, (state, action) => {
        return {
            ...state,
            projetAuditHistory: null,
            projetAuditHistoryError: action.error
        };
    }),
    on(AuditActions.startCreateProjetAudit, (state) => {
        return {
            ...state,
            createProjetAuditLoading: true,
            createProjetAuditSuccess: false,
            createProjetAuditError: null
        };
    }),
    on(AuditActions.createProjetAuditSuccess, (state, action) => {
        return {
            ...state,
            createProjetAudit: action.createProjetAudit,
            createProjetAuditLoading: false,
            createProjetAuditSuccess: true,
        };
    }),
    on(AuditActions.createProjetAuditError, (state, action) => {
        return {
            ...state,
            createProjetAuditLoading: false,
            createProjetAuditSuccess: false,
            createProjetAuditError: action.error
        };
    }),
    on(AuditActions.assignProjetAudit, (state) => {
        return {
            ...state,
            assignProjetAudit: null,
            assignProjetAuditSuccess: false,
            assignProjetAuditError: null,
        };
    }),
    on(AuditActions.assignProjetAuditSuccess, (state, action) => {
        return {
            ...state,
            assignProjetAudit: action.assignProjetAudit,
            assignProjetAuditSuccess: true,
            assignProjetAuditError: null,
        };
    }),
    on(AuditActions.assignProjetAuditError, (state, action) => {
        return {
            ...state,
            assignProjetAudit: null,
            assignProjetAuditSuccess: false,
            assignProjetAuditError: action.error,
        };
    }),
    on(AuditActions.activateProjetAudit, (state) => {
        return {
            ...state,
            activateProjetAudit: null,
            activateProjetAuditSuccess: false,
            activateProjetAuditError: null,
        };
    }),
    on(AuditActions.activateProjetAuditSuccess, (state, action) => {
        const projetAuditList = state.projetAuditList.map(projet =>
            projet.id === action.activateProjetAudit.id ? action.activateProjetAudit : projet
        );

        return {
            ...state,
            projetAuditList,
            activateProjetAudit: action.activateProjetAudit,
            activateProjetAuditSuccess: true,
            activateProjetAuditError: null,
        };
    }),
    on(AuditActions.activateProjetAuditError, (state, action) => {
        return {
            ...state,
            activateProjetAudit: null,
            activateProjetAuditSuccess: false,
            activateProjetAuditError: action.error,
        };
    }),
    on(AuditActions.setCurrentActiveProjetAudit, (state, action) => {
        return {
            ...state,
            currentActiveProjetAudit: action.projetAudit
        };
    }),
    on(AuditActions.setCurrentActiveProjetAuditById, (state, action) => {
        return {
            ...state,
            currentActiveProjetAudit: state.projetAuditList.find(projet => projet.id === action.projetAuditId),
        };
    }),
    on(AuditActions.setSelectedPointAudit, (state, action) => {
        return {
            ...state,
            selectedPointAudit: action.pointAudit,
        };
    }),
    on(AuditActions.cancelProjetAudit, (state) => {
        return {
            ...state,
            cancelProjetAudit: null,
            cancelProjetAuditSuccess: false,
            cancelProjetAuditError: null,
        };
    }),
    on(AuditActions.cancelProjetAuditSuccess, (state, action) => {
        return {
            ...state,
            cancelProjetAudit: action.cancelProjetAudit,
            cancelProjetAuditSuccess: true,
            cancelProjetAuditError: null,
        };
    }),
    on(AuditActions.cancelProjetAuditError, (state, action) => {
        return {
            ...state,
            cancelProjetAudit: null,
            cancelProjetAuditSuccess: false,
            cancelProjetAuditError: action.error,
        };
    }),
    on(AuditActions.rejectProjetAudit, (state) => {
        return {
            ...state,
            rejectProjetAudit: null,
            rejectProjetAuditSuccess: false,
            rejectProjetAuditError: null,
        };
    }),
    on(AuditActions.rejectProjetAuditSuccess, (state, action) => {
        return {
            ...state,
            rejectProjetAudit: action.projetAudit,
            rejectProjetAuditSuccess: true,
            rejectProjetAuditError: null,
        };
    }),
    on(AuditActions.rejectProjetAuditError, (state, action) => {
        return {
            ...state,
            rejectProjetAudit: null,
            rejectProjetAuditSuccess: false,
            rejectProjetAuditError: action.error,
        };
    }),
    on(AuditActions.completeProjetAudit, (state) => {
        return {
            ...state,
            completeProjetAudit: null,
            completeProjetAuditLoading: true,
            completeProjetAuditSuccess: false,
            completeProjetAuditError: null,
        };
    }),
    on(AuditActions.completeProjetAuditSuccess, (state, action) => {
        return {
            ...state,
            projetAuditList: state.projetAuditList.filter(projet => projet.id !== action.projetAudit.id),
            pointsAudit: [],
            completeProjetAudit: action.projetAudit,
            completeProjetAuditLoading: false,
            completeProjetAuditSuccess: true,
            completeProjetAuditError: null,
        };
    }),
    on(AuditActions.completeProjetAuditError, (state, action) => {
        return {
            ...state,
            completeProjetAudit: null,
            completeProjetAuditLoading: false,
            completeProjetAuditSuccess: false,
            completeProjetAuditError: action.error,
        };
    }),
    on(AuditActions.approveProjetAudit, (state) => {
        return {
            ...state,
            approveProjetAudit: null,
            approveProjetAuditLoading: true,
            approveProjetAuditSuccess: false,
            approveProjetAuditError: null,
        };
    }),
    on(AuditActions.approveProjetAuditSuccess, (state, action) => {
        return {
            ...state,
            approveProjetAudit: action.projetAudit,
            approveProjetAuditLoading: false,
            approveProjetAuditSuccess: true,
            approveProjetAuditError: null,
        };
    }),
    on(AuditActions.approveProjetAuditError, (state, action) => {
        return {
            ...state,
            approveProjetAudit: null,
            approveProjetAuditLoading: false,
            approveProjetAuditSuccess: false,
            approveProjetAuditError: action.error,
        };
    }),
    on(AuditActions.updatePointAudit, (state) => {
        return {
            ...state,
            updatePointAudit: null,
            updatePointAuditSuccess: false,
            updatePointAuditError: null
        };
    }),
    on(AuditActions.updatePointAuditSuccess, (state, action) => {
        return {
            ...state,
            pointsAudit: state.pointsAudit.map(point => point.id === action.pointAudit.id ? action.pointAudit : point),
            selectedPointAudit: action.pointAudit,
            updatePointAudit: action.pointAudit,
            updatePointAuditSuccess: true,
            updatePointAuditError: null,
        };
    }),
    on(AuditActions.updatePointAuditError, (state, action) => {
        return {
            ...state,
            updatePointAuditSuccess: false,
            updatePointAuditError: action.error
        };
    }),
    on(AuditActions.clearProjetAuditData, () => {
        return {
            ...auditInitialState
        };
    }),
    on(AuditActions.loadPointsAuditBbox, (state) => {
        return {
            ...state,
            pointsAuditLoading: true,
            loadPointsAuditError: null
        };
    }),
    on(AuditActions.loadPointsAuditBboxSuccess, (state, action) => {
        return {
            ...state,
            pointsAudit: action.pointsAudit,
            pointsAuditLoading: false,
            loadPointsAuditError: null
        };
    }),
    on(AuditActions.loadPointsAuditBboxError, (state, action) => {
        return {
            ...state,
            pointsAudit: [],
            pointsAuditLoading: false,
            loadPointsAuditError: action.error,
        };
    }),
    on(AuditActions.addPointAudit, (state) => {
        return {
            ...state,
            addPointAudit: null,
            addPointAuditSuccess: false,
            addPointAuditError: null
        };
    }),
    on(AuditActions.addPointAuditSuccess, (state, action) => {
        return {
            ...state,
            pointsAudit: [...state.pointsAudit, action.pointAudit],
            selectedPointAudit: action.pointAudit,
            addPointAudit: action.pointAudit,
            addPointAuditSuccess: true,
            addPointAuditError: null
        };
    }),
    on(AuditActions.addPointAuditError, (state, action) => {
        return {
            ...state,
            addPointAudit: null,
            addPointAuditSuccess: false,
            addPointAuditError: action.error
        };
    }),
    on(AuditActions.addPointAuditPhoto, (state) => {
        return {
            ...state,
            addPointAuditPhotoSuccess: false,
            addPointAuditPhotoError: null
        };
    }),
    on(AuditActions.addPointAuditPhotoSuccess, (state, action) => {
        const updatedPointsAudit = state.pointsAudit.map(pointAudit => {
            if (pointAudit.id === action.pointAudit.id) {
                return { ...pointAudit, photos: [...pointAudit.photos, action.photo] };
            } else {
                return pointAudit;
            }
        });

        return {
            ...state,
            pointsAudit: updatedPointsAudit,
            addPointAuditPhotoSuccess: true,
            addPointAuditPhotoError: null
        };
    }),
    on(AuditActions.addPointAuditPhotoError, (state, action) => {
        return {
            ...state,
            addPointAuditPhotoSuccess: false,
            addPointAuditPhotoError: action.error
        };
    }),
    on(AuditActions.addAnomalieAuditPhoto, (state) => {
        return {
            ...state,
            addAnomalieAuditPhotoSuccess: false,
            addAnomalieAuditPhotoError: null
        };
    }),
    on(AuditActions.addAnomalieAuditPhotoSuccess, (state, action) => {
        const updatedPointsAudit = state.pointsAudit.map(point =>
            point.id === action.pointAuditId
                ? {
                    ...point, anomaliesAudit: point.anomaliesAudit.map(
                        anomalie => anomalie.id === action.anomalieAuditId
                            ? {
                                ...anomalie,
                                photos: anomalie.photos?.length > 0 ? [...anomalie.photos, action.photo] : [action.photo]
                            } : anomalie
                    )
                }
                : point
        );
        return {
            ...state,
            pointsAudit: updatedPointsAudit,
            addAnomalieAuditPhotoSuccess: true,
            addAnomalieAuditPhotoError: null
        };
    }),
    on(AuditActions.addAnomalieAuditPhotoError, (state, action) => {
        return {
            ...state,
            addAnomalieAuditPhotoSuccess: false,
            addAnomalieAuditPhotoError: action.error
        };
    }),
    on(AuditActions.deletePointAuditPhoto, (state) => {
        return {
            ...state,
            deletePointAuditPhotoSuccess: false,
            deletePointAuditPhotoError: null
        };
    }),
    on(AuditActions.deletePointAuditPhotoSuccess, (state, action) => {
        const updatedPointsAudit = state.pointsAudit.map(pointAudit => {
            if (pointAudit.id === action.pointAudit.id) {
                const photos: PhotoEntity[] = [...pointAudit.photos];
                if (photos) {
                    const index = photos.findIndex(photo => photo.id === action.photo.id);

                    if (index > -1) {
                        photos.splice(index, 1);
                    }
                }

                return { ...pointAudit, photos: photos };
            } else {
                return pointAudit;
            }
        });
        return {
            ...state,
            pointsAudit: updatedPointsAudit,
            deletePointAuditPhotoSuccess: true,
            deletePointAuditPhotoError: null
        };
    }),
    on(AuditActions.deletePointAuditPhotoError, (state, action) => {
        return {
            ...state,
            deletePointAuditPhotoSuccess: false,
            deletePointAuditPhotoError: action.error
        };
    }),
    on(AuditActions.deleteAnomalieAuditPhoto, (state) => {
        return {
            ...state,
            deleteAnomalieAuditPhotoSuccess: false,
            deleteAnomalieAuditPhotoError: null
        };
    }),
    on(AuditActions.deleteAnomalieAuditPhotoSuccess, (state, action) => {
        const updatedPointsAudit = state.pointsAudit.map(pointAudit => {
            if (pointAudit.id === action.pointAudit.id) {
                const anomalies = pointAudit.anomaliesAudit?.length > 0 ? [...pointAudit.anomaliesAudit] : [];
                if (anomalies.length > 0) {
                    anomalies.map(anomalie => {
                        if (anomalie.id === action.anomalieAuditId) {
                            const photos: PhotoEntity[] = anomalie.photos?.length > 0 ? [...anomalie.photos] : [];
                            if (photos.length > 0) {
                                const index = photos.findIndex(photo => photo.id === action.photo.id);

                                if (index > -1) {
                                    photos.splice(index, 1);
                                }
                            }

                            return { ...anomalie, photos: photos };
                        } else {
                            return anomalie;
                        }
                    });
                }
                const photos: PhotoEntity[] = [...pointAudit.photos];
                if (photos) {
                    const index = photos.findIndex(photo => photo.id === action.photo.id);

                    if (index > -1) {
                        photos.splice(index, 1);
                    }
                }

                return { ...pointAudit, photos: photos };
            } else {
                return pointAudit;
            }
        });
        return {
            ...state,
            pointsAudit: updatedPointsAudit,
            deleteAnomalieAuditPhotoSuccess: true,
            deleteAnomalieAuditPhotoError: null
        };
    }),
    on(AuditActions.deleteAnomalieAuditPhotoError, (state, action) => {
        return {
            ...state,
            deleteAnomalieAuditPhotoSuccess: false,
            deleteAnomalieAuditPhotoError: action.error
        };
    }),
    on(AuditActions.getPointsActiveProjetAudit, (state) => {
        return {
            ...state,
            pointsAuditLoading: true,
            loadPointsAuditError: null
        };
    }),
    on(AuditActions.getPointsActiveProjetAuditSuccess, (state, action) => {
        return {
            ...state,
            pointsAudit: action.pointsAudit,
            pointsAuditLoading: false,
            loadPointsAuditError: null
        };
    }),
    on(AuditActions.getPointsActiveProjetAuditError, (state, action) => {
        return {
            ...state,
            pointsAudit: [],
            pointsAuditLoading: false,
            loadPointsAuditError: action.error,
        };
    }),
    on(AuditActions.getPointsAuditByProjetAuditId, (state) => {
        return {
            ...state,
            pointsAuditLoading: true,
            loadPointsAuditError: null
        };
    }),
    on(AuditActions.getPointsAuditByProjetAuditIdSuccess, (state, action) => {
        return {
            ...state,
            pointsAudit: action.pointsAudit,
            pointsAuditLoading: false,
            loadPointsAuditError: null
        };
    }),
    on(AuditActions.getPointsAuditByProjetAuditIdError, (state, action) => {
        return {
            ...state,
            pointsAudit: [],
            pointsAuditLoading: false,
            loadPointsAuditError: action.error,
        };
    }),
    on(AuditActions.createPointAudit, (state) => {
        return {
            ...state,
            createPointAudit: null,
            createPointAuditSuccess: false,
            createProjetAuditError: null
        };
    }),
    on(AuditActions.createPointAuditSuccess, (state, action) => {
        const pointsAudit = [...state.pointsAudit, action.pointAudit];

        const projetAuditList = state.projetAuditList.map(projet =>
            projet.id === action.pointAudit.projetAuditId
                ? { ...projet, pointAudits: pointsAudit }
                : projet
        );

        return {
            ...state,
            pointsAudit,
            projetAuditList,
            selectedPointAudit: action.pointAudit,
            createPointAudit: action.pointAudit,
            createPointAuditSuccess: true,
            createProjetAuditError: null
        };
    }),
    on(AuditActions.createPointAuditError, (state, action) => {
        return {
            ...state,
            createPointAuditSuccess: false,
            createProjetAuditError: action.error
        };
    }),
);

export function reducer(state: any, action: Action) {
    return auditReducer(state, action);
}
