import { AnomalieLayers } from '../../enums/anomalie-layers.enum';
import { LayerGroupe } from '../../enums/layer-groupe';
import { AnomalieProperties } from '../../features/anomalie/models/anomalie-properties.enum';
import { FeatureSource } from '../../map/models/feature-source.enum';
import { LayerType } from '../../map/models/layer-type.enum';
import { StyleLayer } from '../../map/models/style-layer.model';

export const ANOMALIES_STYLE_LEGENDE: StyleLayer[] = [
    {
        'nomGroupe': LayerGroupe.ANOMALIES,
        'urlimage': ['assets/images/general/Vegetation.png'],
        'value': {
            'id': AnomalieLayers.VEGETATION,
            'type': LayerType.SYMBOL,
            'source': FeatureSource.ANOMALIES,
            'minzoom': 10,
            'filter': ['==', ['get', AnomalieProperties.ELEMENT], AnomalieLayers.VEGETATION],
            'layout': {
                /* eslint-disable @typescript-eslint/naming-convention */
                'icon-image': 'Vegetation',
                'icon-allow-overlap': true, 'visibility': 'none'
                /* eslint-enable @typescript-eslint/naming-convention */
            },
            'paint': {
                /* eslint-disable @typescript-eslint/naming-convention */
                'icon-color': '#FFFFFF'
                /* eslint-enable @typescript-eslint/naming-convention */
            }
        }
    }
];
