import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Guid } from 'guid-typescript';
import { MessageService } from 'primeng/api';
import { concatMap, filter, finalize, from, map, takeUntil, toArray } from 'rxjs';
import { PhotoEntity, PointAuditDto, PointInspectionDto, PoteauBaseDto, ProjetAuditDto } from '../../../../core/api/client/models';
import { StatutPoteau } from '../../../../map/models/statut-poteau.enum';
import { PhotoService } from '../../../../services/photo.service';
import { BaseComponent } from '../../../../shared/components/abstract-base-component';
import { UserInformation } from '../../../../shared/models/user-informations.model';
import * as PhotosActions from '../../../../shared/photos/state/photos.actions';
import { arePhotosUploading, getPointAuditPhotos, getPointAuditPhotosLoading } from '../../../../shared/photos/state/photos.selectors';
import { exportPhotosInErrorList, getAddedPhotos, getDeletedPhotos } from '../../../../shared/utils/photo.utils';
import { State } from '../../../../state/app.state';
import { StatutPointInspection } from '../../../inspection/models/statut-point-inspection.enum';
import { StoreName } from '../../../offline/models/indexed-db-store-name.enum';
import { IndexedDbService } from '../../../offline/services/indexed-db.service';
import { MAX_PHOTO_POINT_AUDIT_NC } from '../../models/audit.const';
import { StatutPointAudit } from '../../models/statut-point-audit.enum';
import * as AuditActions from '../../state/audit.actions';
import { getCreatePointAuditSuccess, getUpdatePointAuditSuccess } from '../../state/audit.selectors';
import { getStatutGlobal } from '../../utils/audit.utils';
import { selectUtilisateurCanEditPointInspection } from '../../../../state/shared/shared.selectors';

@Component({
    selector: 'app-point-audit-non-conforme-dialog',
    templateUrl: './point-audit-non-conforme-dialog.component.html',
    styleUrls: ['./point-audit-non-conforme-dialog.component.scss']
})
export class PointAuditNonConformeDialogComponent extends BaseComponent implements OnInit {

    selectedPointAudit: PointAuditDto = {};

    @Input() set pointAudit(value: PointAuditDto) {
        if (value) {
            this.selectedPointAudit = value;
            if (value.hasOwnProperty('photos')) {
                this.originalPhotos = [...value['photos']];
                this.loadPhotos(this.originalPhotos);
            }
            this.initForm();
        }
    }
    @Input() currentActiveProjetAudit: ProjetAuditDto;
    @Input() currentIdentiteUtilisateur: UserInformation;
    @Input() visible: boolean;
    @Input() isCreatePoteau: boolean;
    @Output() isCreatePoteauChange = new EventEmitter<boolean>();
    @Output() visibleChange = new EventEmitter<boolean>();
    @Output() canceled = new EventEmitter<boolean>();

    public form: FormGroup;

    // Photos
    private addedPhotos: PhotoEntity[] = [];
    private deletedPhotos: PhotoEntity[] = [];
    private updatedPointAuditPhotos: PhotoEntity[] = [];
    public photosWithData: PhotoEntity[] = [];
    public originalPhotos: PhotoEntity[] = [];
    public uploadedPhotos: PhotoEntity[] = [];
    public uploadedPhotoLoading = false;
    public photosLoading = false;
    public photosUploading$ = this.store.select(arePhotosUploading);
    public readonly maxPhotoPointAuditNonConforme = MAX_PHOTO_POINT_AUDIT_NC;
    private canEditPointInspection: boolean;

    constructor(
        private store: Store<State>,
        private photoService: PhotoService,
        private dbService: IndexedDbService,
        private messageService: MessageService,
    ) {
        super();
        this.subscribeToUtilisateur();
    }

    ngOnInit(): void {
        this.initForm();
    }

    private initForm() {
        this.form = new FormGroup({
            remarque: new FormControl(this.selectedPointAudit.remarque, [Validators.required, Validators.maxLength(500)])
        });
    }

    private subscribeToUtilisateur() {
        this.store.select(selectUtilisateurCanEditPointInspection).pipe(
            takeUntil(this.destroyed)
        ).subscribe(canEditPointInspection => this.canEditPointInspection = canEditPointInspection);
    }

    private subscribeToGetCreatePointAuditSuccess() {
        this.store.select(getCreatePointAuditSuccess).pipe(
            filter(success => !!success),
            takeUntil(this.destroyed),
        ).subscribe(pointAudit => {
            if (pointAudit) {
                if (this.uploadedPhotos.length) {
                    this.savePointAuditPhoto(pointAudit, this.uploadedPhotos);
                }
                this.closePointAuditNonConformeDialog();
            }
        });
    }

    private subscribeToGetUpdatePointAuditSuccess() {
        this.store.select(getUpdatePointAuditSuccess).pipe(
            filter(success => !!success),
            takeUntil(this.destroyed),
        ).subscribe(pointAudit => {
            if (pointAudit) {
                this.savePointAuditPhoto(pointAudit, this.updatedPointAuditPhotos);
                this.closePointAuditNonConformeDialog();
            }
        });
    }

    public savePointAuditNonConforme() {
        if (this.isCreatePoteau) {
            this.createPoteauPointAuditNonConforme();
        } else {
            this.updatePointAuditNonConforme();
        }
    }

    private createPoteauPointAuditNonConforme() {
        const pointInspectionId = Guid.create().toString();

        this.selectedPointAudit = {
            ...this.selectedPointAudit,
            anomaliesAudit: [],
            auditeLe: new Date().getTime(),
            auditePar: this.currentIdentiteUtilisateur.courriel,
            id: Guid.create().toString(),
            pointInspectionId: pointInspectionId,
            photos: [],
            remarque: this.form.controls.remarque.value,
            statut: StatutPointAudit.nonConforme
        };

        const updatedPointAudit: PointAuditDto = {
            ...this.selectedPointAudit,
            statutGlobal: getStatutGlobal(this.selectedPointAudit)
        };

        const poteau: PoteauBaseDto = {
            creeLe: 0,
            equipements: [],
            id: pointInspectionId,
            idSig: 0,
            inspectionId: pointInspectionId,
            statut: StatutPoteau.ajoute
        };

        const pointInspection: PointInspectionDto = {
            accessibleCamion: 'inconnu',
            anomalies: [],
            compteur: 0,
            dateTransfertSap: 0,
            geometrie: updatedPointAudit.geometrie,
            id: pointInspectionId,
            photos: [],
            pointsAudit: [updatedPointAudit],
            poteau: poteau,
            poteauId: pointInspectionId,
            projetId: this.currentActiveProjetAudit.projetId,
            statut: StatutPointInspection.nonInspecte
        };

        this.store.dispatch(AuditActions.createPointAudit({ pointInspection }));

        if (this.uploadedPhotos.length) {
            this.subscribeToGetCreatePointAuditSuccess();
        } else {
            this.closePointAuditNonConformeDialog();
        }
    }



    private updatePointAuditNonConforme() {

        if (this.form.valid) {

            const pointAudit: PointAuditDto = {
                ...this.selectedPointAudit,
                statut: StatutPointAudit.nonConforme,
                remarque: this.form.controls.remarque.value,
                auditeLe: new Date().getTime(),
                auditePar: this.currentIdentiteUtilisateur.courriel,
                justification: null,
                photos: this.photoService.removeDataFromPhotoArray(this.selectedPointAudit.photos)
            };

            const updatedPointAudit: PointAuditDto = {
                ...pointAudit,
                statutGlobal: getStatutGlobal(pointAudit)
            };

            this.store.dispatch(AuditActions.updatePointAudit({ pointAudit: updatedPointAudit }));

            this.updatedPointAuditPhotos = [
                ...this.photosWithData,
                ...this.uploadedPhotos
            ];

            this.deletedPhotos = getDeletedPhotos(this.updatedPointAuditPhotos, this.originalPhotos);
            this.addedPhotos = getAddedPhotos(this.updatedPointAuditPhotos, this.originalPhotos, this.deletedPhotos);

            if (this.deletedPhotos.length > 0 || this.addedPhotos.length > 0) {
                this.subscribeToGetUpdatePointAuditSuccess();
            } else {
                this.closePointAuditNonConformeDialog();
            }
        }
    }

    public savePointAuditPhoto(pointAudit: PointAuditDto, updatedPointAuditPhotos: PhotoEntity[]) {
        const deletedPhotos = getDeletedPhotos(updatedPointAuditPhotos, this.originalPhotos);
        const addedPhotos = getAddedPhotos(updatedPointAuditPhotos, this.originalPhotos, deletedPhotos);

        deletedPhotos.forEach((deletedPhoto: PhotoEntity) => {
            this.store.dispatch(AuditActions.deletePointAuditPhoto({ pointAudit: pointAudit, photo: deletedPhoto }));
        });

        addedPhotos.forEach((addedPhoto: PhotoEntity) => {
            this.store.dispatch(AuditActions.addPointAuditPhoto({ pointAudit: pointAudit, photo: addedPhoto }));
        });
    }

    public closePointAuditNonConformeDialog(isCanceled = false) {
        this.form.reset();
        this.photosWithData = [];
        this.uploadedPhotos = [];
        this.visible = false;
        this.isCreatePoteauChange.emit(false);
        this.visibleChange.emit(this.visible);
        this.canceled.emit(isCanceled);
    }

    // Photos
    public removePhoto(photoId: string) {
        this.photosWithData = this.photoService.removePhoto(this.photosWithData, photoId);
    }

    public onUploadPhotos(photos: File[]) {
        this.uploadedPhotoLoading = true;
        from(photos).pipe(
            concatMap(photo => from(this.photoService.cleanImage(photo))
                .pipe(
                    map((imageBase64: string): PhotoEntity => {
                        return {
                            id: Guid.create().toString(),
                            nomOriginal: photo.name,
                            nom: '',
                            pointAuditId: this.selectedPointAudit.id,
                            data: this.photoService.base64ToFile(imageBase64, photo.name)
                        };
                    }),
                )
            ),
            toArray(),
            finalize(() => this.uploadedPhotoLoading = false)
        ).subscribe((cleanedPhotos) => this.uploadedPhotos = cleanedPhotos);
    }

    private loadPhotos(photosWithoutData: PhotoEntity[]) {
        // Ici, s'il est admin, on charge les photos de l'anomalie actuelle
        if (this.canEditPointInspection) {
            this.store.dispatch(PhotosActions.loadPointAuditPhotos({ photos: photosWithoutData }));

            this.store.select(getPointAuditPhotos)
                .pipe(
                    takeUntil(this.destroyed)
                ).subscribe(photosWithData => this.photosWithData = [...photosWithData]);

            this.store.select(getPointAuditPhotosLoading).pipe(
                takeUntil(this.destroyed)
            ).subscribe(loading => this.photosLoading = loading);
            // Sinon, on récupère les photos de indexdb
        } else {
            this.photosLoading = true;
            this.dbService.getAll<PhotoEntity>(StoreName.PHOTOS)
                .pipe(
                    takeUntil(this.destroyed)
                ).subscribe(photos => {
                    this.photosLoading = false;
                    const photoIds = photosWithoutData.map(photo => photo.id);
                    this.photosWithData = photos.filter(photo => photoIds.includes(photo.id));

                    if (this.photosWithData.length !== photosWithoutData.length) {
                        const photosInError = photosWithoutData.filter(photo => this.photosWithData.map(displayedPhoto => displayedPhoto.id).includes(photo.id));
                        exportPhotosInErrorList(photosInError);

                        this.messageService.add({
                            severity: 'warn',
                            summary: 'Photos manquantes',
                            detail: `Certaines photos n'ont pas pu être chargées. La liste des photos manquantes a été téléchargée.`,
                            life: 5000
                        });
                    }
                });
        }
    }
}
