import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivationEnd, Router } from '@angular/router';
import { BusyRouterService, ComponentBase } from '@ngxhq/common-ui';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { OpenIdService } from '@ngxhq/security';
import { Store } from '@ngrx/store';
import { State } from './state/app.state';
import { getUserFromClaims } from './core/store/actions/user.actions';
import { OAuthService } from 'angular-oauth2-oidc';
import { AppConfiguration } from './shared/config/app-config.model';
import { ServiceWorkerUpdateService } from './services/service-worker-update.service';
import { selectIsAppOnline } from './state/shared/shared.selectors';
import { LocalStorageService } from './services/local-storage.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})

export class AppComponent extends ComponentBase implements OnInit {
    layout = 'shell';
    autoLogoutIn: number | undefined;

    public isOnlineSoftwareState$ = this.store.select(selectIsAppOnline);
    public userRoles: string[] = [];

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private busyRouterService: BusyRouterService,
        private openIdService: OpenIdService,
        private zone: NgZone,
        private store: Store<State>,
        private titleService: Title,
        private configs: AppConfiguration,
        public swUpdateService: ServiceWorkerUpdateService,
        public localStorageService: LocalStorageService
    ) {
        super();
    }

    ngOnInit() {
        void this.getUserInfoAndClaims();
        this.activateApplicationAutoLayout();
        this.activateNavigationBusyState();
        this.listenToSessionTimeout();
        this.initSilentRefreshObservable();
        this.initEnvironment();
        this.swUpdateService.checkForUpdates();
    }

    private initEnvironment() {
        let curentEnvironment = '';
        if (this.configs && this.configs.environment && (this.configs.environment !== 'prod')) {
            curentEnvironment = ` - ${this.configs.environment.toUpperCase()}`;
        }
        this.titleService.setTitle(`Capture${curentEnvironment}`);
    }


    private async getUserInfoAndClaims() {
        const isAuthenticated = (await this.openIdService.getCurrentStatus()).isAuthenticated;
        if (isAuthenticated) {
            const userInfo = await this.openIdService.getUserInfo();
            const claims = await this.openIdService.getClaims();
            this.store.dispatch(getUserFromClaims({ userInfo: userInfo, claims: claims }));

            if (!this.localStorageService.hasCaptureRoleFromClaim()) {
                setTimeout(() => {
                    void this.router.navigate(['invalid-role']);
                }, 1000);
            }
        }
    }

    private activateApplicationAutoLayout() {
        this.router.events
            .pipe(
                filter((event) => event instanceof ActivationEnd),
                tap((event) => this.setComponentLayout(event as ActivationEnd)),
                takeUntil(this.destroyed)
            )
            .subscribe();
    }

    private setComponentLayout(event: ActivationEnd): void {
        const newLayout = event.snapshot.data &&
            event.snapshot.data.layout ?
            event.snapshot.data.layout : 'shell';
        if (newLayout !== this.layout) {
            this.layout = newLayout;
            this.changeDetectorRef.detectChanges();
        }
    }
    private activateNavigationBusyState() {
        this.busyRouterService.showBusyWhenNavigating()
            .pipe(takeUntil(this.destroyed))
            .subscribe();
    }

    private listenToSessionTimeout() {
        this.openIdService.idleTimeoutRemainingSeconds
            .subscribe({
                next: (x: number | undefined) => {
                    this.zone.run(() => {
                        this.autoLogoutIn = x;
                    });
                },
                error: (err: any) => {
                    console.error('idleTimeoutRemainingSeconds failed', err);
                    this.zone.run(() => {
                        this.autoLogoutIn = undefined;
                    });
                }
            });
    }

    private initSilentRefreshObservable(): void {
        this.isOnlineSoftwareState$
            .pipe(
                takeUntil(this.destroyed)
            ).subscribe({
                next: (online) => {
                    if (window.matchMedia('(display-mode: standalone)').matches || !online) {
                        this.openIdService['stopIdleIfRunning']();
                    } else {
                        this.openIdService['startIdleIfNotRunning']();
                    }

                    if (!online) {
                        (this.openIdService['oauthService'] as OAuthService).stopAutomaticRefresh();
                    } else {
                        (this.openIdService['oauthService'] as OAuthService).setupAutomaticSilentRefresh();
                    }
                }
            });
    }
}
