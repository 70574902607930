import { createAction, props } from '@ngrx/store';
import { ProjetAuditHistory } from '../../audit/models/projet-audit-history.model';
import { ProjetInspectionHistory } from '../models/projet-inspection-history.model';
import {
    ProjetCompletDto,
    PatchProjetInspectionDto,
    CreatedProjetDto,
    ProjetAuditDto,
    PatchProjetAuditDto,
    ResultatTransfertAvisInspection,
    CreatedProjetAuditDto,
    ProjetBaseDto,
    CreateProjetDto
} from '../../../core/api/client/models';

export enum ProjetsActionTypes {
    LOAD_PROJETS_LIST = '[Projets] Load Projets List',
    LOAD_PROJETS_LIST_SUCCESS = '[Projets] Load Projets List Success',
    LOAD_PROJETS_LIST_FAILURE = '[Projets] Load Projets List Failure',
    LOAD_PROJET_INSPECTION_HISTORY = '[Projets] Load Projet Inspection History',
    LOAD_PROJET_INSPECTION_HISTORY_SUCCESS = '[Projets] Load Projet Inspection History Success',
    LOAD_PROJET_INSPECTION_HISTORY_FAILURE = '[Projets] Load Projet Inspection History Failure',
    ASSIGN_PROJET_INSPECTION = '[Projets] Assign Projet Inspection',
    ASSIGN_PROJET_INSPECTION_SUCCESS = '[Projets] Assign Projet Inspection Success',
    ASSIGN_PROJET_INSPECTION_FAILURE = '[Projets] Assign Projet Inspection Failure',
    VALIDATE_PROJET_INSPECTION = '[Projets] Validate Projet Inspection',
    VALIDATE_PROJET_INSPECTION_SUCCESS = '[Projets] Validate Projet Inspection Success',
    VALIDATE_PROJET_INSPECTION_FAILURE = '[Projets] Validate Projet Inspection Failure',
    CANCEL_PROJET_INSPECTION = '[Projets] Cancel Projet Inspection',
    CANCEL_PROJET_INSPECTION_SUCCESS = '[Projets] Cancel Projet Inspection Success',
    CANCEL_PROJET_INSPECTION_FAILURE = '[Projets] Cancel Projet Inspection Failure',
    DELETE_PROJET_INSPECTION = '[Projets] Delete Projet Inspection',
    DELETE_PROJET_INSPECTION_SUCCESS = '[Projets] Delete Projet Inspection Success',
    DELETE_PROJET_INSPECTION_FAILURE = '[Projets] Delete Projet Inspection Failure',
    APPROVE_PROJET_INSPECTION = '[Projets] Approve Projet Inspection',
    APPROVE_PROJET_INSPECTION_SUCCESS = '[Projets] Approve Projet Inspection Success',
    APPROVE_PROJET_INSPECTION_FAILURE = '[Projets] Approve Projet Inspection Failure',
    REJECT_PROJET_INSPCTION = '[Projet] Reject Projet Inspection',
    REJECT_PROJET_INSPECTION_SUCCESS = '[Projet] Reject Projet Inspection Success',
    REJECT_PROJET_INSPECTION_FAILURE = '[Projet] Reject Projet Inspection Failure',
    UPDATE_PROJET_INSPCTION = '[Projet] Update Projet Inspection',
    UPDATE_PROJET_INSPECTION_SUCCESS = '[Projet] Update Projet Inspection Success',
    UPDATE_PROJET_INSPECTION_FAILURE = '[Projet] Update Projet Inspection Failure',
    CREATE_AVIS_SAP = '[Projets] Create Avis Sap',
    CREATE_AVIS_SAP_SUCCESS = '[Projets] Create Avis Sap Success',
    CREATE_AVIS_SAP_FAILURE = '[Projets] Create Avis Sap Failure',
    START_CREATE_PROJET_INSPECTION = '[Projets] Start Create Projet Inspection',
    START_CREATE_PROJET_INSPECTION_SUCCESS = '[Projets] Start Create Projet Inspection Success',
    START_CREATE_PROJET_INSPECTION_FAILURE = '[Projets] Start Create Projet Inspection Failure',
    CREATE_PROJET_INSPECTION_SUCCESS = '[Projets] Create Projet Inspection Success',
    CREATE_PROJET_INSPECTION_FAILURE = '[Projets] Create Projet Inspection Failure',

    LOAD_PROJET_AUDIT_LIST = '[Projets] Load Projet Audit List',
    LOAD_PROJET_AUDIT_LIST_SUCCESS = '[Projets] Load Projet Audit List Success',
    LOAD_PROJET_AUDIT_LIST_FAILURE = '[Projets] Load Projet Audit List Failure',
    LOAD_PROJET_AUDIT_HISTORY = '[Projets] Load Projet Audit History',
    LOAD_PROJET_AUDIT_HISTORY_SUCCESS = '[Projets] Load Projet Audit History Success',
    LOAD_PROJET_AUDIT_HISTORY_FAILURE = '[Projets] Load Projet Audit History Failure',
    ASSIGN_PROJET_AUDIT = '[Projets] Assign Projet Audit',
    ASSIGN_PROJET_AUDIT_SUCCESS = '[Projets] Assign Projet Audit Success',
    ASSIGN_PROJET_AUDIT_FAILURE = '[Projets] Assign Projet Audit Failure',
    APPROVE_PROJET_AUDIT = '[Projets] Approve Projet Audit',
    APPROVE_PROJET_AUDIT_SUCCESS = '[Projets] Approve Projet Audit Success',
    APPROVE_PROJET_AUDIT_FAILURE = '[Projets] Approve Projet Audit Failure',
    CANCEL_PROJET_AUDIT = '[Projets] Cancel Projet Audit',
    CANCEL_PROJET_AUDIT_SUCCESS = '[Projets] Cancel Projet Audit Success',
    CANCEL_PROJET_AUDIT_FAILURE = '[Projets] Cancel Projet Audit Failure',
    REJECT_PROJET_AUDIT = '[Projets] Reject Projet Audit',
    REJECT_PROJET_AUDIT_SUCCESS = '[Projets] Reject Projet Audit Success',
    REJECT_PROJET_AUDIT_FAILURE = '[Projets] Reject Projet Audit Failure',
    START_CREATE_PROJET_AUDIT = '[Projets] Start Create Projet Audit',
    START_CREATE_PROJET_AUDIT_SUCCESS = '[Projets] Start Create Projet Audit Success',
    START_CREATE_PROJET_AUDIT_FAILURE = '[Projets] Start Create Projet Audit Failure',
    CREATE_PROJET_AUDIT_SUCCESS = '[Projets] Create Projet Audit Success',
    CREATE_PROJET_AUDIT_FAILURE = '[Projets] Create Projet Audit Failure'
}

////////////////////////////////////////////////////////
//          Action pour la partie inspection          //
////////////////////////////////////////////////////////

/*** LOAD PROJETS LIST */
export const loadProjetsList = createAction(ProjetsActionTypes.LOAD_PROJETS_LIST, props<{ inclureProjetAudit: boolean }>());

export const loadProjetsListSuccess = createAction(ProjetsActionTypes.LOAD_PROJETS_LIST_SUCCESS, props<{ projetsList: ProjetCompletDto[] }>());

export const loadProjetsListFailure = createAction(ProjetsActionTypes.LOAD_PROJETS_LIST_FAILURE, props<{ error: any }>());

/*** LOAD PROJET INSPECTION HISTORY */
export const loadProjetInspectionHistory = createAction(ProjetsActionTypes.LOAD_PROJET_INSPECTION_HISTORY, props<{ projetInspection: ProjetCompletDto }>());

export const loadProjetInspectionHistorySuccess = createAction(ProjetsActionTypes.LOAD_PROJET_INSPECTION_HISTORY_SUCCESS,
    props<{ projetInspectionHistory: ProjetInspectionHistory[], nomProjet: string }>());

export const loadProjetInspectionHistoryFailure = createAction(ProjetsActionTypes.LOAD_PROJET_INSPECTION_HISTORY_FAILURE, props<{ error: any }>());

/*** CREATE PROJET INSPECTION ***/
export const startCreateProjetInspection = createAction(ProjetsActionTypes.START_CREATE_PROJET_INSPECTION, props<{ projetInspection: CreateProjetDto }>());

export const startCreateProjetInspectionSuccess = createAction(ProjetsActionTypes.START_CREATE_PROJET_INSPECTION_SUCCESS,
    props<{ createdProjetInspection: CreatedProjetDto }>());

export const startCreateProjetInspectionFailure = createAction(ProjetsActionTypes.START_CREATE_PROJET_INSPECTION_FAILURE, props<{ error: any }>());

export const createProjetInspectionSuccess = createAction(ProjetsActionTypes.CREATE_PROJET_INSPECTION_SUCCESS, props<{ createProjetInspection: ProjetCompletDto }>());

export const createProjetInspectionFailure = createAction(ProjetsActionTypes.CREATE_PROJET_INSPECTION_FAILURE, props<{ error: any }>());

/*** ASSIGN PROJET INSPECTION ***/
export const assignProjetInspection = createAction(ProjetsActionTypes.ASSIGN_PROJET_INSPECTION, props<{ projetId: string, assignData: PatchProjetInspectionDto }>());

export const assignProjetInspectionSuccess = createAction(ProjetsActionTypes.ASSIGN_PROJET_INSPECTION_SUCCESS, props<{ projetInspection: ProjetCompletDto }>());

export const assignProjetInspectionFailure = createAction(ProjetsActionTypes.ASSIGN_PROJET_INSPECTION_FAILURE, props<{ error: any }>());

/*** VALIDATE PROJET INSPECTION ***/
export const validateProjetInspection = createAction(ProjetsActionTypes.VALIDATE_PROJET_INSPECTION,
    props<{ projetInspectionId: string, validateData: PatchProjetInspectionDto }>());

export const validateProjetInspectionSuccess = createAction(ProjetsActionTypes.VALIDATE_PROJET_INSPECTION_SUCCESS, props<{ projetInspection: ProjetCompletDto }>());

export const validateProjetInspectionFailure = createAction(ProjetsActionTypes.VALIDATE_PROJET_INSPECTION_FAILURE, props<{ error: any }>());

/*** DELETE PROJET INSPECTION ***/
export const deleteProjetInspection = createAction(ProjetsActionTypes.DELETE_PROJET_INSPECTION, props<{ projetInspectionId: string }>());

export const deleteProjetInspectionSuccess = createAction(ProjetsActionTypes.DELETE_PROJET_INSPECTION_SUCCESS, props<{ projetInspectionId: string }>());

export const deleteProjetInspectionFailure = createAction(ProjetsActionTypes.DELETE_PROJET_INSPECTION_FAILURE, props<{ error: any }>());

/*** APPROVE PROJET INSPECTION ***/
export const approveProjetInspection = createAction(ProjetsActionTypes.APPROVE_PROJET_INSPECTION,
    props<{ projetInspectionId: string, approveData: PatchProjetInspectionDto }>());

export const approveProjetInspectionSuccess = createAction(ProjetsActionTypes.APPROVE_PROJET_INSPECTION_SUCCESS, props<{ projetInspection: ProjetCompletDto }>());

export const approveProjetInspectionFailure = createAction(ProjetsActionTypes.APPROVE_PROJET_INSPECTION_FAILURE, props<{ error: any }>());

/*** CANCEL PROJET INSPECTION ***/
export const cancelProjetInspection = createAction(ProjetsActionTypes.CANCEL_PROJET_INSPECTION,
    props<{ projetInspectionId: string, cancelData: PatchProjetInspectionDto }>());

export const cancelProjetInspectionSuccess = createAction(ProjetsActionTypes.CANCEL_PROJET_INSPECTION_SUCCESS, props<{ projetInspection: ProjetCompletDto }>());

export const cancelProjetInspectionFailure = createAction(ProjetsActionTypes.CANCEL_PROJET_INSPECTION_FAILURE, props<{ error: any }>());

/*** REJETER PROJET INSPECTION ***/
export const rejectProjetInspection = createAction(ProjetsActionTypes.REJECT_PROJET_INSPCTION,
    props<{ projetInspectionId: string, rejectData: PatchProjetInspectionDto }>());

export const rejectProjetInspectionSuccess = createAction(ProjetsActionTypes.REJECT_PROJET_INSPECTION_SUCCESS, props<{ projetInspection: ProjetCompletDto }>());

export const rejectProjetInspectionFailure = createAction(ProjetsActionTypes.REJECT_PROJET_INSPECTION_FAILURE, props<{ error: any }>());

/*** UPDATE PROJET INSPECTION ***/
export const updateProjetInspection = createAction(ProjetsActionTypes.UPDATE_PROJET_INSPCTION, props<{ projetInspectionId: string, updateData: ProjetBaseDto }>());

export const updateProjetInspectionSuccess = createAction(ProjetsActionTypes.UPDATE_PROJET_INSPECTION_SUCCESS, props<{ projetInspection: ProjetCompletDto }>());

export const updateProjetInspectionFailure = createAction(ProjetsActionTypes.UPDATE_PROJET_INSPECTION_FAILURE, props<{ error: any }>());

/*** CREATE AVIS SAP ***/
export const createAvisSap = createAction(ProjetsActionTypes.CREATE_AVIS_SAP, props<{ projetId: string }>());

export const createAvisSapSuccess = createAction(ProjetsActionTypes.CREATE_AVIS_SAP_SUCCESS, props<{ createAvisSap: ResultatTransfertAvisInspection[] }>());

export const createAvisSapFailure = createAction(ProjetsActionTypes.CREATE_AVIS_SAP_FAILURE, props<{ error: any }>());

///////////////////////////////////////////////////
//          Action pour la partie audit          //
///////////////////////////////////////////////////

/*** LOAD PROJET AUDIT LIST */
export const loadProjetAuditList = createAction(ProjetsActionTypes.LOAD_PROJET_AUDIT_LIST);

export const loadProjetAuditListSuccess = createAction(ProjetsActionTypes.LOAD_PROJET_AUDIT_LIST_SUCCESS, props<{ projetAuditList: ProjetAuditDto[] }>());

export const loadProjetAuditListFailure = createAction(ProjetsActionTypes.LOAD_PROJET_AUDIT_LIST_FAILURE, props<{ error: any }>());

/*** LOAD PROJET AUDIT HISTORY */
export const loadProjetAuditHistory = createAction(ProjetsActionTypes.LOAD_PROJET_AUDIT_HISTORY, props<{ projetAuditId: string }>());

export const loadProjetAuditHistorySuccess = createAction(ProjetsActionTypes.LOAD_PROJET_AUDIT_HISTORY_SUCCESS, props<{ projetAuditHistory: ProjetAuditHistory[] }>());

export const loadProjetAuditHistoryFailure = createAction(ProjetsActionTypes.LOAD_PROJET_AUDIT_HISTORY_FAILURE, props<{ error: any }>());

/*** ASSIGN PROJET AUDIT ***/
export const assignProjetAudit = createAction(ProjetsActionTypes.ASSIGN_PROJET_AUDIT, props<{ projetAuditId: string, assignData: PatchProjetAuditDto }>());

export const assignProjetAuditSuccess = createAction(ProjetsActionTypes.ASSIGN_PROJET_AUDIT_SUCCESS, props<{ projetAudit: ProjetAuditDto }>());

export const assignProjetAuditFailure = createAction(ProjetsActionTypes.ASSIGN_PROJET_AUDIT_FAILURE, props<{ error: any }>());

/*** APPROVE PROJET AUDIT ***/
export const approveProjetAudit = createAction(ProjetsActionTypes.APPROVE_PROJET_AUDIT, props<{ projetAuditId: string, approveData: PatchProjetAuditDto }>());

export const approveProjetAuditSuccess = createAction(ProjetsActionTypes.APPROVE_PROJET_AUDIT_SUCCESS, props<{ projetAudit: ProjetAuditDto }>());

export const approveProjetAuditFailure = createAction(ProjetsActionTypes.APPROVE_PROJET_AUDIT_FAILURE, props<{ error: any }>());

/*** CANCEL PROJET AUDIT ***/
export const cancelProjetAudit = createAction(ProjetsActionTypes.CANCEL_PROJET_AUDIT, props<{ projetAuditId: string, cancelData: PatchProjetAuditDto }>());

export const cancelProjetAuditSuccess = createAction(ProjetsActionTypes.CANCEL_PROJET_AUDIT_SUCCESS, props<{ projetAudit: ProjetAuditDto }>());

export const cancelProjetAuditFailure = createAction(ProjetsActionTypes.CANCEL_PROJET_AUDIT_FAILURE, props<{ error: any }>());

/*** REJECT PROJET AUDIT ***/
export const rejectProjetAudit = createAction(ProjetsActionTypes.REJECT_PROJET_AUDIT, props<{ projetAuditId: string, rejectData: PatchProjetAuditDto }>());

export const rejectProjetAuditSuccess = createAction(ProjetsActionTypes.REJECT_PROJET_AUDIT_SUCCESS, props<{ projetAudit: ProjetAuditDto }>());

export const rejectProjetAuditFailure = createAction(ProjetsActionTypes.REJECT_PROJET_AUDIT_FAILURE, props<{ error: any }>());

/*** CREATE PROJET AUDIT ***/
export const startCreateProjetAudit = createAction(ProjetsActionTypes.START_CREATE_PROJET_AUDIT, props<{ projetId: string, projetAudit: ProjetAuditDto }>());

export const startCreateProjetAuditSuccess = createAction(ProjetsActionTypes.START_CREATE_PROJET_AUDIT_SUCCESS, props<{ createdProjetAudit: CreatedProjetAuditDto }>());

export const startCreateProjetAuditFailure = createAction(ProjetsActionTypes.START_CREATE_PROJET_AUDIT_FAILURE, props<{ error: any }>());

export const createProjetAuditSuccess = createAction(ProjetsActionTypes.CREATE_PROJET_AUDIT_SUCCESS, props<{ createProjetAudit: ProjetAuditDto }>());

export const createProjetAuditFailure = createAction(ProjetsActionTypes.CREATE_PROJET_AUDIT_FAILURE, props<{ error: any }>());
