export enum CreateProjetMessage {
    CREATE_PROJET_FIRST = 'Veuillez créer un projet avant',
    SEARCH_LINE_FIRST = 'Veuillez cliquer sur "Rechercher la ligne" puis "Visualiser les poteaux" avant de sauvegarder votre projet.',
    DRAW_POLYGONE = 'Veuillez dessiner un polygone puis cliquer sur "Visualiser les poteaux" avant de sauvegarder votre projet',
    VISUALISE = `Veuillez visualiser les poteaux avant de sauvegarder le projet`,
    FILL_FIELD = `Veuillez remplir tous les champs`,
    LINE_NO_RESULT = 'Aucun résultat pour cette recherche de ligne',
    RAINETTE_FAUX_GRILLON = `Attention, le projet touche une zone protégée de rainette faux-grillon.` +
    ` Veuillez contacter votre administrateur de contrat afin de convenir des mesures à respecter.`,
    PROJET_X_SAVED = 'Votre demande de création de projet {0} a bien été enregistrée',
    IMPORT_FILE = 'Le fichier ne peut pas être importé',
}
