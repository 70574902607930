import { createAction, props } from '@ngrx/store';
import { BoundingBoxInfo } from '../../../shared/models/bounding-box-info.model';
import { StoreName } from '../models/indexed-db-store-name.enum';
import {
    AnomalieBaseDto,
    PhotoEntity,
    PointAuditDto,
    PointInspectionDto,
    ProjetAuditDto,
    ProjetCompletDto
} from '../../../core/api/client/models';

export enum OfflineActionTypes {
    LOAD_PROJET_AUDIT_LIST = '[Offline] Load Projet Audit List',
    LOAD_PROJET_INSPECTION_LIST = '[Offline] Load Projet Inspection List',
    ADD_PROJET_TO_INDEXEDDB = '[Offline] Add Projet To IndexedDb',
    ADD_PROJET_TO_INDEXEDDB_SUCCESS = '[Offline] Add Projet To IndexedDb Success',
    ADD_PROJET_TO_INDEXEDDB_ERROR = '[Offline] Add Projet To IndexedDb Error',
    ADD_PROJET_AUDIT_TO_INDEXEDDB = '[Offline] Add Projet Audit To IndexedDb',
    ADD_PROJET_AUDIT_TO_INDEXEDDB_SUCCESS = '[Offline] Add Projet Audit To IndexedDb Success',
    ADD_PROJET_AUDIT_TO_INDEXEDDB_ERROR = '[Offline] Add Projet Audit To IndexedDb Error',
    UPDATE_POINT_AUDIT = '[Offline] Update Point Audit',
    UPDATE_POINT_INSPECTION = '[Offline] Update Point Inspection',
    UPDATE_ANOMALIE_INSPECTION = '[Offline] Update Anomalie Inspection',
    UPDATE_PROJET_AUDIT_TO_INDEXEDDB = '[Offline] Update Projet Audit To IndexedDb',
    UPDATE_PROJET_AUDIT_TO_INDEXEDDB_SUCCESS = '[Offline] Update Projet Audit To IndexedDb Success',
    UPDATE_PROJET_AUDIT_TO_INDEXEDDB_ERROR = '[Offline] Update Projet Audit To IndexedDb Error',
    UPDATE_PROJET_TO_INDEXEDDB = '[Offline] Update Projet To IndexedDb',
    UPDATE_PROJET_TO_INDEXEDDB_SUCCESS = '[Offline] Update Projet To IndexedDb Success',
    UPDATE_PROJET_TO_INDEXEDDB_ERROR = '[Offline] Update Projet To IndexedDb Error',
    CREATE_POINT_AUDIT = `[Offline] Create Point Audit`,
    CREATE_POINT_INSPECTION = `[Offline] Create Point Inspection`,
    CREATE_ANOMALIE_INSPECTION = `[Offline] Create Anomalie Inspection`,
    ADD_POINT_AUDIT = '[Offline] Add Point Audit',
    ADD_POINT_AUDIT_PHOTO = '[Offline] Add Point Audit Photo',
    ADD_POINT_INSPECTION_PHOTO = '[Offline] Add Point Inspection Photo',
    ADD_ANOMALIE_AUDIT_PHOTO = '[Offline] Add Anomalie Audit Photo',
    ADD_ANOMALIE_INSPECTION_PHOTO = '[Offline] Add Anomalie Inspection Photo',
    DELETE_POINT_AUDIT_PHOTO = '[Offline] Delete Point Audit Photo',
    DELETE_POINT_INSPECTION_PHOTO = '[Offline] Delete Point Inspection Photo',
    DELETE_ANOMALIE_AUDIT_PHOTO = '[Offline] Delete Anomalie Audit Photo',
    DELETE_ANOMALIE_INSPECTION_PHOTO = '[Offline] Delete Anomalie Inspection Photo',
    DELETE_ANOMALIE_INSPECTION = '[Offline] Delete Anomalie Inspection',
    LOAD_POINTS_AUDIT_BBOX = '[Offline] Get Points Audit Bbox',
    LOAD_POINTS_INSPECTION_BBOX = '[Offline] Get Points Inspection Bbox',
    GET_POINTS_ACTIVE_PROJET = '[Offline] Get Points From Active Projet',
    GET_POINTS_ACTIVE_PROJET_INSPECTION = '[Offline] Get Points From Active Projet Inspection',
    GET_POINTS_ACTIVE_PROJET_AUDIT = '[Offline] Get Points From Active Projet Audit',
    GET_POINTS_PROJET_AUDIT = '[Offline] Get Points From Projet Audit',
    DELETE_PROJET_FROM_INDEXEDDB = '[Offline] Delete Projet From IndexedDb',
    DELETE_PROJET_FROM_INDEXEDDB_SUCCESS = '[Offline] Delete Projet From IndexedDb Success',
    DELETE_PROJET_FROM_INDEXEDDB_ERROR = '[Offline] Delete Projet From IndexedDb Error',
    DELETE_INSPECTION_PHOTOS_FROM_INDEXEDDB = '[Offline] Delete Inspection Photos From IndexedDb',
    DELETE_AUDIT_PHOTOS_FROM_INDEXEDDB = '[Offline] Delete Audit Photos From IndexedDb',
    DELETE_PHOTOS_FROM_INDEXEDDB_SUCCESS = '[Offline] Delete Inspection Photos From IndexedDb Success',
    DELETE_PHOTOS_FROM_INDEXEDDB_ERROR = '[Offline] Delete Inspection Photoss From IndexedDb Error',
    GET_PROJETS_DOWNLOADED = '[Offline] Get Projets Downloaded',
    GET_PROJETS_DOWNLOADED_SUCCESS = '[Offline] Get Projets Downloaded Success',
    GET_PROJETS_DOWNLOADED_ERROR = '[Offline] Get Projets Downloaded Error',
    GET_PROJETS_AUDIT_DOWNLOADED = '[Offline] Get Projets Audit Downloaded',
    GET_PROJETS_AUDIT_DOWNLOADED_SUCCESS = '[Offline] Get Projets Audit Downloaded Success',
    GET_PROJETS_AUDIT_DOWNLOADED_ERROR = '[Offline] Get Projets Audit Downloaded Error',
    GET_PROJET_INSPECTION_BY_ID = '[Offline] Get Projet Inspection By Id',
    GET_ESRI_ACCESS_TOKEN = '[Offline] Set Esri Access Token'
}

export const loadProjetAuditList = createAction(OfflineActionTypes.LOAD_PROJET_AUDIT_LIST);

export const loadProjetInspectionList = createAction(OfflineActionTypes.LOAD_PROJET_AUDIT_LIST);

export const addProjetToIndexedDb = createAction(OfflineActionTypes.ADD_PROJET_TO_INDEXEDDB, props<{ projetId: string }>());

export const addProjetToIndexedDbSuccess = createAction(OfflineActionTypes.ADD_PROJET_TO_INDEXEDDB_SUCCESS, props<{ projet: ProjetCompletDto }>());

export const addProjetToIndexedDbError = createAction(OfflineActionTypes.ADD_PROJET_TO_INDEXEDDB_ERROR, props<{ error: any }>());

export const addProjetAuditToIndexedDb = createAction(OfflineActionTypes.ADD_PROJET_AUDIT_TO_INDEXEDDB, props<{ projetAudit: ProjetAuditDto }>());

export const addProjetAuditToIndexedDbSuccess = createAction(OfflineActionTypes.ADD_PROJET_AUDIT_TO_INDEXEDDB_SUCCESS, props<{ projetAudit: ProjetAuditDto }>());

export const addProjetAuditToIndexedDbError = createAction(OfflineActionTypes.ADD_PROJET_AUDIT_TO_INDEXEDDB_ERROR, props<{ error: any }>());

export const updatePointAudit = createAction(OfflineActionTypes.UPDATE_POINT_AUDIT, props<{ projetAudit: ProjetAuditDto, pointAudit: PointAuditDto }>());

export const updatePointInspection = createAction(OfflineActionTypes.UPDATE_POINT_INSPECTION, props<{ pointInspection: PointInspectionDto, poteauAction?: string }>());

export const updateAnomalieInspection = createAction(OfflineActionTypes.UPDATE_ANOMALIE_INSPECTION,
    props<{ pointInspection: PointInspectionDto, anomalie: AnomalieBaseDto }>());

export const updateProjetAuditToIndexedDb = createAction(OfflineActionTypes.UPDATE_PROJET_AUDIT_TO_INDEXEDDB, props<{ projetAudit: ProjetAuditDto }>());

export const updateProjetAuditToIndexedDbSuccess = createAction(OfflineActionTypes.UPDATE_PROJET_AUDIT_TO_INDEXEDDB_SUCCESS, props<{ projetAudit: ProjetAuditDto[] }>());

export const updateProjetAuditToIndexedDbError = createAction(OfflineActionTypes.UPDATE_PROJET_AUDIT_TO_INDEXEDDB_ERROR, props<{ error: any }>());

export const updateProjetToIndexedDb = createAction(OfflineActionTypes.UPDATE_PROJET_TO_INDEXEDDB, props<{ projet: ProjetCompletDto }>());

export const updateProjetToIndexedDbSuccess = createAction(OfflineActionTypes.UPDATE_PROJET_TO_INDEXEDDB_SUCCESS, props<{ projet: ProjetCompletDto[] }>());

export const updateProjetToIndexedDbError = createAction(OfflineActionTypes.UPDATE_PROJET_TO_INDEXEDDB_ERROR, props<{ error: any }>());

export const createPointAudit = createAction(OfflineActionTypes.CREATE_POINT_AUDIT, props<{ pointInspection: PointInspectionDto }>());

export const createPointInspection = createAction(OfflineActionTypes.CREATE_POINT_INSPECTION, props<{ pointInspection: PointInspectionDto }>());

export const createAnomalieInspection = createAction(OfflineActionTypes.CREATE_ANOMALIE_INSPECTION,
    props<{ pointInspection: PointInspectionDto, anomalie: AnomalieBaseDto }>());

export const deleteAnomalieInspection = createAction(OfflineActionTypes.DELETE_ANOMALIE_INSPECTION, props<{ pointInspection: PointInspectionDto, anomalieId: string }>());

export const addPointAudit = createAction(OfflineActionTypes.ADD_POINT_AUDIT, props<{ projetAudit: ProjetAuditDto, pointAudit: PointAuditDto }>());

export const addPointAuditPhoto = createAction(OfflineActionTypes.ADD_POINT_AUDIT_PHOTO, props<{ pointAudit: PointAuditDto, photo: PhotoEntity }>());

export const addPointInspectionPhoto = createAction(OfflineActionTypes.ADD_POINT_INSPECTION_PHOTO, props<{ pointInspection: PointInspectionDto, photo: PhotoEntity }>());

export const addAnomalieAuditPhoto = createAction(OfflineActionTypes.ADD_ANOMALIE_AUDIT_PHOTO,
    props<{ pointAudit: PointAuditDto, anomalieAuditId: string, photo: PhotoEntity }>());

export const addAnomalieInspectionPhoto = createAction(OfflineActionTypes.ADD_ANOMALIE_INSPECTION_PHOTO,
    props<{ pointInspection: PointInspectionDto, anomalieId: string, photo: PhotoEntity }>());

export const deletePointAuditPhoto = createAction(OfflineActionTypes.DELETE_POINT_AUDIT_PHOTO, props<{ pointAudit: PointAuditDto, photo: PhotoEntity }>());

export const deletePointInspectionPhoto = createAction(OfflineActionTypes.DELETE_POINT_INSPECTION_PHOTO,
    props<{ pointInspection: PointInspectionDto, photo: PhotoEntity }>());

export const deleteAnomalieAuditPhoto = createAction(OfflineActionTypes.DELETE_ANOMALIE_AUDIT_PHOTO,
    props<{ pointAudit: PointAuditDto, anomalieAuditId: string, photo: PhotoEntity }>());

export const deleteAnomalieInspectionPhoto = createAction(OfflineActionTypes.DELETE_ANOMALIE_INSPECTION_PHOTO,
    props<{ pointInspection: PointInspectionDto, anomalieId: string, photo: PhotoEntity }>());

export const loadPointsAuditBbox = createAction(OfflineActionTypes.LOAD_POINTS_AUDIT_BBOX, props<{ bbox: BoundingBoxInfo }>());

export const loadPointsInspectionBbox = createAction(OfflineActionTypes.LOAD_POINTS_INSPECTION_BBOX, props<{ bbox: BoundingBoxInfo }>());

export const getPointsActiveProjetInspectionForAudit = createAction(OfflineActionTypes.GET_POINTS_ACTIVE_PROJET);

export const getPointsActiveProjetInspection = createAction(OfflineActionTypes.GET_POINTS_ACTIVE_PROJET_INSPECTION);

export const getPointsActiveProjetAudit = createAction(OfflineActionTypes.GET_POINTS_ACTIVE_PROJET_AUDIT);

export const deleteProjetFromIndexedDb = createAction(OfflineActionTypes.DELETE_PROJET_FROM_INDEXEDDB, props<{ storeName: StoreName, projetId: string }>());

export const deleteProjetFromIndexedDbSuccess = createAction(OfflineActionTypes.DELETE_PROJET_FROM_INDEXEDDB_SUCCESS, props<{ success: boolean }>());

export const deleteProjetFromIndexedDbError = createAction(OfflineActionTypes.DELETE_PROJET_FROM_INDEXEDDB_ERROR, props<{ error: any }>());

export const deleteInspectionPhotosFromIndexedDb = createAction(OfflineActionTypes.DELETE_INSPECTION_PHOTOS_FROM_INDEXEDDB, props<{ projetId: string }>());

export const deleteAuditPhotosFromIndexedDb = createAction(OfflineActionTypes.DELETE_AUDIT_PHOTOS_FROM_INDEXEDDB, props<{ projetAuditId: string }>());

export const deletePhotosFromIndexedDbSuccess = createAction(OfflineActionTypes.DELETE_PHOTOS_FROM_INDEXEDDB_SUCCESS, props<{ photoIds: number[] }>());

export const deletePhotosFromIndexedDbError = createAction(OfflineActionTypes.DELETE_PHOTOS_FROM_INDEXEDDB_ERROR, props<{ error: any }>());

export const getProjetsDownloaded = createAction(OfflineActionTypes.GET_PROJETS_DOWNLOADED);

export const getProjetsDownloadedSuccess = createAction(OfflineActionTypes.GET_PROJETS_DOWNLOADED_SUCCESS, props<{ projets: ProjetAuditDto[] }>());

export const getProjetsDownloadedError = createAction(OfflineActionTypes.GET_PROJETS_DOWNLOADED_ERROR, props<{ error: any }>());

export const getProjetsAuditDownloaded = createAction(OfflineActionTypes.GET_PROJETS_AUDIT_DOWNLOADED);

export const getProjetsAuditDownloadedSuccess = createAction(OfflineActionTypes.GET_PROJETS_AUDIT_DOWNLOADED_SUCCESS, props<{ projetsAudit: ProjetAuditDto[] }>());

export const getProjetsAuditDownloadedError = createAction(OfflineActionTypes.GET_PROJETS_AUDIT_DOWNLOADED_ERROR, props<{ error: any }>());

export const getProjetInspectionById = createAction(OfflineActionTypes.GET_PROJET_INSPECTION_BY_ID, props<{ projetId: string }>());

export const selectEsriAccessToken = createAction(OfflineActionTypes.GET_ESRI_ACCESS_TOKEN);
