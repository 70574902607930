export enum TraitementInitialValue {
    aucun = 'Aucun',
    buttCreosote = 'ButtCreosote',
    buttPCP = 'ButtPCP',
    cca = 'CCA',
    ccapa = 'CCAPA',
    ccapeg = 'CCAPEG',
    creosote = 'Creosote',
    pcp = 'PCP',
    sel = 'Sel',
    autre = 'Autre',
}

export enum TraitementInitial {
    aucun = 'aucun',
    buttCreosote = 'buttCreosote',
    buttPCP = 'buttPCP',
    cca = 'cca',
    ccapa = 'ccapa',
    ccapeg = 'ccapeg',
    creosote = 'creosote',
    pcp = 'pcp',
    sel = 'sel',
    autre = 'autre',
}

export const traitementInitialListe = Object.keys(TraitementInitial).map(
    key => ({ champ: key, valeur: TraitementInitialValue[key as keyof typeof TraitementInitial] })
);

export const traitementInitialMap = new Map<TraitementInitial, string>(
    [
        [TraitementInitial.aucun, TraitementInitialValue.aucun],
        [TraitementInitial.buttCreosote, TraitementInitialValue.buttCreosote],
        [TraitementInitial.buttPCP, TraitementInitialValue.buttPCP],
        [TraitementInitial.cca, TraitementInitialValue.cca],
        [TraitementInitial.ccapa, TraitementInitialValue.ccapa],
        [TraitementInitial.ccapeg, TraitementInitialValue.ccapeg],
        [TraitementInitial.creosote, TraitementInitialValue.creosote],
        [TraitementInitial.pcp, TraitementInitialValue.pcp],
        [TraitementInitial.sel, TraitementInitialValue.sel],
        [TraitementInitial.aucun, TraitementInitialValue.aucun],
    ]
);
