import { createFeatureSelector, createSelector } from '@ngrx/store';
import { InspectionState } from './inspection.state';
import { PointInspectionDto, ResultatTransfertAvisInspection } from '../../../core/api/client/models';
import { selectUtilisateurIsControleurQualite, selectUtilisateurIsInspecteur } from '../../../state/shared/shared.selectors';

const getInspectionFeatureState = createFeatureSelector<InspectionState>('inspection');

export const getProjetInspectionList = createSelector(
    getInspectionFeatureState,
    state => state.projetInspectionList!
);

export const getProjetInspectionListLoading = createSelector(
    getInspectionFeatureState,
    state => state.projetInspectionListLoading
);

export const getCreateProjetInspectionLoading = createSelector(
    getInspectionFeatureState,
    state => state.createProjetInspectionLoading
);

export const getCreateProjetInspectionSuccess = createSelector(
    getInspectionFeatureState,
    state => {
        return {
            success: state.createProjetInspectionSuccess,
            projetInspection: state.createProjetInspection
        };
    }
);

export const getAssignProjetInspectionSuccess = createSelector(
    getInspectionFeatureState,
    state => {
        return {
            success: state.assignProjetInspectionSuccess,
            projetInspection: state.assignProjetInspection
        };
    }
);

export const getAssignProjetInspectionError = createSelector(
    getInspectionFeatureState,
    state => state.assignProjetInspectionError
);

export const getActivateProjetInspectionSuccess = createSelector(
    getInspectionFeatureState,
    state => {
        return {
            success: state.activateProjetInspectionSuccess,
            projetInspection: state.activateProjetInspection
        };
    }
);

export const getCurrentActiveProjetInspection = createSelector(
    getInspectionFeatureState,
    state => state.currentActiveProjetInspection
);

export const getSelectedPointInspection = createSelector(
    getInspectionFeatureState,
    state => state.selectedPointInspection
);

export const getCancelProjetInspectionSuccess = createSelector(
    getInspectionFeatureState,
    state => {
        return {
            success: state.cancelProjetInspectionSuccess,
            projetInspection: state.cancelProjetInspection
        };
    }
);

export const getCancelProjetInspectionError = createSelector(
    getInspectionFeatureState,
    state => state.cancelProjetInspectionError
);

export const getRejectProjetInspectionSuccess = createSelector(
    getInspectionFeatureState,
    state => {
        return {
            success: state.rejectProjetInspectionSuccess,
            projetInspection: state.rejectProjetInspection
        };
    }
);

export const getCompleteProjetInspectionSuccess = createSelector(
    getInspectionFeatureState,
    state => state.completeProjetInspection
);

export const getCompleteProjetInspectionLoading = createSelector(
    getInspectionFeatureState,
    state => state.completeProjetInspectionLoading
);

export const getApproveProjetInspectionSuccess = createSelector(
    getInspectionFeatureState,
    state => {
        return {
            success: state.approveProjetInspectionSuccess,
            projetInspection: state.approveProjetInspection
        };
    }
);

export const getApproveProjetInspectionError = createSelector(
    getInspectionFeatureState,
    state => state.approveProjetInspectionError
);

export const getRejectProjetInspectionError = createSelector(
    getInspectionFeatureState,
    state => state.rejectProjetInspectionError
);

export const getCreatePointInspectionSuccess = createSelector(
    getInspectionFeatureState,
    state => state.createPointInspectionSuccess
);

export const getUpdatePointInspectionSuccess = createSelector(
    getInspectionFeatureState,
    state => state.updatePointInspectionSuccess
);

export const getCreateAnomalieInspectionSuccess = createSelector(
    getInspectionFeatureState,
    state => state.createAnomalieInspection
);

export const getUpdateAnomalieInspectionSuccess = createSelector(
    getInspectionFeatureState,
    state => state.updateAnomalieInspection
);

export const getDeleteAnomalieInspectionSuccess = createSelector(
    getInspectionFeatureState,
    state => state.deleteAnomalieInspectionSuccess
);

export const getPointsInspectionLoading = createSelector(
    getInspectionFeatureState,
    state => state.pointsInspectionLoading
);

export const getPointsActiveProjetInspectionSuccess = createSelector(
    getInspectionFeatureState,
    state => state.pointsInspection
);

export const getProjetInspection = createSelector(
    getInspectionFeatureState,
    state => state.projetInspection
);

export const getProjetInspectionById = (projetId: string) => createSelector(
    getInspectionFeatureState,
    state => state?.projetInspectionList?.find(projet => projet.id === projetId)
);

export const getPointInspectionById = (pointInspectionId: string) => createSelector(
    getInspectionFeatureState,
    state => state?.pointsInspection.find(point => point.id === pointInspectionId)
);

export const getAnomaliesByInspectionId = (pointInspectionId: string) => createSelector(
    getInspectionFeatureState,
    state => state?.pointsInspection.find(point => point.id === pointInspectionId)?.anomalies || []
);

export const getInvalidPointsInspectionLoading = createSelector(
    getInspectionFeatureState,
    state => state.pointsInspectionLoading
);

export const getInvalidPointsInspection = createSelector(
    getInspectionFeatureState,
    state => {
        const invalidPointsInspectionList: PointInspectionDto[] = [];

        for (const pi of state.pointsInspection) {
            if (pi.anomalies && pi.anomalies.length > 0 && (!pi.photos || pi.photos?.length === 0)) {
                invalidPointsInspectionList.push(pi);
            } else if (pi.anomalies && pi.anomalies.length > 0) {
                for (const anomalie of pi.anomalies) {
                    if (anomalie.photos?.length === 0) {
                        invalidPointsInspectionList.push(pi);
                        break;
                    }
                }
            }
        }

        return invalidPointsInspectionList;
    }
);

export const getResultatTransfertAvisErrorsLoading = createSelector(
    getInspectionFeatureState,
    state => state.pointsInspectionLoading
);

export const getResultatTransfertAvisErrors = (projetId: string, resultatTransfertAvis: ResultatTransfertAvisInspection[]) => createSelector(
    getInspectionFeatureState,
    state => {
        const invalidPointsInspectionList: [PointInspectionDto, string[]][] = [];

        if (state.pointsInspection.length > 0) {

            const foundPointInspections = state.pointsInspection
                .filter(pi => resultatTransfertAvis
                    .map(avis => avis.id).includes(pi.id) && pi.projetId === projetId);

            for (const pi of foundPointInspections) {
                const errors = [];

                const currentAvis = resultatTransfertAvis.find(c => c.id === pi.id);

                errors.push(...currentAvis.erreurs!);

                const anomaliesErrors = currentAvis.resultatsAnomalies.reduce((aggregatedErrors: string[], anomalie) => {
                    return aggregatedErrors.concat(anomalie.erreurs);
                }, []);

                errors.push(...anomaliesErrors);

                if (errors.length > 0) {
                    invalidPointsInspectionList.push([pi, errors]);
                }
            }
        }

        return invalidPointsInspectionList;
    }
);

export const filterDeletedAnomalies = (pointsInspection: PointInspectionDto[] = []) => createSelector( // TODO REFACTOR
    selectUtilisateurIsInspecteur,
    selectUtilisateurIsControleurQualite,
    (isInspecteur, isControlleurQualite) => {
        if (isInspecteur || isControlleurQualite) {
            return pointsInspection.map(pointInspection => {
                pointInspection.anomalies = pointInspection.anomalies?.filter(anomalie => !anomalie.statut);
                return pointInspection;
            });
        }

        return pointsInspection;
    }
);
