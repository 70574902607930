export enum PointInspectionProperties {
    ID = 'id',
    STATUT = 'statut',
    PROPRIETAIRE = 'proprietaire',
    CODE_A_BARRES = 'codeABarres',
    LCLCL_DISTANT = 'lclclDistant',

    ACCESSIBLE_CAMION = 'accessibleCamion',
    ADRESSE_TRAVAUX = 'adresseTravaux',
    IND_EQUIPEMENT_MAJEUR = 'indEquipementMajeur',
    LCLCL_POTEAU = 'lclclPoteau',

    // TODO: UNUSED AFTER REFACTOR. DELETE AFTER TESTING
    // inspectionId = 'inspectionId',
    // poteauId = 'poteau_id',
    // projetId = 'projetId',
    // pointInspectionId = 'pointInspectionId',
    // statut = 'Statut',
    // justification = 'Justification',
    // remarque = 'Remarque',
    // remarquePoteau = 'Remarque poteau',
    // inspecteLe = 'Inspecté le',
    // inspectePar = 'Inspecté par',
    // codeABarres = 'Code à barres',
    // lclclDistant = 'LCLCL distant',
    // lclclPoteau = 'LCLCL poteau',
    // proprietaire = 'Propriétaire',
    // montage = 'Montage',
    // longueur = 'Longueur (pied)',
    // hauteurHorsSol = 'Hauteur hors sol (mètre)',
    // classe = 'Classe',
    // materiau = 'Matériau',
    // anneeInstallation = `Année d'installation`,
    // anneeFabrication = 'Année de fabrication',
    // statutPoteau = 'Statut (poteau)',
    // usage = 'Usage',
    // inclinaison = 'Inclinaison (degré)',
    // accessibleCamion = 'Accessible camion nacelle',
    // indEquipementMajeur = `Présence d'appareil lourd`,
    // adresseTravaux = 'Adresse des travaux',
    // localisation = 'Localisation',
    // essence = 'Essence du poteau',
    // typePosition = 'Type de position',
    // fabricant = 'Fabricant',
    // traitementInitial = 'Traitement initial',
    // photos = 'photos',
    // pointsAudit = 'pointsAudit',
    // anomalies = 'anomalies',
    // malt = 'Malt',
    // tuteur = 'Tuteur',
    // luminaire = 'Luminaire',
    // jambeDeForce = 'Jambe de force',
    // liaisonAerosouterraine = 'Liaison aérosouterraine',
    // panneau = 'Panneau municipal',
    // equipementsMineurs = 'Équipements mineurs',
    // modifieLe = 'Modifié le',
    // modifiePar = 'Modifié par',
}
