import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { LocalStorageService } from '../services/local-storage.service';
import { inject } from '@angular/core';
import { LocalStorageIndex } from '../shared/enums/local-storage-index.enum';
import { combineLatest, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectUtilisateurRoles, selectUtilisateurHasCaptureRole } from '../state/shared/shared.selectors';
import { setUserActiveRole } from '../state/shared/shared.actions';

export const roleGuard: CanActivateFn = (): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
    const store = inject(Store);
    const router = inject(Router);
    const localStorageService = inject(LocalStorageService);

    const navigateTo = (path: string): boolean | UrlTree => void router.navigate([path]);

    const executeNavigation = (roles: string[], hasCaptureRole: boolean): boolean | UrlTree => {
        if (roles !== undefined) {
            if (roles.length === 0 || (roles.length > 0 && !hasCaptureRole)) {
                return navigateTo('invalid-role');
            } else if (roles.length === 1) {
                store.dispatch(setUserActiveRole({ role: roles[0] }));
                return navigateTo('carte');
            } else {
                return navigateTo('select-role');
            }
        }
        return false;
    };

    const activeRole = localStorageService.getItem(LocalStorageIndex.ACTIVE_ROLE);

    if (activeRole) {
        store.dispatch(setUserActiveRole({ role: activeRole }));
        return true;
    } else {
        combineLatest([
            store.select(selectUtilisateurRoles),
            store.select(selectUtilisateurHasCaptureRole)
        ]).subscribe(([roles, hasCptureRole]) => {
            executeNavigation(roles, hasCptureRole);
        });

        return false;
    }
};
