export enum AccessibleCamionValue {
    inconnu = 'Inconnu',
    oui = 'Oui',
    non = 'Non',
}

export enum AccessibleCamion {
    inconnu = 'inconnu',
    oui = 'oui',
    non = 'non',
}

export const accessibleCamionListe = Object.keys(AccessibleCamion).filter((key: string) => key !== 'inconnu').map((key: string) => (
    { champ: key, valeur: AccessibleCamionValue[key as keyof typeof AccessibleCamion] }
));

export const accessibleCamionMap = new Map<AccessibleCamion, string>(
    [
        [AccessibleCamion.inconnu, AccessibleCamionValue.inconnu],
        [AccessibleCamion.oui, AccessibleCamionValue.oui],
        [AccessibleCamion.non, AccessibleCamionValue.non],
    ]
);
