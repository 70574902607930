export enum Longueur {
    n30 = '30',
    n35 = '35',
    n40 = '40',
    n45 = '45',
    n50 = '50',
    n55 = '55',
    n60 = '60',
    n65 = '65',
    n70 = '70',
    n75 = '75',
    n85 = '85',
    n90 = '90',
    n95 = '95',
    n100 = '100',
    n105 = '105',
    n110 = '110',
    n115 = '115',
    n120 = '120',
    n125 = '125',
    autre = 'Autre',
}

export const longueurListe = Object.keys(Longueur).map(key => ({ champ: key, valeur: Longueur[key as keyof typeof Longueur] }));
