export enum ProjetLabels {
    ID = `Id`,
    NOM = `Nom du projet`,
    TYPE = `Type de projet`,
    STATUT = `Statut`,
    NUMERO_ORDRE_TRAVAIL = `Numéro d'ordre de travail`,
    CREE_LE = `Créé le`,
    FIRME = `Firme`,
    TERRITOIRE = `Territoire`,
    ANNEE_INSPECTION = `Année`,
    ASSIGNE_A = `Assigné à`,
}
