import { AfterViewChecked, Component, Input, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Table } from 'primeng/table';
import { ContextMenu } from 'primeng/contextmenu';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService, FilterMetadata, FilterService, MenuItem, MessageService, SelectItem } from 'primeng/api';

import { TableColumn } from '../../../../../shared/models/table-column.model';
import { MultiSelectGroupTableColumn } from '../../../../../shared/models/multi-select-group-table-column.model';
import { dateTimeChange, pickDataAndCreateTypeFromArray } from '../../../../../shared/utils';
import { FrenchTitleCasePipe } from '../../../../../shared/pipes/french-title-case.pipe';
import { BaseComponent } from '../../../../../shared/components/abstract-base-component';
import { customDateFilter, customNumberFilter, customTextFilter } from '../../../../../shared/filters/custom.filter';
import { ProjetAuditDto, ProjetCompletDto } from '../../../../../core/api/client/models';
import { ProjetsListDialog } from '../../../models/projets-list-dialog.model';
import { StatutProjetsInspection, StatutProjetsInspectionValue } from '../../../models/statut-projets-inspection.enum';
import { ProjetAuditContextualMenu } from '../../../menu/projet-audit-contextual-menu';
import { ProjetInspectionContextualMenu } from '../../../menu/projet-inspection-contextual-menu';
import { ExcelExtractionService } from '../../../services/excel-extraction.service';
import { ExtractionMenu } from '../../../menu/extraction.menu';
import { ProjetDataType } from '../../../models/projet-data.type';
import { selectDataExtractionLoading } from '../../../../../state/shared/shared.selectors';
import { ProjetType } from '../../../models/projet-type.enum';
import { ProjetTooltips } from '../../../models/projet-tooltips.enum';
import { LocalStorageIndex } from '../../../../../shared/enums/local-storage-index.enum';
import { LocalStorageService } from '../../../../../services/local-storage.service';
import { UiService } from '../../../../../services/ui.service';

export type ProjetsListDialogKeys = keyof ProjetsListDialog;
export interface DynamicSpecificFilterType {
    project: ProjetsListDialog,
    field: ProjetsListDialogKeys,
    value: any
}

@Component({
    selector: 'app-projets-list-dialog',
    templateUrl: './projets-list-dialog.component.html',
    styleUrls: ['./projets-list-dialog.component.scss'],
    providers: [
        ExtractionMenu
    ]
})
export class ProjetsListDialogComponent extends BaseComponent implements OnInit, AfterViewChecked {
    public localStorageTableKey = LocalStorageIndex.PROJETS_LIST;
    private customContainsFilter = 'custom-contains';
    private customContainsDateFilter = 'custom-contains-date';
    private customContainsNumberFilter = 'custom-contains-number';
    public resetFiltersLabel = 'Réinitialiser les filtres';
    public extraireRowsLabel = 'Projet(s) filtré(s)';
    public extraireColumnsLabel = 'Colonne(s) sélectionné(es)';
    public resetInterfaceLabel = 'Reset Interface';

    public projetTooltips = ProjetTooltips;

    public selectedProjet: ProjetsListDialog;
    public dataExtractionLoading = this.store.select(selectDataExtractionLoading);
    public extractOnlyRowsToggled = false;
    public extractOnlyColumnsToggled = false;
    public filterIsApplied = false;
    public globalFilterFields: string[] = [];   // Requis pour le filtre global
    public haveDataForExtraction: boolean = false;
    public extractFilteredRowsOnly: boolean;
    public extractFilteredColumnsOnly: boolean;
    public dynamicFilters: any = {};
    public dynamicFiltersModel: { [field: string]: any } = {};

    private frenchTitleCasePipe: FrenchTitleCasePipe = inject(FrenchTitleCasePipe);
    public totalFilteredValue: number = 10;
    public totalDeProjet: number = 0;
    public extractMenuItems: MenuItem[];
    public haveGroupsData = true;
    public colonnes: MultiSelectGroupTableColumn[] | TableColumn[] = [];
    public statutProjetEnum = StatutProjetsInspection;

    public items: MenuItem[];

    public selectedProjetAudit: ProjetAuditDto;
    public assignProjetAuditDialogVisible: boolean;

    public selectedColumns: TableColumn[] = [];
    private _proposedColumns: TableColumn[] = [];
    @Input() set proposedColumns(value: TableColumn[]) {
        this._proposedColumns = value;
        this.colonnes = this.generateColumnDatas();
        this.generateGlobalFieldsSearch();
        this.getDynamicSelectionFilters();
    };
    get proposedColumns(): TableColumn[] {
        return this._proposedColumns;
    }

    private _projets: ProjetsListDialog[];
    @Input() set projets(value: ProjetCompletDto[]) {
        this._projets = value;
        this.populateDynamicFilters();
        this.populateStaticFilters();
        this.totalDeProjet = value.length;
    }

    get projets(): ProjetsListDialog[] {
        return this._projets;
    }

    @Input() projetDataType: ProjetDataType;

    @ViewChild('projetsTable') projetsTable: Table;

    constructor(
        protected store: Store,
        private filterService: FilterService,
        protected confirmationService: ConfirmationService,
        protected readonly dialogService: DialogService,
        protected ref: DynamicDialogRef,
        protected readonly fb: FormBuilder,
        protected messageService: MessageService,
        private projetAuditContextMenu: ProjetAuditContextualMenu,
        private projetInspectionContextMenu: ProjetInspectionContextualMenu,
        private excelExtractionService: ExcelExtractionService,
        private extraction: ExtractionMenu,
        private localStorageService: LocalStorageService,
        private uiService: UiService,
    ) {
        super();
        this.registerFilter();
        this.extractMenuItems = this.extraction.getInspectionMenu();
    }

    ngAfterViewChecked(): void {
        if (this.localStorageService.getItem(this.localStorageTableKey)) {
            this.projetsTable.saveState();
        }
    }

    ngOnInit(): void {
        this.uiService.setProjetDataType(this.projetDataType);

        if (this.projetDataType !== ProjetType.INSPECTION) {
            this.projetAuditContextMenu.subscribeToPermissions();
        }

        this.setupExtractionMenu();
        this.initColumns();
    }

    public trackRows(_: number, item: ProjetsListDialog) {
        if (this.projetDataType === ProjetType.AUDIT) {
            return item.audit.id;
        }
        return item.id;
    }

    /************************************/
    /**    Functions for extraction    **/
    /************************************/
    public enableExtractionButton(): boolean {
        return this.selectedColumns.length > 0 && this.projets.length > 0;
    }

    public onExtractFilteredDataOnly($event: any): void {
        this.extractOnlyRowsToggled = $event.checked;
    }

    public exportCsvFormat(table: Table): void {
        this.excelExtractionService.exportCsv<ProjetCompletDto>(this.extractData(table));
    }

    public exportExcelFormat(table: Table): void {
        this.excelExtractionService.exportExcel<ProjetCompletDto>(this.extractData(table));
    }

    private extractData(table: Table): Partial<ProjetsListDialog>[] {
        return this.extractOnlyRowsToggled ? this.extractFilteredData(table) : this.formatDataToExtract(this.projets);
    }

    private extractFilteredData(table: Table): Partial<ProjetsListDialog>[] {
        const data = table.filteredValue ?? this.projets;
        return this.formatDataToExtract(data);
    }

    private getColumnsToExtract(): TableColumn[] {
        return this.extractOnlyColumnsToggled ? this.selectedColumns : this.proposedColumns;
    }

    private getDatesColumnsNames(columns: TableColumn[]): string[] {
        columns = columns.filter(col => col.isDate);
        return columns.map(col => col.field);
    }

    private removeProperty<T>(record: T, propNames: (keyof T)[]): any {
        const recordCopy: T = { ...record };
        propNames.forEach(propName => delete recordCopy[propName]);
        return recordCopy;
    }

    private formatDataToExtract(projets: ProjetsListDialog[]): Partial<ProjetsListDialog>[] {
        const formatDate = (date: Date | number) => date ? dateTimeChange(date.toString(), 'YYYY-MM-dd') : '';
        const columnToExtract: TableColumn[] = this.getColumnsToExtract();
        const selectedColumns: string[] = columnToExtract.map(col => col.field);
        const data = pickDataAndCreateTypeFromArray<ProjetsListDialog>(projets, selectedColumns);
        const datesColumnsNames = this.getDatesColumnsNames(columnToExtract);
        return data.map((row: any) => ({
            ...this.removeProperty<ProjetsListDialog>(row, ['audit']),
            ...datesColumnsNames.reduce((acc: any, key: any) => {
                acc[key] = row[key] !== undefined ? formatDate(row[key]) : '';
                return acc;
            }, {})
        }));
    }

    private setupExtractionMenu() {
        switch (this.projetDataType) {
            case ProjetType.AUDIT: this.extractMenuItems = this.extraction.getAuditMenu(); break;
            case ProjetType.INSPECTION: this.extractMenuItems = this.extraction.getInspectionMenu(); break;
            default: {
                this.extractMenuItems = [
                    this.extraction.getInspectionAsSubMenu(),
                    this.extraction.getAuditAsSubMenu()
                ];
            } break;
        }
    }

    /***************************************/
    /**    Functions column management    **/
    /***************************************/
    public onColumnChange(event: any): void {
        this.selectedColumns = event.value;

        this.setStaticColumns();

        if (this.selectedColumns.length === 0) {
            this.projetsTable.clearState();
        }
        this.saveSelectedColumnNames();

        this.haveDataForExtraction = this.enableExtractionButton();
    }

    private saveSelectedColumnNames(): void {
        const selectedColumnNames = this.selectedColumns.map(col => col.field);
        if (selectedColumnNames.length > 1 || (selectedColumnNames.length === 1 && selectedColumnNames[0] !== 'actions')) {
            this.localStorageService.setItem(LocalStorageIndex.PROJETS_LIST_DIALOG_COLUMN_NAMES, JSON.stringify(selectedColumnNames));
        } else {
            this.selectedColumns = [];
            this.deleteTableFilterKeys();
        }
    }

    private deleteTableFilterKeys(): void {
        this.localStorageDeleteKey(LocalStorageIndex.PROJETS_LIST_DIALOG_COLUMN_NAMES);
        this.localStorageDeleteKey(this.localStorageTableKey);
    }

    private localStorageDeleteKey(key: string): void {
        if (this.localStorageService.getItem(key)) {
            this.localStorageService.removeItem(key);
        }
    }

    private initColumns(): void {
        this.setColumns();
        this.setStaticColumns();

        this.haveDataForExtraction = this.enableExtractionButton();
    }

    private setColumns(): void {
        const selectedColumnNamesFromLocalStorage = this.localStorageService.getItem(LocalStorageIndex.PROJETS_LIST_DIALOG_COLUMN_NAMES);
        if (selectedColumnNamesFromLocalStorage) {
            const parsedNames = JSON.parse(selectedColumnNamesFromLocalStorage);
            this.selectedColumns = this.proposedColumns.filter(col => parsedNames.includes(col.field));
        } else {
            this.selectedColumns = this.proposedColumns.filter(col => col.showByDefault);
        }
    }

    private setStaticColumns(): void {
        if (this.selectedColumns.length > 0) {
            this.addNonToggleableColumns();
        }
        this.moveActionToEnd();
    }

    private addNonToggleableColumns(): void {
        this.proposedColumns.forEach((col: TableColumn) => {
            if (!col.toggleable && !this.selectedColumns.includes(col)) {
                this.selectedColumns.push(col);
            }
        });
    }

    private moveActionToEnd(): void {
        const actionIndex = this.selectedColumns.findIndex(col => col.field === 'actions');
        if (actionIndex !== -1 && actionIndex !== this.selectedColumns.length - 1) {
            const actionColumn = this.selectedColumns.splice(actionIndex, 1)[0];
            this.selectedColumns.push(actionColumn);
        }
    }

    private getGroups(): string[] {
        return this.proposedColumns.map(col => col.group ?? null).filter((value, index, self) => self.indexOf(value) === index).filter(value => value !== null);
    }

    private createGroup(value: string): MultiSelectGroupTableColumn {
        return {
            label: this.frenchTitleCasePipe.transform(value),
            value: value,
            items: this.proposedColumns.filter(col => col.group === value)
        };
    }

    private generateColumnDatas(): MultiSelectGroupTableColumn[] | TableColumn[] {
        const groups = this.getGroups();
        if (groups.length <= 1) {
            this.haveGroupsData = false;
            return this.proposedColumns.filter(col => col.toggleable === undefined || col.toggleable === true);
        }

        this.haveGroupsData = true;
        return groups.map(group => this.createGroup(group));
    }


    /********************************/
    /**    Functions for Table     **/
    /********************************/
    public onSaveTableState(_: Table): void {
        this.saveSelectedColumnNames();
    }

    public onRestoreTableState(table: Table): void {
        this.isTableContainsFilters(table);
        this.restoreDynamicFilters(table);
    }

    public onDoubleClickRow(projet: ProjetsListDialog): void {
        this.selectedProjet = projet;
        this.zoom();
    }

    public resetInterface(table: Table): void {
        this.clearTable(table);
        this.deleteTableFilterKeys();
        this.ngOnInit();
    }

    /**************************************/
    /**    Functions for actions menu    **/
    /**************************************/
    private zoom(): void {
        if (this.projetDataType === ProjetType.AUDIT) {
            this.projetAuditContextMenu.getZoomSurAuditMenuItem(this.selectedProjet.audit).command();
        } else {
            this.projetInspectionContextMenu.getZoomSurMenuItem(this.selectedProjet).command();
        }
    }

    private initMenu(): void {
        if (this.projetDataType === ProjetType.AUDIT) {
            this.items = this.projetAuditContextMenu.getFullAuditMenu(this.selectedProjet.audit);
        } else if (this.projetDataType === ProjetType.INSPECTION) {
            this.items = this.projetInspectionContextMenu.getFullInspectionMenu(this.selectedProjet);
        } else {
            this.items = [
                {
                    label: 'Extraire historique...',
                    disabled: this.projetInspectionContextMenu.isProjetHistoriqueDisabled() && this.projetAuditContextMenu.isAuditHistoriqueDisabled(),
                    items: [
                        this.projetInspectionContextMenu.getExtraireHistoriqueMenuItem(this.selectedProjet, `Projet d'inspection`),
                        this.projetAuditContextMenu.getExtraireHistoriqueAuditMenuItem(this.selectedProjet.audit, `Projet d'audit`)
                    ]
                },
                this.projetInspectionContextMenu.getRapportSubMenu(this.selectedProjet),
                {
                    label: 'Audit...',
                    items: this.projetAuditContextMenu.getAuditMenuAsSubmenu(this.selectedProjet.audit),
                    disabled: this.selectedProjet.audit === undefined,
                    visible: true
                },
                { separator: true },
                this.projetInspectionContextMenu.getZoomSurMenuItem(this.selectedProjet),
                { separator: true },
                ...this.projetInspectionContextMenu.getAssignationSubMenu(this.selectedProjet),
                { separator: true },
                ...this.projetInspectionContextMenu.getActionSubMenu(this.selectedProjet),

            ];
        }
    }

    public onActionsButtonClick(event: MouseEvent, contextMenu: ContextMenu, project: ProjetsListDialog): void {
        event.stopPropagation();
        event.preventDefault();

        this.selectedProjet = project;
        this.setMenuPermissions();
        this.initMenu();

        contextMenu.show(event);
    }

    private setMenuPermissions(): void {
        this.projetInspectionContextMenu.setMenuItemStatusForInspection(this.selectedProjet);
        if (this.projetDataType !== ProjetType.INSPECTION) {
            this.projetAuditContextMenu.setMenuItemStatusForAudit(this.selectedProjet.audit);
        }
    }

    /************************************/
    /**    Functions for filtering     **/
    /************************************/

    public globalFilter(value: string): void {
        this.projetsTable.filterGlobal(value, 'contains');
        this.isTableContainsFilters(this.projetsTable);
    }

    public onFilter(projectsAndAudits: Table): void {
        this.totalFilteredValue = projectsAndAudits.filteredValue?.length ?? 0;
    }

    public determineFilterType(col: Partial<TableColumn>): string {
        if (col.filterType) {
            switch (col.filterType) {
                case 'dropdown': return 'equals';
                case 'multiSelect': return 'in';
                default: {
                    if (col.inputFilterDataType === 'number') {
                        return this.customContainsNumberFilter;
                    } else if (col.inputFilterDataType === 'date') {
                        return this.customContainsDateFilter;
                    } else {
                        return this.customContainsFilter;
                    }
                };
            }
        } else { // Si aucun filtreType n'est spécifié, on utilise le filtre input par défaut
            if (col.isDate) {
                return this.customContainsDateFilter;
            } if (col.inputFilterDataType === 'number') {
                return this.customContainsNumberFilter;
            } else if (col.inputFilterDataType === 'date') {
                return this.customContainsDateFilter;
            }

            return this.customContainsFilter;
        }
    }

    public onColumnFilter(value: any, col: Partial<TableColumn>): void {
        const filterType = this.determineFilterType(col);
        this.projetsTable.filter(value, col.field, filterType);
        this.isTableContainsFilters(this.projetsTable);
    }

    public isTableContainsFilters(table: Table): void {
        this.filterIsApplied = Object.keys(table.filters).length > 0;
    }

    public clearTable(table: Table): void {
        table.clear();
        this.clearColumnFilters();
        this.isTableContainsFilters(this.projetsTable);
        this.clearSelectionFilterValues();
        this.projetsTable.saveState();
    }

    private clearSelectionFilterValues(): void {
        Object.keys(this.dynamicFiltersModel).forEach(key => {
            this.dynamicFiltersModel[key] = null;
        });
    }

    private restoreDynamicFilters(table: Table): void {
        if (table) {
            Object.keys(this.dynamicFiltersModel).forEach(key => {
                if (table.filters[key]) {
                    this.dynamicFiltersModel[key] = (table.filters[key] as FilterMetadata).value;
                }
            });
        }
    }

    private clearColumnFilters(): void {
        const filterKeys = Object.keys(this.projetsTable.filters);
        filterKeys.forEach(key => {
            delete this.projetsTable.filters[key];
        });
    }

    private generateGlobalFieldsSearch(): void {
        this.proposedColumns.forEach((col: TableColumn) => {
            if (col.filterable !== false && col.isDate !== true) {
                this.globalFilterFields.push(col.field);
            }
        });
    }

    private getDynamicSelectionFilters(): void {
        const filterTypes = this.proposedColumns.map(col => col.filterType).filter((value, index, self) => self.indexOf(value) === index)
            .filter(value => value !== undefined && value !== null);

        const datas: any = {};
        filterTypes.forEach(filterType => {
            const filterTypeFields: any = {};
            this.proposedColumns.filter(col => col.filterType === filterType).forEach(col => {
                filterTypeFields[col.field] = null;
                this.dynamicFiltersModel[col.field] = null;
            });

            datas[filterType] = filterTypeFields;
        });

        this.dynamicFilters = datas;
    }


    private dynamicFilterFirmeNameValues(datas: DynamicSpecificFilterType[]): SelectItem<any>[] {
        const fullValues = datas.map(data => { return { label: data.value, value: data.value }; });
        const finalData: { label: string, value: any }[] = [];
        fullValues.forEach(fullValue => {
            if (finalData.findIndex(data => data.value === fullValue.value) === -1) {
                finalData.push(fullValue);
            }
        });
        return finalData;
    }

    private defaultDynamicFilterValues(datas: DynamicSpecificFilterType[]): SelectItem<any>[] {
        const values = datas.map(project => project.value).filter((value, index, self) => self.indexOf(value) === index).map(value => {
            return {
                label: value,
                value: value
            };
        });
        return values;
    }

    private populateDynamicFilters(): void {
        Object.keys(this.dynamicFilters).forEach((filterType: any) => {
            const filterTypeFields = Object.keys(this.dynamicFilters[filterType]);
            filterTypeFields.forEach((field: string) => {
                const myfield = field as ProjetsListDialogKeys;
                const projectMapped = this.projectFilterMapping(myfield);
                this.dynamicFilters[filterType][field] = this.specificFilterType(projectMapped);
            });
        });
    }

    private projectFilterMapping(field: ProjetsListDialogKeys): DynamicSpecificFilterType[] {
        if (this.projetDataType !== ProjetType.INSPECTION && field.includes('audit.')) {
            const column = this.proposedColumns.find(col => col.field as ProjetsListDialogKeys === field);
            return this.projets.map((project: ProjetsListDialog) => {
                return {
                    project: project,
                    field: field,
                    value: (project as any)[column.fieldObj] ? (project as any)[column.fieldObj][column.fieldProp] : ''
                };
            });
        }
        return this.projets.map(projet => { return { project: projet, field: field, value: projet[field] }; });
    }

    private specificFilterType(datas: DynamicSpecificFilterType[]): SelectItem<any>[] {
        switch (datas[0].field) {
            case 'firmeName': return this.dynamicFilterFirmeNameValues(datas);
            default: return this.defaultDynamicFilterValues(datas);
        }
    }

    private isFieldExistInDynamicFilters(filterType: string, field: string): boolean {
        return this.dynamicFilters.hasOwnProperty(filterType) && this.dynamicFilters[filterType].hasOwnProperty(field);
    }

    private populateStaticFilters(): void {
        if (this.isFieldExistInDynamicFilters('multiSelect', 'statut')) {
            const statusFiltersItems: SelectItem<any>[] = [];
            (Object.values(StatutProjetsInspection)).forEach((statut) => {
                statusFiltersItems.push({
                    label: StatutProjetsInspectionValue[statut],
                    value: statut
                });
            });

            statusFiltersItems.sort((a: SelectItem<any>, b: SelectItem<any>) => {
                if (a.label < b.label) { return -1; }
                if (a.label > b.label) { return 1; }
                return 0;
            });

            this.dynamicFilters['multiSelect']['statut'] = statusFiltersItems;
        }
    }

    // TODO: Mart: rapidité des filtre laisse à désirer
    private registerFilter(): void {
        this.filterService.register(this.customContainsFilter, customTextFilter);
        this.filterService.register(this.customContainsDateFilter, customDateFilter);
        this.filterService.register(this.customContainsNumberFilter, customNumberFilter);
    }
}
