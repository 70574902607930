import { createSelector } from '@ngrx/store';
import { UserRole } from 'src/app/shared/models/user-roles.model';
import { User, userSelectors } from '../reducers/user.reducer';
import { CoreState, getCoreState } from '../reducers';
import { OpenIdClaims, OpenIdUserInfo } from '@ngxhq/security';

export const getUserState = createSelector(
    getCoreState,
    (state: CoreState) => state && state.user
);

export const getAllUserstate = createSelector(
    getUserState,
    userSelectors.selectAll
);

export const getUserClaimAndOpenIdInfo = createSelector(
    getAllUserstate,
    (state: User[]) => {
        if (state[0]) {
            return state && state[0].user as Partial<OpenIdClaims & OpenIdUserInfo & { roles: UserRole[]; }>;
        } else {
            return {};
        };
    }
);
