import { Component, Input, OnInit } from '@angular/core';
import { MemoizedSelector, Store } from '@ngrx/store';
import { State } from '../../../../state/app.state';
import { filter, takeUntil } from 'rxjs/operators';
import { utils, WorkBook, write } from 'xlsx';
import { dateTimeChange, saveFile } from '../../../../shared/utils';
import { MessageService } from 'primeng/api';
import { ProjetAuditDto } from '../../../../core/api/client/models';
import { ProjetAuditHistory } from '../../models/projet-audit-history.model';
import { MultiSelectFilters } from '../../../../shared/models/multi-select-filters.model';
import { BaseComponent } from '../../../../shared/components/abstract-base-component';
import { StatutProjetAuditValue } from '../../models/statut-projet-audit.enum';
import * as AuditActions from '../../state/audit.actions';
import * as InspectionActions from '../../../inspection/state/inspection.actions';
import {
    getAssignProjetAuditError,
    getAssignProjetAuditSuccess,
    getCancelProjetAuditError,
    getCancelProjetAuditSuccess,
    getRejectProjetAuditError,
    getRejectProjetAuditSuccess,
    getProjetAuditHistory,
    getProjetAuditHistoryError,
    getApproveProjetAuditError,
    getApproveProjetAuditSuccess
} from '../../state/audit.selectors';
import {
    projetAuditListDialogColumn,
    projetAuditListMobileDialogColumn
} from '../../models/projet-audit-list-dialog.column';
import {
    selectCanApproveProjetAudit,
    selectCanAssignProjetAudit,
    selectCanCancelProjetAudit,
    selectCanCompleteProjetAudit,
    selectCanExtractAuditHistory,
    selectCanRejectProjetAudit,
    selectCanZoomProjetAudit,
    selectIsAppOnline,
    selectUtilisateurIsMobile
} from '../../../../state/shared/shared.selectors';
import { StatutPointAuditValue } from '../../models/statut-point-audit.enum';

@Component({
    selector: 'app-projet-audit-list',
    templateUrl: './projet-audit-list.component.html',
    styleUrls: ['./projet-audit-list.component.scss']
})
export class ProjetAuditListComponent extends BaseComponent implements OnInit {

    projetAuditList: ProjetAuditDto[];

    @Input() set projetAudit(value: ProjetAuditDto[]) {
        this.projetAuditList = [...value ?? []];
        this.initMultiSelectStatut();
        this.initMultiSelectStatutGlobal();
    }

    statutFiltersItems: MultiSelectFilters[] = [];
    statutGlobalFiltersItems: MultiSelectFilters[] = [];

    columns = projetAuditListDialogColumn;
    mobileColumns = projetAuditListMobileDialogColumn;

    public isAppOffline = true;
    public isUserMobile = false;
    public canZoomProjetAudit = false;
    public canApproveProjetAudit = false;
    public canRejectProjetAudit = false;
    public canCompleteProjetAudit = false;
    public canAssignProjetAudit = false;
    public canCancelProjetAudit = false;
    public canExtractProjetAuditHistory = false;

    constructor(
        private store: Store<State>,
        private messageService: MessageService
    ) {
        super();
    }

    ngOnInit() {
        this.subscribeToIsAppOffline();
        this.subscribeToUtilisateurIsMobile();
        this.subscribeToCanZoomProjetAudit();
        this.subscribeToCanAssignProjetAudit();
        this.subscribeToCanRejectProjetAudit();
        this.subscribeToCanApproveProjetAudit();
        this.subscribeToRejectProjetAudit();
        this.subscribeToAssignProjetAudit();
        this.subscribeToCanCancelProjetAudit();
        this.subscribeToCancelProjetAudit();
        this.subscribeToCanExtractProjetAuditHistory();
        this.subscribeToExportProjetAuditHistory();
        this.subscribeToCanCompleteProjetAudit();
        this.subscribeToApproveProjetAudit();
    }

    initMultiSelectStatut = () => this.statutFiltersItems = this.initFiltersFromEntries(StatutProjetAuditValue);
    initMultiSelectStatutGlobal = () => this.statutGlobalFiltersItems = this.initFiltersFromEntries(StatutPointAuditValue);

    private initFiltersFromEntries(values: any) {
        const keyValues = Object.entries(values).map(([key, value]) => {
            return {
                code: key,
                name: value
            } as MultiSelectFilters;
        });

        return keyValues.sort((a, b) => (a.name && b.name) ? (a.name >= b.name) ? 1 : -1 : 0);
    }

    private subscribeToUtilisateurIsMobile() {
        this.store.select(selectUtilisateurIsMobile).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_isUserMobile => {
            this.isUserMobile = _isUserMobile;
        });
    }

    private subscribeToCanApproveProjetAudit() {
        this.store.select(selectCanApproveProjetAudit).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_canApproveProjetAudit => {
            this.canApproveProjetAudit = _canApproveProjetAudit;
        });
    }

    private subscribeToCanZoomProjetAudit() {
        this.store.select(selectCanZoomProjetAudit).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_canZoomAudit => {
            this.canZoomProjetAudit = _canZoomAudit;
        });
    }

    private subscribeToCanRejectProjetAudit() {
        this.store.select(selectCanRejectProjetAudit).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_canRejectProjetAudit => {
            this.canRejectProjetAudit = _canRejectProjetAudit;
        });
    }

    private subscribeToIsAppOffline() {
        this.store.select(selectIsAppOnline).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_isAppOnline => {
            this.isAppOffline = !_isAppOnline;
        });
    }

    private subscribeToCanCompleteProjetAudit() {
        this.store.select(selectCanCompleteProjetAudit).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_canCompleteProjetAudit => {
            this.canCompleteProjetAudit = _canCompleteProjetAudit;
        });
    }

    private subscribeToCanAssignProjetAudit() {
        this.store.select(selectCanAssignProjetAudit).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_canAssignProjetAudit => {
            this.canAssignProjetAudit = _canAssignProjetAudit;
        });
    }

    private subscribeToCanCancelProjetAudit() {
        this.store.select(selectCanCancelProjetAudit).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_canCancelProjetAudit => {
            this.canCancelProjetAudit = _canCancelProjetAudit;
        });
    }

    private subscribeToCanExtractProjetAuditHistory() {
        this.store.select(selectCanExtractAuditHistory).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_canExtractProjetAuditHistory => {
            this.canExtractProjetAuditHistory = _canExtractProjetAuditHistory;
        });
    }

    private subscribeToSuccess(
        selector: MemoizedSelector<object, { projetAudit: ProjetAuditDto, success: boolean }>,
        summary: string,
        detail: string,
        action: Function = () => this.refreshData()) {

        this.store.select(selector).pipe(
            filter(success => !!success),
            takeUntil(this.destroyed)
        ).subscribe(result => {
            if (result.success) {
                this.messageService.add(
                    {
                        severity: 'info',
                        closable: true,
                        summary,
                        detail
                    });
                action();
            }
        });
    }

    private subscribeToError(
        selector: MemoizedSelector<object, any>,
        summary: string,
        detail: string,
        action: Function = null) {
        this.store.select(selector).pipe(
            filter(error => !!error),
            takeUntil(this.destroyed)
        ).subscribe(error => {
            const erreur = error?.error?.erreurs || detail;
            this.messageService.add(
                {
                    severity: 'error',
                    closable: true,
                    summary,
                    detail: erreur
                }
            );

            if (action) {
                action();
            }
        });
    }

    private subscribeToAssignProjetAudit() {
        this.subscribeToSuccess(getAssignProjetAuditSuccess, `Assignation d'un projet d'audit`, `L'assignation a été effectuée avec succès.`);
        this.subscribeToError(getAssignProjetAuditError, `Assignation d'un projet d'audit`, `Une erreur est survenue lors de l'assignation d'un projet d'audit.`);
    }

    private subscribeToRejectProjetAudit() {
        this.subscribeToSuccess(getRejectProjetAuditSuccess, `Rejeter un projet d'audit`, `Le rejet du projet d'audit a été effectué avec succès.`);
        this.subscribeToError(getRejectProjetAuditError, `Rejeter un projet d'audit`, `Une erreur est survenue lors du rejet du projet d'audit.`);
    }

    private subscribeToCancelProjetAudit() {
        this.subscribeToSuccess(getCancelProjetAuditSuccess, `Annulation d'un projet d'audit`, `L'annulation a été effectuée avec succès.`);
        this.subscribeToError(getCancelProjetAuditError, `Annulation d'un projet d'audit`, `Une erreur est survenue lors de l'annulation d'un projet d'audit.`);
    }

    private subscribeToApproveProjetAudit() {
        this.subscribeToSuccess(getApproveProjetAuditSuccess, `Approbation d'un projet d'audit`, `L'approbation a été effectuée avec succès.`);
        this.subscribeToError(getApproveProjetAuditError, `Approbation d'un projet d'audit`, `Une erreur est survenue lors de l'approbation du projet d'audit.`);
    }

    private subscribeToExportProjetAuditHistory() {
        this.store.select(getProjetAuditHistory)
            .pipe(
                filter(success => !!success),
                takeUntil(this.destroyed)
            )
            .subscribe(projetAuditHistory => {
                this.exportToExcelProjetAuditHistory(projetAuditHistory!);
            });

        this.subscribeToError(getProjetAuditHistoryError, `Erreur Extraction historique`, `Une erreur est survenue lors de l'extraction de l'historique.`);
    }

    private refreshData() {
        this.store.dispatch(AuditActions.clearProjetAuditData());
        this.store.dispatch(AuditActions.loadProjetAuditList());
        this.store.dispatch(InspectionActions.loadProjetInspectionList());
    }

    public exportToExcelProjetAuditHistory(projetAuditHistory: ProjetAuditHistory[]) {
        const worksheet = utils.json_to_sheet(projetAuditHistory);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const workbook: WorkBook = { Sheets: { 'historiqueAudit': worksheet }, SheetNames: ['historiqueAudit'] };
        const excelBuffer: any = write(workbook, { bookType: 'xlsx', type: 'array' });
        const FILE_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        saveFile(excelBuffer, projetAuditHistory[0].nom + '_' + dateTimeChange(Date.now().toString(), 'YYYYMMdd'), FILE_TYPE, '.xlsx');
    }
}
