export enum ModeCreationProjetValue {
    polygone = 'Polygone',
    ligne = 'Ligne',
    shapefile = 'Shapefile',
}

export enum ModeCreationProjet {
    polygone = 'polygone',
    ligne = 'ligne',
    shapefile = 'shapefile',
}

export const modeCreationList = Object.keys(ModeCreationProjet).map(
    key => ({ mode: key, label: ModeCreationProjetValue[key], inactive: false })
);
