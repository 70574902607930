export enum InclinaisonValue {
    n0 = '0',
    n1 = '1',
    n2 = '2',
    n3 = '3',
    n4 = '4',
    n5 = '5',
    n6 = '6',
    n7 = '7',
    n8 = '8',
    n9 = '9',
    n10 = '10',
    n11 = '11',
    n12 = '12',
    n13 = '13',
    n14 = '14',
    n15 = '15',
    n16 = '16',
    n17 = '17',
    n18 = '18',
    n19 = '19',
    n20 = '20',
}

export enum Inclinaison {
    n0 = 'n0',
    n1 = 'n1',
    n2 = 'n2',
    n3 = 'n3',
    n4 = 'n4',
    n5 = 'n5',
    n6 = 'n6',
    n7 = 'n7',
    n8 = 'n8',
    n9 = 'n9',
    n10 = 'n10',
    n11 = 'n11',
    n12 = 'n12',
    n13 = 'n13',
    n14 = 'n14',
    n15 = 'n15',
    n16 = 'n16',
    n17 = 'n17',
    n18 = 'n18',
    n19 = 'n19',
    n20 = 'n20',
}

export const inclinaisonListe = Object.keys(Inclinaison).map(key => ({ champ: key, valeur: InclinaisonValue[key as keyof typeof Inclinaison] }));

export const inclinaisonMap = new Map<Inclinaison, string>(
    [
        [Inclinaison.n0, InclinaisonValue.n0],
        [Inclinaison.n1, InclinaisonValue.n1],
        [Inclinaison.n2, InclinaisonValue.n2],
        [Inclinaison.n3, InclinaisonValue.n3],
        [Inclinaison.n4, InclinaisonValue.n4],
        [Inclinaison.n5, InclinaisonValue.n5],
        [Inclinaison.n6, InclinaisonValue.n6],
        [Inclinaison.n7, InclinaisonValue.n7],
        [Inclinaison.n8, InclinaisonValue.n8],
        [Inclinaison.n9, InclinaisonValue.n9],
        [Inclinaison.n10, InclinaisonValue.n10],
        [Inclinaison.n11, InclinaisonValue.n11],
        [Inclinaison.n12, InclinaisonValue.n12],
        [Inclinaison.n13, InclinaisonValue.n13],
        [Inclinaison.n14, InclinaisonValue.n14],
        [Inclinaison.n15, InclinaisonValue.n15],
        [Inclinaison.n16, InclinaisonValue.n16],
        [Inclinaison.n17, InclinaisonValue.n17],
        [Inclinaison.n18, InclinaisonValue.n18],
        [Inclinaison.n19, InclinaisonValue.n19],
        [Inclinaison.n20, InclinaisonValue.n20],
    ]
);

