export enum PointAuditProperties {
    ID = 'id',
    STATUT = 'statut',
    STATUT_GLOBAL = 'statutGlobal',
    ANOMALIES_AUDIT = 'anomaliesAudit',

    // TODO: UNUSED AFTER REFACTOR. DELETE AFTER TESTING
    // projetAuditId = 'projetAuditId',
    // auditeLe = 'Audité le',
    // auditePar = 'Audité par',
    // remarque = 'Remarque',
    // justification = 'Justification',
    //containsAnomalieNonConforme = 'containsAnomalieNonConforme',
    // type = 'Type',
    // geometrie = 'geometrie',
    // pointInspectionId = 'pointInspectionId',
    // anomaliesAudit = 'anomaliesAudit',
    // photos = 'photos',
}
