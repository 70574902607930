import { LayerGroupe } from '../../enums/layer-groupe';
import { StatutProjet, StatutProjetValue } from '../../features/projet/models';
import { ProjetProperties } from '../../features/projets/models/projet-properties.enum';
import { FeatureSource } from '../../map/models/feature-source.enum';
import { LayerType } from '../../map/models/layer-type.enum';
import { mapStyleConfig } from '../../map/models/map-style-config.const';
import { StyleLayer } from '../../map/models/style-layer.model';

export const PROJETS_INSPECTION_STYLE_LEGENDE: StyleLayer[] = [
    generateStyle(StatutProjet.enCreation),
    generateStyle(StatutProjet.nouveau),
    generateStyle(StatutProjet.inspectionAssigne),
    generateStyle(StatutProjet.inspectionEnCours),
    generateStyle(StatutProjet.inspectionCompletee),
    generateStyle(StatutProjet.aqAssigne),
    generateStyle(StatutProjet.aqEnCours),
    generateStyle(StatutProjet.aqCompletee),
    generateStyle(StatutProjet.attenteApprobationHQ),
    generateStyle(StatutProjet.approbationFinaleHQ),
    generateStyle(StatutProjet.conforme),
    generateStyle(StatutProjet.nonConforme),
    generateStyle(StatutProjet.correctionAssignee),
    generateStyle(StatutProjet.correctionEnCours),
    generateStyle(StatutProjet.correctionCompletee),
    generateStyle(StatutProjet.auditEnCours, '(Inspection)'),
    generateStyle(StatutProjet.audite),
    generateStyle(StatutProjet.annule, null, 'none'),
    generateStyle(StatutProjet.erreur, null, 'none')
];

function generateStyle(statut: StatutProjet, suffix: null | string = null, visibility: any = 'visible'): StyleLayer {
    return {
        'nomGroupe': LayerGroupe.PROJETS_INSPECTION,
        'value': {
            'id': suffix ? `${StatutProjetValue[statut]} ${suffix}` : StatutProjetValue[statut],
            'type': LayerType.FILL,
            'source': FeatureSource.PROJETS_INSPECTION,
            'minzoom': 5,
            'filter': ['==', ['get', ProjetProperties.STATUT], statut],
            'layout': { 'visibility': visibility },
            'paint': {
                /* eslint-disable @typescript-eslint/naming-convention */
                'fill-color': mapStyleConfig.colors[statut],
                'fill-outline-color': mapStyleConfig.colors[statut],
                /* eslint-enable @typescript-eslint/naming-convention */
            }
        },
    };
}
