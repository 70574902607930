import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { selectUtilisateurActiveRoles } from '../state/shared/shared.selectors';
import { switchMap, take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RoleChoisiInterceptor implements HttpInterceptor {
    constructor(private store: Store) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return this.store.select(selectUtilisateurActiveRoles).pipe(
            take(1),
            switchMap((role) => {
                if (role?.length > 0) {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    const modifiedReq = req.clone({ setHeaders: { 'role-choisi': role[0] } });
                    return next.handle(modifiedReq);
                }
                return next.handle(req);
            })
        );
    }
}
