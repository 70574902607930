import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { State } from '../../../../state/app.state';
import { takeUntil, filter, map, mergeMap } from 'rxjs';
import { BaseComponent } from '../../../../shared/components/abstract-base-component';
import { AnomalieAuditDto, PointAuditDto, PointInspectionDto, ProjetAuditDto, ProjetCompletDto } from '../../../../core/api/client/models';
import { getPointAuditById, getProjetAuditById } from '../../../../features/audit/state/audit.selectors';
import { flatMap } from 'lodash';
import { statutAuditName, StatutPointAudit } from '../../../../features/audit/models/statut-point-audit.enum';
import { dateTimeChange, generatePhotosAnomalieAudit, generatePhotosFromParameters } from '../../../../shared/utils';
import { PhotoService } from '../../../../services/photo.service';
import { ObjectType } from '../../../../shared/enums/photo-object-type.enum';
import * as InspectionActions from '../../../../features/inspection/state/inspection.actions';
import { getPointInspectionById, getProjetInspectionById } from '../../../../features/inspection/state/inspection.selectors';
import { UserRole } from '../../../../shared/models/user-roles.model';
import { PopUpInfoCloseEvent } from '../../../models/pop-up-info-close-event.model';
import { PointLabels } from '../../../../features/projets/models/point-labels.enum';
import { selectIsAppOnline, selectUtilisateurCanEditPointAuditByProject } from '../../../../state/shared/shared.selectors';

@Component({
    selector: 'app-info-point-audit',
    templateUrl: './info-point-audit.component.html',
    styleUrls: ['./info-point-audit.component.scss']
})
export class InfoPointAuditComponent extends BaseComponent implements OnDestroy, OnInit, OnChanges {
    public subTitle: string = `Point d'audit`;
    public nbPhotos: number = 0;
    public data: { [name: string]: any } = {};
    public pointAuditHtmlPhotos: HTMLImageElement[] = [];
    public userGroups: string[] = [];

    public projetInspection: ProjetCompletDto | undefined = undefined;
    public pointAudit: PointAuditDto | undefined = undefined;
    public pointInspection: PointInspectionDto | undefined = undefined;
    public projetAudit: ProjetAuditDto | undefined = undefined;
    public anomaliesNonConformes: AnomalieAuditDto[] | undefined = undefined;

    public verticalProperties: string[] = [PointLabels.NOTE_AUDITEUR];
    public showEditButton = false;
    public isOffline = false;
    public offlineMessage = 'Connectez-vous à internet pour modifier';

    private auditFeatureReceived: mapboxgl.MapboxGeoJSONFeature;
    private pointInspectionFeatureReceived: mapboxgl.MapboxGeoJSONFeature;

    @Input() currentUserGroups: string[] = [];
    @Input() pointAuditId: string;
    @Input() feature: mapboxgl.MapboxGeoJSONFeature;
    @Input() auditFeature: mapboxgl.MapboxGeoJSONFeature;
    @Output() closed: EventEmitter<PopUpInfoCloseEvent> = new EventEmitter<PopUpInfoCloseEvent>();

    constructor(
        private store: Store<State>,
        private photoService: PhotoService
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscribeToIsAppOffline();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.currentUserGroups?.currentValue) {
            this.userGroups = changes.currentUserGroups.currentValue;
        }

        if (changes?.feature?.currentValue) {
            this.pointInspectionFeatureReceived = changes.feature.currentValue;
        }

        if (changes?.auditFeature?.currentValue) {
            this.auditFeatureReceived = changes.auditFeature.currentValue;
        }

        if (changes?.pointAuditId?.currentValue) {
            this.subscribeToPointAudit(changes.pointAuditId.currentValue);
        }
    }

    private subscribeToPointAudit(pointAuditId: string) {
        this.store
            .pipe(
                select(getPointAuditById(pointAuditId)),
                filter(pointsAudit => !!pointsAudit),
                mergeMap(pointAudit =>
                    this.store.select(getPointInspectionById(pointAudit.pointInspectionId))
                        .pipe(map(pointInspection => ({ pointInspection, pointAudit })))
                ),
                mergeMap(data =>
                    this.store.select(getProjetInspectionById(data.pointInspection?.projetId))
                        .pipe(map(projetInspection => ({ ...data, projetInspection })))
                ),
                mergeMap(data =>
                    this.store.select(getProjetAuditById(data.pointAudit?.projetAuditId))
                        .pipe(map(projetAudit => ({ ...data, projetAudit })))
                ),
                takeUntil(this.destroyed)
            )
            .subscribe(({ pointAudit, pointInspection, projetInspection, projetAudit }) => {
                if (this.userGroups.includes(UserRole.ADMIN_GCSP) && pointAudit?.pointInspectionId === pointInspection?.id) {
                    this.pointInspection = {
                        ...pointInspection,
                        pointsAudit: [pointAudit]
                    };
                    this.store.dispatch(InspectionActions.setSelectedPointInspection({ pointInspection: this.pointInspection }));
                } else {
                    this.pointInspection = pointInspection;
                }

                this.pointAudit = pointAudit;
                this.projetInspection = projetInspection;
                this.projetAudit = projetAudit;
                this.anomaliesNonConformes = pointAudit.anomaliesAudit.filter(({ statut }) => statut === StatutPointAudit.nonConforme);
                // TODO: Check if this is the right place to subscribe to this
                this.subscribeUtilisateurCanEditPointAuditByProject(this.projetAudit);
                this.initHtmlPhotos();
                this.initData();
            });
    }

    private subscribeUtilisateurCanEditPointAuditByProject(projetAudit: ProjetAuditDto) {
        this.store.select(selectUtilisateurCanEditPointAuditByProject(projetAudit)).pipe(
            takeUntil(this.destroyed)
        ).subscribe(canEditPointAuditByProject => {
            this.showEditButton = canEditPointAuditByProject;
        });
    }

    private initHtmlPhotos() {
        const convertedPointAuditPhotos = generatePhotosFromParameters(this.pointAudit.photos, {
            getParentId: () => this.pointAudit.id,
            getObjectType: () => ObjectType.POINTS_AUDIT,
            getPhotoId: photo => photo.id,
            alt: 'PointsAudit'
        });

        const convertedNonConformitesPhotos = generatePhotosAnomalieAudit(this.anomaliesNonConformes);
        const pointAuditPhotoContainers = this.photoService.convertToPhotoContainer(convertedPointAuditPhotos);
        const nonConformitesPhotoContainers = this.photoService.convertToPhotoContainer(convertedNonConformitesPhotos);

        this.photoService.setCarousselPhotos([...pointAuditPhotoContainers, ...nonConformitesPhotoContainers]);

        this.pointAuditHtmlPhotos = this.photoService.convertToPhotoHtml(pointAuditPhotoContainers);
    }

    private initData() {
        const photosNonConformites = flatMap(this.anomaliesNonConformes, x => x.photos);
        this.nbPhotos = this.pointAudit.photos.length + photosNonConformites.length;

        this.data = {
            [PointLabels.STATUT]: this.getStatut(),
            [PointLabels.AUDITE_LE]: dateTimeChange(this.pointAudit.auditeLe?.toString(), 'dd/MM/YYYY'),
            ...(!this.userGroups.includes(UserRole.ADMIN_EXTERNE) ? { [PointLabels.AUDITE_PAR]: this.pointAudit.auditePar } : {}),
            [PointLabels.TYPE_NON_CONFORMITE]: this.getTypeNonConformite(),
            [PointLabels.NOTE_AUDITEUR]: this.pointAudit.remarque,
            ...(this.pointAudit?.auditModifieLe > 0 ? { [PointLabels.MODIFIE_LE]: dateTimeChange(this.pointAudit.auditModifieLe.toString(), 'dd/MM/YYYY') } : {}),
            ...(this.pointAudit?.auditModifiePar ? { [PointLabels.MODIFIE_PAR]: this.pointAudit.auditModifiePar } : {}),
        };
    }

    private getStatut() {
        const isAudite = [StatutPointAudit.conforme, StatutPointAudit.nonConforme]
            .map(statut => statut.toString())
            .includes(this.pointAudit.statutGlobal.toString());

        return `${isAudite ? 'Audité,' : ''} ${statutAuditName(this.pointAudit.statutGlobal)}`.trim();
    }

    private getTypeNonConformite() {
        const typesNonConformites: string[] = [];

        if (this.pointAudit?.statut === StatutPointAudit.nonConforme) {
            typesNonConformites.push('Détails');
        }

        if (this.anomaliesNonConformes?.length) {
            typesNonConformites.push('Anomalies');
        }

        return typesNonConformites.join(' + ');
    }

    public close() {
        this.closed.emit({ closed: true });
        this.ngOnDestroy();
    }

    public edit() {
        this.closed.emit({
            closed: true,
            pointAuditId: this.pointAudit.id,
            pointAuditFeature: this.auditFeatureReceived,
            pointInspectionFeature: this.pointInspectionFeatureReceived
        });
        this.ngOnDestroy();
    }

    private subscribeToIsAppOffline() {
        this.store.select(selectIsAppOnline).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_isAppOnline => {
            this.isOffline = !_isAppOnline;
        });
    }
}
