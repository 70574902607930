import { Component, Input } from '@angular/core';
import { AnomalieBaseDto } from '../../../../../core/api/client/models';
import { AnomalieLabels } from '../../../../../features/anomalie/models/anomalie-labels.enum';
import { Priorite, prioriteMap } from '../../../../../shared/enums/priorite.enum';
import { PhotoService } from '../../../../../services/photo.service';
import { StatutAnomalie } from '../../../../../features/anomalie/models/statut-anomalie.enum';
import { booleanToOuiNonNull, dateTimeChange, generatePhotos } from '../../../../../shared/utils';
import { ObjectType } from '../../../../../shared/enums/photo-object-type.enum';

@Component({
    selector: 'app-pop-up-info-anomalie',
    templateUrl: './pop-up-info-anomalie.component.html',
    styleUrls: ['./pop-up-info-anomalie.component.scss']
})
export class PopUpInfoAnomalieComponent {
    public imagesAnomalies: { [name: string]: HTMLImageElement[] } = {};
    public verticalProperties: string[] = [];
    public statutAnomalieLabel = StatutAnomalie;
    public data: { [name: string]: any } = {};
    public numberOfPhotos: { [name: string]: any } = {};

    private _anomalies: AnomalieBaseDto[] = [];
    @Input() set anomalies(value: AnomalieBaseDto[]) {
        this._anomalies = value || [];
        this.initData();
        this.initPhotos();
    }

    get anomalies() {
        return this._anomalies;
    }

    constructor(
        private photoService: PhotoService
    ) { }

    private initData() {
        const anomalieData: { [name: string]: any } = {};
        this.anomalies.forEach((anomalie: AnomalieBaseDto, index: number) => {
            const currentData = {
                ...(anomalie.type ? { [AnomalieLabels.TYPE]: anomalie.type } : {}),
                ...(anomalie.cause ? { [AnomalieLabels.CAUSE]: anomalie.cause } : {}),
                ...(anomalie.indCreationAvis ? { [AnomalieLabels.INDICATEUR_CREATION_AVIS]: booleanToOuiNonNull(anomalie.indCreationAvis) } : {}),
                ...(anomalie.priorite ? { [AnomalieLabels.PRIORITE]: prioriteMap.get(anomalie.priorite as Priorite) } : {}),
                ...(anomalie.dateTransfertSap ? { [AnomalieLabels.DATE_TRANSFERT_SAP]: dateTimeChange(anomalie.dateTransfertSap.toString(), 'dd/MM/YYYY') } : {}),
                ...(anomalie.remarque ? { [AnomalieLabels.REMARQUE]: anomalie.remarque } : {}),
                ...(anomalie.urgence ? { [AnomalieLabels.URGENT]: booleanToOuiNonNull(anomalie.urgence) } : {}),
                ...(anomalie.modifieLe ? { [AnomalieLabels.MODIFIE_LE]: dateTimeChange(anomalie.modifieLe.toString(), 'dd/MM/YYYY') } : {}),
                ...(anomalie.modifiePar ? { [AnomalieLabels.MODIFIE_PAR]: anomalie.modifiePar } : {})
            };
            anomalieData[index] = currentData;
        });

        this.data = anomalieData;

        this.verticalProperties = [
            AnomalieLabels.CAUSE,
        ];
    }

    private initPhotos() {
        this.anomalies.forEach((anomalie, index) => {
            const photos = generatePhotos(anomalie.photos ? anomalie.photos : [], anomalie.id!, ObjectType.ANOMALIES, 'Anomalie');
            const anomaliesPhotos = this.photoService.convertToPhotoContainer(photos);
            const htmlPhotos = this.photoService.convertToPhotoHtml(anomaliesPhotos);
            this.numberOfPhotos[index] = anomalie.photos.length;
            this.imagesAnomalies[anomalie.id] = htmlPhotos;
        });
    }
}
