import { Component, Output, EventEmitter, Input, ViewChild, OnDestroy } from '@angular/core';
import { DoInit, ComponentBase, ComponentFeatures, InheritsBaseLifecycleHooks, SaveCommand } from '@ngxhq/common-ui';
import { OpenIdService, OpenIdUserInfo, AuthentificationState } from '@ngxhq/security';
import { combineLatest, from, Observable, Subscription } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { HqHeaderComponent } from '@ngxhq/theme-ngxhq';
import { MenuItem } from 'primeng/api';
import { select, Store } from '@ngrx/store';
import { State } from '../../state/app.state';
import { UiService } from 'src/app/services/ui.service';
import { UserInformation } from '../../shared/models/user-informations.model'; // TODO: Changer UserInformation pour IdentiteUtilisateur
import { logoutUser } from 'src/app/core/store/actions/user.actions';
import { UserViewModel } from '../models/user-view-model';
import { AppConfiguration } from '../../shared/config/app-config.model';
import { environment } from '@app/environment';
import { getTaxonomieById, isTaxonomiesFetched } from '../../core/store/selectors/taxonomie.selectors';
import { Taxonomie } from '../../features/pilotage/models/taxonomie.model';
import { getAllTaxonomies } from '../../core/store/actions/taxonomie.action';
import { selectIdentiteUtilisateur as fetchIdentiteUtilisateur } from '../../state/shared/shared.actions';
import {
    selectCanOpenCreatePoteauSansAnomalie,
    selectCanOpenCreateProjet,
    selectCanOpenProjetAuditList,
    selectCanOpenProjetInspectionList,
    selectCanOpenProjetsList,
    selectIdentiteUtilisateur,
    selectUtilisateurActiveRoles,
    selectUtilisateurIsMobile,
    selectUtilisateurIsPilotage
} from '../../state/shared/shared.selectors';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
@ComponentFeatures([
    InheritsBaseLifecycleHooks()
])
export class HeaderComponent extends ComponentBase implements DoInit, OnDestroy {
    public menuWeb: MenuItem[];
    public menuMobile: MenuItem[];
    public identiteUtilisateur: UserInformation;
    public utilisateurActiveRole: string;
    public isMobileUser: boolean;
    public user: UserViewModel | undefined;
    public imagePath: string = './assets/images/';
    public isPoteauDetailsDialogOpened = false;
    public environmentName: string;
    public firmeName: string = '';

    private canOpenPilotage = false;
    private canOpenProjetsList = false;
    private canOpenProjetInspectionList = false;
    private canOpenProjetAuditList = false;
    private canOpenCreateProjet = false;
    private canOpenCreatePoteauSansAnomalie = false;
    private subscriptions: Subscription[] = [];
    private selectIdentiteUtilisateur$: Observable<UserInformation> = this.store.select(selectIdentiteUtilisateur);
    private selectUtilisateurActiveRoles$: Observable<string[]> = this.store.select(selectUtilisateurActiveRoles);
    private selectUtilisateurIsMobile$: Observable<boolean> = this.store.select(selectUtilisateurIsMobile);

    @Output() expandedChange = new EventEmitter<boolean>();
    @Input()
    set expanded(value: boolean) {
        if (this.header && this.header.expanded !== value) {
            this.header.expanded = value;
        }
        this.expandedChange.emit(value);
    }

    @ViewChild(HqHeaderComponent)
    private header: HqHeaderComponent;

    constructor(
        private openIdService: OpenIdService,
        private uiService: UiService,
        private store: Store<State>,
        private configs: AppConfiguration,
    ) {
        super();
        if (environment && environment.imagePath) {
            this.imagePath = environment.imagePath.assets;
        }
    }

    private setUpEnvironnement() {
        this.environmentName = this.configs ? this.configs.environment : '';
    }

    public doInit() {
        this.setUpEnvironnement();
        this.openIdService.status.pipe(
            switchMap(x => from(this.mapStatusToViewModel(x))),
            takeUntil(this.destroyed),
        ).subscribe({
            next: (user: UserViewModel) => {
                this.user = user;
                if (user) {
                    this.store.dispatch(fetchIdentiteUtilisateur());
                    this.uiService.getBackendVersion();
                }
            },
            error: (err: any) => {
                // eslint-disable-next-line no-restricted-syntax
                console.log('HeaderComponent - openIdService status subsription in error.', err);
                if ((typeof err !== 'object') || err.message.indexOf('Missing OpenID configuration') === -1) {
                    throw err;
                }
            }
        });

        this.subscriptions.push(
            this.selectIdentiteUtilisateur$
                .pipe(
                    tap((identiteUtilisateur) => {
                        if (identiteUtilisateur) {
                            this.identiteUtilisateur = identiteUtilisateur;
                            this.subscribeToFirme(identiteUtilisateur.firme);
                        }
                    })
                ).subscribe(),

            this.uiService.isPoteauDetailsDialogOpened$
                .pipe(
                    tap((isOpen: boolean) => {
                        this.isPoteauDetailsDialogOpened = isOpen;
                    })
                ).subscribe(),

            this.selectUtilisateurActiveRoles$
                .pipe(
                    tap((roles: string[] | undefined) => {
                        if (roles !== undefined) {
                            if (roles && roles.length === 1) {
                                this.utilisateurActiveRole = roles[0];
                            }
                        }
                    })
                ).subscribe(),

            this.selectUtilisateurIsMobile$
                .pipe(
                    tap((isMobile: boolean) => {
                        this.isMobileUser = isMobile;
                    })
                ).subscribe()
        );

        this.subscribeToMenuItems();
    }

    private getFirmeById(firmeId: string) {
        this.store.pipe(
            select(getTaxonomieById(firmeId)),
            tap((firme: Taxonomie) => this.firmeName = firme.code),
            takeUntil(this.destroyed),
        ).subscribe();
    }

    private subscribeToFirme(firmeId: string) {
        this.store.pipe(
            select(isTaxonomiesFetched()),
            tap((isFetched: boolean) => {
                if (!isFetched) {
                    this.store.dispatch(getAllTaxonomies());
                } else {
                    this.getFirmeById(firmeId);
                }
            }),
            takeUntil(this.destroyed),
        ).subscribe();
    }

    private subscribeToMenuItems() {
        combineLatest([
            this.store.select(selectUtilisateurIsPilotage),
            this.store.select(selectCanOpenCreateProjet),
            this.store.select(selectCanOpenProjetsList),
            this.store.select(selectCanOpenProjetInspectionList),
            this.store.select(selectCanOpenProjetAuditList),
            this.store.select(selectCanOpenCreatePoteauSansAnomalie)
        ]).pipe(
            takeUntil(this.destroyed)
        ).subscribe(([
            openPilotage,
            openCreateProjet,
            openProjetsList,
            openProjetInspectionList,
            openProjetAuditList,
            openCreatePoteauSansAnomalie
        ]) => {
            this.canOpenPilotage = openPilotage;
            this.canOpenCreateProjet = openCreateProjet;
            this.canOpenProjetsList = openProjetsList;
            this.canOpenProjetInspectionList = openProjetInspectionList;
            this.canOpenProjetAuditList = openProjetAuditList;
            this.canOpenCreatePoteauSansAnomalie = openCreatePoteauSansAnomalie;
            this.initMenu();
        });
    }

    private initMenu() {
        this.menuWeb = [
            {
                label: 'Création de projet',
                icon: 'fas fa-plus-square',
                visible: this.canOpenCreateProjet,
                command: () => {
                    this.uiService.closeActionSheet(true);
                    this.uiService.openCreateProjetModal(true);
                }
            },
            {
                label: `Tableau des projets`,
                icon: 'fas fa-table',
                visible: this.canOpenProjetsList,
                command: () => {
                    this.uiService.closeActionSheet(true);
                    this.uiService.openProjetsListModal(true);
                }
            },
            {
                label: `Tableau des projets d'inspection`,
                icon: 'fas fa-table',
                visible: this.canOpenProjetInspectionList,
                command: () => {
                    this.uiService.closeActionSheet(true);
                    this.uiService.openProjetInspectionListModal(true);
                }
            },
            {
                label: `Tableau des projets d'audit`,
                icon: 'fas fa-clipboard-list',
                visible: this.canOpenProjetAuditList,
                command: () => {
                    this.uiService.closeActionSheet(true);
                    this.uiService.openProjetAuditListModal(true);
                }
            },
            {
                label: 'Actualiser la page',
                icon: 'fas fa-sync',
                url: './'
            },
            {
                label: 'Recherche',
                icon: 'fas fa-search',
                command: () => {
                    this.uiService.closeActionSheet(true);
                    this.uiService.openRechercheModal(true);
                }
            },
            {
                label: 'Pilotage',
                icon: 'fas fa-cogs',
                visible: this.canOpenPilotage,
                command: () => {
                    this.uiService.closeActionSheet(true);
                    this.uiService.openPilotageModal(true);
                }
            },
            {
                label: 'À propos',
                icon: 'fas fa-question',
                command: () => {
                    this.uiService.closeActionSheet(true);
                    this.uiService.openVersionModal(true);
                }
            },
        ];

        this.menuMobile = [
            {
                label: `Tableau des projets d'inspection`,
                icon: 'fas fa-table',
                visible: this.canOpenProjetInspectionList,
                command: () => {
                    this.uiService.closeActionSheet(true);
                    this.uiService.openProjetInspectionListModal(true);
                }
            },
            {
                label: `Tableau des projets d'audit`,
                icon: 'fas fa-clipboard-list',
                visible: this.canOpenProjetAuditList,
                command: () => {
                    this.uiService.closeActionSheet(true);
                    this.uiService.openProjetAuditListModal(true);
                }
            },
            {
                label: 'Ajouter poteau',
                icon: 'fas fa-plus-square',
                command: () => {
                    this.uiService.closeActionSheet(true);
                    this.uiService.openCreatePoteauModal(true);
                }
            },
            {
                label: 'Ajouter poteau sans anomalie',
                icon: 'fas fa-plus-square',
                visible: this.canOpenCreatePoteauSansAnomalie,
                command: () => {
                    this.uiService.closeActionSheet(true);
                    this.uiService.openCreatePoteauSansAnomalieModal(true);
                }
            },
            {
                label: 'Recherche',
                icon: 'fas fa-search',
                command: () => {
                    this.uiService.closeActionSheet(true);
                    this.uiService.openRechercheModal(true);
                }
            },
            {
                label: 'À propos',
                icon: 'fas fa-question',
                command: () => {
                    this.uiService.closeActionSheet(true);
                    this.uiService.openVersionModal(true);
                }
            },
        ];
    }

    private async mapStatusToViewModel(x: AuthentificationState): Promise<UserViewModel | undefined> {
        return x.isAuthenticated
            ? this.mapToViewModel(await this.openIdService.getUserInfo())
            : undefined;
    }

    private mapToViewModel(userInfo: OpenIdUserInfo | undefined): UserViewModel | undefined {
        if (!userInfo) {
            return undefined;
        }
        return {
            fullname: `${userInfo.firstName} ${userInfo.lastName}`.trim(),
            initials: `${userInfo.firstName[0] || ''}${userInfo.lastName[0] || ''}`,
        };
    }

    @SaveCommand()
    async login(): Promise<void> {
        await this.openIdService.login('/');
    }

    @SaveCommand()
    async logout(): Promise<void> {
        this.store.dispatch(logoutUser());
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
