/* eslint-disable @typescript-eslint/naming-convention */
import { LayerGroupe } from '../../enums/layer-groupe';
import { PointInspectionProperties } from '../../features/inspection/models/point-inspection-properties.enum';
import { StatutPointInspection, StatutPointInspectionValue } from '../../features/inspection/models/statut-point-inspection.enum';
import { FeatureSource } from '../../map/models/feature-source.enum';
import { LayerLayout } from '../../map/models/layer-layout.model';
import { LayerType } from '../../map/models/layer-type.enum';
import { mapStyleConfig } from '../../map/models/map-style-config.const';
import { Proprietaire } from '../../map/models/proprietaire.enum';
import { StyleLayer } from '../../map/models/style-layer.model';

export const POINTS_INSPECTION_STYLE_LEGENDE: StyleLayer[] = [
    generateStyle(StatutPointInspection.nonInspecte),
    generateStyle(StatutPointInspection.urgence),
    generateStyle(StatutPointInspection.anomaliePrioriteC),
    generateStyle(StatutPointInspection.anomaliePrioriteE),
    generateStyle(StatutPointInspection.anomaliePrioriteG),
    generateStyle(StatutPointInspection.anomaliePrioriteK),
    generateStyle(StatutPointInspection.anomaliePrioriteM),
    generateStyle(StatutPointInspection.anomaliePrioriteN),
    generateStyle(StatutPointInspection.sansAnomalie),
    generateStyle(StatutPointInspection.ignore),
    generateStyle(StatutPointInspection.exclu),
];

function generateStyle(statut: StatutPointInspection): StyleLayer {
    return {
        ...generateBaseInspectionStyle([statut]),
        'value': {
            ...generateInspectionValueStyle(statut),
            'filter': ['all',
                ['==', PointInspectionProperties.STATUT, statut],
                ['!=', PointInspectionProperties.PROPRIETAIRE, Proprietaire.prive],
            ],
            'layout': {
                'icon-image': StatutPointInspectionValue[statut],
                ...getLayout()
            },
        }
    };
}

function getLayout(): LayerLayout {
    return {
        'icon-allow-overlap': true,
        'text-font': [
            'Arial Bold'
        ],
        'text-size': 14,
        'text-anchor': 'bottom-left',
        'text-justify': 'center',
        'text-offset': [0.4, 0],
        'text-field': [
            'format',
            ['get', PointInspectionProperties.CODE_A_BARRES],
            { 'font-scale': 1 },
            '\n',
            ['get', PointInspectionProperties.LCLCL_POTEAU],
            { 'font-scale': 0.8 }
        ],
        'text-optional': true,
        'visibility': 'visible'
    };
};

function generateBaseInspectionStyle(images: string[]): Partial<StyleLayer> {
    const baseAssetPath = 'assets/images/general/points-inspections/';
    return {
        'nomGroupe': LayerGroupe.POINTS_INSPECTION,
        'urlimage': images.map(image => `${baseAssetPath}${image}.png`),
    };
}

function generateInspectionValueStyle(statut: StatutPointInspection) {
    return {
        'id': StatutPointInspectionValue[statut],
        'type': LayerType.SYMBOL,
        'source': FeatureSource.POINTS_INSPECTION,
        'minzoom': mapStyleConfig.poteau.minZoom,
        'paint': {
            'icon-color': '#FFFFFF'
        }
    };
}
