export enum AnomalieLabels {
    ID = 'Id',
    ELEMENT = 'Élément',
    TYPE = 'Type',
    CAUSE = 'Cause',
    INDICATEUR_CREATION_AVIS = `Indicateur de création d'avis`,
    PRIORITE = 'Priorité',
    DATE_TRANSFERT_SAP = 'Date de transfert à SAP',
    REMARQUE = 'Remarque',
    URGENT = 'Urgent',
    PHOTOS = 'photos',
    STATUT = 'statut',
    MODIFIE_LE = 'Modifié le',
    MODIFIE_PAR = 'Modifié par',
    MALT = `Malt`,
    TUTEUR = `Tuteur`,
    LUMINAIRE = `Luminaire`,
    JAMBE_DE_FORCE = `Jambe de force`,
    LIAISON_AEROSOUTERRAINE = `Liaison aérosouterraine`,
    PANNEAU = `Panneau municipal`,
}
