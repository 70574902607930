<section class="error__wrapper">
    <ng-container>
        <h1>Application Capture - rôle</h1>
        <h2>Les rôles attribué pour cet utilisateur ne contiennent pas les SIGA requises pour l'application Capture
        </h2>
    </ng-container>
    <p>
        Veuillez valider vos demandes SIGA afin d'avoir le(s) rôle(s) nécessaire(s)
    </p>
</section>
