import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { StatutPointInspectionValue } from '../../../features/inspection/models/statut-point-inspection.enum';
import { FeatureSource } from '../../models/feature-source.enum';
import { uniqBy } from 'lodash';
import { PointAuditProperties } from '../../../features/audit/models/point-audit-properties.enum';
import { PopUpInfoCloseEvent } from '../../models/pop-up-info-close-event.model';

@Component({
    selector: 'app-pop-up-info',
    templateUrl: './pop-up-info.component.html',
    styleUrls: ['./pop-up-info.component.scss']
})
export class PopUpInfoComponent implements OnChanges {
    public featureSource = FeatureSource;
    public displayNavigation: boolean = false;
    public disableBackButton: boolean = false;
    public disableForwardButton: boolean = false;
    public userGroups: string[] = [];
    public featurePointInspection: mapboxgl.MapboxGeoJSONFeature;

    public currentPage: number = 0;
    public totalPage: number = 0;

    public filteredFeatures: mapboxgl.MapboxGeoJSONFeature[] = [];
    public selectedFeature: mapboxgl.MapboxGeoJSONFeature;
    public source: any;

    @Input() features: mapboxgl.MapboxGeoJSONFeature[] = [];
    @Input() currentUserGroups: string[] = [];
    @Output() closed: EventEmitter<PopUpInfoCloseEvent> = new EventEmitter<PopUpInfoCloseEvent>();

    public ngOnChanges(changes: SimpleChanges) {
        if (changes?.features?.currentValue) {
            this.setUpValue(changes.features.currentValue);
        }

        if (changes?.currentUserGroups?.currentValue) {
            this.userGroups = changes.currentUserGroups.currentValue;
        }
    }

    public nextPage() {
        if (this.currentPage < this.totalPage) {
            this.currentPage++;
            this.initFeatures();
        }

        this.evaluateNavigationDisplay();
    }
    public previousPage() {
        if (this.currentPage > 1) {
            this.currentPage--;
            this.initFeatures();
        }

        this.evaluateNavigationDisplay();
    }

    public close(event: PopUpInfoCloseEvent) {
        this.closed.emit(event);
    }

    private containtPointInspection(features: mapboxgl.MapboxGeoJSONFeature[]) {
        for (let i = 0; i < features.length; i++) {
            if ((features[i].source === FeatureSource.POINTS_INSPECTION && features[i].layer.id !== StatutPointInspectionValue.exclu)
                || features[i].source === FeatureSource.POINTS_AUDIT) {
                return true;
            }
        };
        return false;
    }

    private setUpSelection(features: mapboxgl.MapboxGeoJSONFeature[]) {
        const popUpProjectsSource: string[] = [FeatureSource.PROJETS_INSPECTION, FeatureSource.PROJETS_AUDIT];
        let filteredFeatures: mapboxgl.MapboxGeoJSONFeature[] = [];

        if (this.containtPointInspection(features)) {
            filteredFeatures = features.filter((feature: mapboxgl.MapboxGeoJSONFeature) =>
                feature.source === FeatureSource.POINTS_INSPECTION || feature.source === FeatureSource.POINTS_AUDIT
            );
        } else {
            filteredFeatures = features.filter((feature: mapboxgl.MapboxGeoJSONFeature) => popUpProjectsSource.includes(feature.source));
        }

        if (filteredFeatures.length === 0) {
            this.close({ closed: true });
        } else {
            this.filteredFeatures = this.filterDouble(filteredFeatures);
            this.setupFeatures();
        }
    }

    private setupFeatures() {
        const filteredFeaturePointInspection = this.filteredFeatures.filter(feature => feature.source === FeatureSource.POINTS_INSPECTION);
        if (filteredFeaturePointInspection.length > 0) {
            this.featurePointInspection = filteredFeaturePointInspection[0];
        }
    }

    private filterDouble(features: mapboxgl.MapboxGeoJSONFeature[] = []) {
        //pour les points d'audit, il y a plusieurs couches qui affiche les même points d'audit
        //donc pour éviter les doublons on fait un uniqBy sur le Id du point d'audit.
        const auditFeatures = features.filter(feature => feature.source === FeatureSource.POINTS_AUDIT);
        const remainingFeatures = features.filter(feature => !auditFeatures.includes(feature));

        const finalData = [
            ...remainingFeatures,
            ...uniqBy(auditFeatures, feature => feature.properties[PointAuditProperties.ID])
        ];

        return finalData;
    }

    private setUpValue(features: mapboxgl.MapboxGeoJSONFeature[]) {
        this.setUpSelection(features);
        this.setTotalSelection();
        this.setFirstSelection();
    }

    private initFeatures() {
        this.selectedFeature = this.filteredFeatures[this.currentPage - 1];
        this.source = this.selectedFeature.source;
    }

    private setTotalSelection() {
        this.totalPage = this.filteredFeatures.length;
    }

    private setFirstSelection() {
        this.currentPage = 1;
        this.initFeatures();
        this.evaluateNavigationDisplay();
    }

    private evaluateNavigationDisplay() {
        this.displayNavigation = this.totalPage > 1;
        this.disableBackButton = this.currentPage === 1;
        this.disableForwardButton = this.currentPage === this.totalPage;
    }
}
