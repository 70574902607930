<div *ngIf="pointsInspectionLoading$ | async" class="spinner-placeholder">
    <app-loading-spinner></app-loading-spinner>
</div>
<ng-container *ngIf="pointsInspection$ | async as pointsInspection">
    <p-table *ngIf="pointsInspection?.length > 0" [columns]="columns" [value]="pointsInspection"
        responsiveLayout="scroll">
        <ng-template pTemplate="header">
            <tr>
                <th *ngFor="let col of columns " class="p-flex-column">
                    {{col.header}}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
            <tr>
                <td>{{rowData.poteau.codeABarres}}</td>
                <td>Photo manquante</td>
                <td class="sticky-column">
                    <div class="sticky-column__container">
                        <button pButton pRipple class="p-button-rounded p-button-secondary" icon="pi pi-search-plus"
                            (click)="onZoomPointInspection(rowData)"></button>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div *ngIf="pointsInspection.length === 0">
        Tous les points d'inspection sont valides.
    </div>
</ng-container>