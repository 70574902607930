export enum StatutPoteauValue {
    nonModifie = 'Non modifié',
    ajoute = 'Ajouté',
    deplace = 'Déplacé',
    supprime = 'Supprimé',
    modifie = 'Modifié',
}

export enum StatutPoteau {
    nonModifie = 'nonModifie',
    ajoute = 'ajoute',
    deplace = 'deplace',
    supprime = 'supprime',
    modifie = 'modifie'
}

export const statutPoteauList = Object.keys(StatutPoteau).map(key => ({ champ: key, valeur: StatutPoteauValue[key as keyof typeof StatutPoteau] }));

export const statutPoteauMap = new Map<StatutPoteau, string>(
    [
        [StatutPoteau.nonModifie, StatutPoteauValue.nonModifie],
        [StatutPoteau.ajoute, StatutPoteauValue.ajoute],
        [StatutPoteau.deplace, StatutPoteauValue.deplace],
        [StatutPoteau.supprime, StatutPoteauValue.supprime],
        [StatutPoteau.modifie, StatutPoteauValue.modifie],
    ]
);
