export enum UserRole {
    ADMIN_EXTERNE = 'AdminExterne',
    ADMIN_GCSP = 'AdminGCSP',
    ADMIN_TIC = 'AdminTIC',
    AUDITEUR_EXTERNE = 'AuditeurExterne',
    AUDITEUR_HQD = 'AuditeurHQD',
    CONTROLEUR_QUALITE_EXTERNE = 'ControleurQualiteExterne',
    GESTION = 'Gestion',
    INSPECTEUR_EXTERNE = 'InspecteurExterne',
    INSPECTEUR_HQD = 'InspecteurHQD',
    PILOTE = 'Pilote',
    TECH_INGENIEURS_RESEAU = 'TechIngenieursReseau',
}

export enum UserRoleKey {
    /* eslint-disable @typescript-eslint/naming-convention */
    AdminExterne = 'AdminExterne',
    AdminGCSP = 'AdminGCSP',
    AdminTIC = 'AdminTIC',
    AuditeurExterne = 'AuditeurExterne',
    AuditeurHQD = 'AuditeurHQD',
    ControleurQualiteExterne = 'ControleurQualiteExterne',
    Gestion = 'Gestion',
    InspecteurExterne = 'InspecteurExterne',
    InspecteurHQD = 'InspecteurHQD',
    Pilote = 'Pilote',
    TechIngenieursReseau = 'TechIngenieursReseau',
    /* eslint-enable @typescript-eslint/naming-convention */
}

export enum UserRoleValue {
    /* eslint-disable @typescript-eslint/naming-convention */
    AdminExterne = 'Administrateur externe',
    AdminGCSP = 'Administrateur GCSP',
    AdminTIC = 'Administrateur TIC',
    AuditeurExterne = 'Auditeur externe',
    AuditeurHQD = 'Auditeur HQD',
    ControleurQualiteExterne = 'Controleur qualite externe',
    Gestion = 'Gestion',
    InspecteurExterne = 'Inspecteur externe',
    InspecteurHQD = 'Inspecteur HQD',
    Pilote = 'Pilote',
    TechIngenieursReseau = 'Technicien ingenieurs reseau',
    /* eslint-enable @typescript-eslint/naming-convention */
}
