import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { ComponentBase } from '@ngxhq/common-ui';
import { filter, switchMap, takeUntil } from 'rxjs';
import { AnomalieAuditDto, AnomalieBaseDto, PhotoEntity, PointAuditDto, PointInspectionDto } from '../../../../../core/api/client/models';
import * as PhotosActions from '../../../../../shared/photos/state/photos.actions';
import { arePhotosUploading, getAnomaliesAuditPhotos, getAnomaliesAuditPhotosLoading } from '../../../../../shared/photos/state/photos.selectors';
import { StatutPointAudit } from '../../../../audit/models/statut-point-audit.enum';
import { StoreName } from '../../../../offline/models/indexed-db-store-name.enum';
import { IndexedDbService } from '../../../../offline/services/indexed-db.service';
import { AnomalieAuditData } from '../../../models/anomalie-audit-data.model';
import { State } from '../../../../../state/app.state';
import { selectUtilisateurCanEditPointInspection } from '../../../../../state/shared/shared.selectors';

@Component({
    selector: 'app-anomalie-dialog-audit-data',
    templateUrl: './anomalie-dialog-audit-data.component.html',
    styleUrls: ['./anomalie-dialog-audit-data.component.scss'],
})
export class AnomalieDialogAuditDataComponent extends ComponentBase implements OnDestroy {
    anomaliesAudit: AnomalieAuditData[] = [];
    selectedPointAudit: PointAuditDto;
    selectedPointInspection: PointInspectionDto;

    public photosUploading$ = this.store.select(arePhotosUploading);
    public anomaliesAuditPhotosLoading = false;
    private canEditPointInspection: boolean;

    @Input() isAuditeur = false;

    @Input() set pointInspection(value: PointInspectionDto) {
        if (value) {
            this.selectedPointInspection = value;
            this.initData();
        }
    }
    @Input() set pointAudit(value: PointAuditDto) {
        if (value) {
            this.selectedPointAudit = value;
            this.initData();
        }
    }

    @Output() openAnomalieAuditNonConformeDialog = new EventEmitter();
    @Output() deleteAnomalieAuditNonConforme = new EventEmitter();
    @Output() selectedAnomalie = new EventEmitter<AnomalieAuditData>();

    constructor(
        private dbService: IndexedDbService,
        private store: Store<State>
    ) {
        super();
        this.subscribeToUtilisateur();
    }

    private subscribeToUtilisateur() {
        this.store.select(selectUtilisateurCanEditPointInspection).pipe(
            takeUntil(this.destroyed)
        ).subscribe(canEditPointInspection => this.canEditPointInspection = canEditPointInspection);
    }

    private initData() {
        this.anomaliesAudit = [];
        const anomaliesAudit: AnomalieAuditDto[] = this.getAnomalieAudit();
        this.loadPhotosAndCreateAnomaliesAuditDataForInfoCard(anomaliesAudit);
    }

    private getAnomalieAudit(): AnomalieAuditDto[] {
        return (this.isAuditeur ? this.selectedPointAudit?.anomaliesAudit : this.selectedPointInspection?.pointsAudit[0]?.anomaliesAudit) ?? [];
    }

    public onOpenAnomalieAuditNonConformeDialog(anomalieAudit: AnomalieAuditData) {
        this.selectedAnomalie.emit(anomalieAudit);
        this.openAnomalieAuditNonConformeDialog.emit();
    }

    public onDeleteAnomalieAuditNonConforme(anomalieAudit: AnomalieAuditData) {
        this.selectedAnomalie.emit(anomalieAudit);
        this.deleteAnomalieAuditNonConforme.emit();
    }

    private loadPhotosAndCreateAnomaliesAuditDataForInfoCard(anomaliesAudit: AnomalieAuditDto[]) {
        const concatenatedAnomaliesAuditPhotos = anomaliesAudit.reduce((acc: PhotoEntity[], anomalieAudit) => {
            return acc.concat(anomalieAudit.photos);
        }, []);

        if (concatenatedAnomaliesAuditPhotos?.length > 0) {
            // Ici, s'il est admin, on charge toutes les photos des anomalies audit en provenance du backend
            if (this.canEditPointInspection) {
                this.store.dispatch(PhotosActions.loadAnomaliesAuditPhotos({ photos: concatenatedAnomaliesAuditPhotos }));

                this.store.select(getAnomaliesAuditPhotos)
                    .pipe(
                        takeUntil(this.destroyed)
                    ).subscribe(photosWithData => {
                        if (photosWithData?.length > 0) {
                            this.createAnomaliesDataWithPhotos(anomaliesAudit, photosWithData);
                        }
                    }),

                    this.store.select(getAnomaliesAuditPhotosLoading).pipe(
                        takeUntil(this.destroyed)
                    ).subscribe(loading => this.anomaliesAuditPhotosLoading = loading);

                // Sinon, on récupère les photos de indexedDb
            } else {
                this.anomaliesAuditPhotosLoading = true;

                this.photosUploading$.pipe(
                    filter(isUploading => !isUploading),
                    switchMap(() => this.dbService.getAll<PhotoEntity>(StoreName.PHOTOS)),
                    takeUntil(this.destroyed)
                ).subscribe(photos => {
                    this.anomaliesAuditPhotosLoading = false;
                    this.createAnomaliesDataWithPhotos(anomaliesAudit, photos);
                });
            }
        } else {
            this.createAnomaliesDataWithPhotos(anomaliesAudit, []);
        }
    }

    private createAnomaliesDataWithPhotos(anomaliesAudit: AnomalieAuditDto[], photosWithData: PhotoEntity[]) {
        this.anomaliesAudit = [];
        anomaliesAudit.forEach((anomalieAudit: AnomalieAuditDto) => {
            const currentAnomalieAuditPhotos = photosWithData.filter(photoWithData => {
                return anomalieAudit.photos.some(photoWithoutData => photoWithData.id === photoWithoutData.id);
            });

            if (anomalieAudit.anomalieInspectionId) {
                const anomalieInspection = this.selectedPointInspection.anomalies?.filter
                    ((anomalie: AnomalieBaseDto) => anomalie.id === anomalieAudit.anomalieInspectionId);
                if (anomalieInspection && anomalieInspection.length === 1 && anomalieAudit.statut === StatutPointAudit.nonConforme) {
                    this.anomaliesAudit.push({
                        id: anomalieInspection[0].id,
                        element: anomalieInspection[0].element,
                        type: anomalieInspection[0].type,
                        cause: anomalieInspection[0].cause,
                        auditData: {
                            id: anomalieAudit.id,
                            date: anomalieAudit.auditeLe,
                            info: anomalieAudit.remarque,
                            statut: anomalieAudit.statut,
                            photos: currentAnomalieAuditPhotos
                        }
                    });
                }
            }
        });
    }
}
