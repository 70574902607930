import { Component } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { UserRoleValue } from '../shared/models/user-roles.model';
import { LocalStorageIndex } from '../shared/enums/local-storage-index.enum';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectUtilisateurRoles } from '../state/shared/shared.selectors';
import { BaseComponent } from '../shared/components/abstract-base-component';
import { filter, map, takeUntil } from 'rxjs';
import { setUserActiveRole } from '../state/shared/shared.actions';
import { LocalStorageService } from '../services/local-storage.service';

@Component({
    selector: 'app-select-role',
    templateUrl: './select-role.component.html',
    styleUrls: ['./select-role.component.scss']
})
export class SelectRoleComponent extends BaseComponent {
    public selectedRole: SelectItem = { label: 'Choisir un rôle', value: undefined };
    public options = this.store.select(selectUtilisateurRoles).pipe(
        filter(roles => !!roles),
        map(roles => this.populateOptions(roles)),
        takeUntil(this.destroyed),
    );

    constructor(public ref: DynamicDialogRef, private router: Router, private store: Store, private localStorageService: LocalStorageService) {
        super();
    }

    private populateOptions(roles: string[]) {
        return [
            { label: 'Choisir un rôle', value: undefined, disabled: true },
            ...roles.map(role => {
                return { label: UserRoleValue[role as keyof typeof UserRoleValue], value: role };
            })
        ] as SelectItem<string>[];
    }

    public save() {
        this.localStorageService.setItem(LocalStorageIndex.ACTIVE_ROLE, this.selectedRole.value);
        this.store.dispatch(setUserActiveRole({ role: this.selectedRole.value }));
        void this.router.navigate(['carte']);
    }
}
