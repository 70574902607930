import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SharedState } from './shared.state';
import { UserRole } from '../../shared/models/user-roles.model';
import { getAllTaxonomieState } from '../../core/store/selectors/taxonomie.selectors';
import { getUserClaimAndOpenIdInfo } from '../../core/store/selectors/user.selectors';
import { StatutProjet } from '../../features/projet/models';
import { ProjetAuditDto, ProjetCompletDto } from '../../core/api/client/models';
import { StatutProjetAudit } from '../../features/audit/models/statut-projet-audit.enum';
import { Taxonomie } from '../../features/pilotage/models/taxonomie.model';

const getSharedFeatureState = createFeatureSelector<SharedState>('shared');

export const selectIsAppOnline = createSelector(
    getSharedFeatureState,
    (state: SharedState) => state?.isAppOnline
);

export const selectEsriAccessToken = createSelector(
    getSharedFeatureState,
    (state: SharedState) => state.esriAccessToken
);

export const selectDataExtractionLoading = createSelector(
    getSharedFeatureState,
    (state: SharedState) => state.dataExtractionLoading
);

export const selectDataExtraction = createSelector(
    getSharedFeatureState,
    (state: SharedState) => state.dataExtraction
);

export const selectDataExtractionError = createSelector(
    getSharedFeatureState,
    (state: SharedState) => state.dataExtractionError
);

export const selectStartDataExtraction = createSelector(
    getSharedFeatureState,
    (state: SharedState) => state.startDataExtraction
);

export const selectStartDataExtractionError = createSelector(
    getSharedFeatureState,
    (state: SharedState) => state.startDataExtractionError
);

export const selectAnomaliesPilotage = createSelector(
    getSharedFeatureState,
    (state: SharedState) => state.anomaliesPilotage
);


////////////////////////////////////////
// Utilisateur
////////////////////////////////////////
export const selectIdentiteUtilisateur = createSelector(
    getSharedFeatureState,
    (state: SharedState) => state.identiteUtilisateur
);

export const selectUtilisateurRoles = createSelector(
    selectIdentiteUtilisateur,
    (identiteUtilisateur) => identiteUtilisateur?.groupes as UserRole[] || undefined
);

export const selectUtilisateurHasCaptureRole = createSelector(
    selectUtilisateurRoles,
    (roles: UserRole[] | undefined) => roles ? Object.values(UserRole).some(role => roles.includes(role)) : false
);

export const selectUtilisateurCourriel = createSelector(
    selectIdentiteUtilisateur,
    (identiteUtilisateur) => identiteUtilisateur?.courriel || null
);

export const selectUtilisateurFirme = createSelector(
    selectIdentiteUtilisateur,
    getAllTaxonomieState,
    getUserClaimAndOpenIdInfo,
    (identiteUtilisateur, taxonomieState, userClaim) => {
        const userId = (identiteUtilisateur?.courriel || userClaim?.upn?.toString())?.toLowerCase();
        const userTaxonomie = taxonomieState.find(taxonomie => taxonomie.code.toLowerCase() === userId);

        if (userTaxonomie?.proprietes) {
            const { firmeID } = JSON.parse(userTaxonomie.proprietes);
            const firme = taxonomieState.find(x => x.id.toLowerCase() === firmeID.toLowerCase());

            if (firme) {
                return firme;
            }
        }

        return null;
    }
);

export const selectUtilisateurFirmeName = createSelector(
    selectUtilisateurFirme,
    (firme: Taxonomie) => firme?.code ?? ''
);

export const selectUtilisateurActiveRoles = createSelector(
    getSharedFeatureState,
    (state: SharedState) => state.utilisateurActiveRole ? [state.utilisateurActiveRole as UserRole] : []
);

export const selectUtilisateurIsMobile = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.AUDITEUR_EXTERNE,
        UserRole.AUDITEUR_HQD,
        UserRole.CONTROLEUR_QUALITE_EXTERNE,
        UserRole.INSPECTEUR_EXTERNE,
        UserRole.INSPECTEUR_HQD
    ].some(role => activeRole.includes(role))
);

export const selectUtilisateurIsInspecteur = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.INSPECTEUR_HQD,
        UserRole.INSPECTEUR_EXTERNE
    ].some(role => activeRole.includes(role))
);

export const selectUtilisateurIsAuditeur = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.AUDITEUR_HQD,
        UserRole.AUDITEUR_EXTERNE
    ].some(role => activeRole.includes(role))
);

export const selectUtilisateurIsControleurQualite = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.CONTROLEUR_QUALITE_EXTERNE
    ].some(role => activeRole.includes(role))
);

export const selectUtilisateurIsTechIngenieurReseau = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.TECH_INGENIEURS_RESEAU
    ].some(role => activeRole.includes(role))
);

export const selectUtilisateurIsAdminTic = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.ADMIN_TIC
    ].some(role => activeRole.includes(role))
);

export const selectUtilisateurIsOnlyPilote = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.PILOTE
    ].some(role => activeRole.includes(role))
);

export const selectUtilisateurIsInspecteurOrControleur = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.INSPECTEUR_HQD,
        UserRole.INSPECTEUR_EXTERNE,
        UserRole.CONTROLEUR_QUALITE_EXTERNE
    ].some(role => activeRole.includes(role))
);

export const selectUtilisateurIsPiloteOrAdminTIC = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.PILOTE,
        UserRole.ADMIN_TIC
    ].some(role => activeRole.includes(role))
);

export const selectUtilisateurIsPilotage = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.PILOTE,
        UserRole.ADMIN_TIC,
        UserRole.ADMIN_GCSP
    ].some(role => activeRole.includes(role))
);

export const selectUtilisateurIsAdminExterne = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.ADMIN_EXTERNE
    ].some(role => activeRole.includes(role))
);

export const selectUtilisateurIsAdmin = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.ADMIN_EXTERNE,
        UserRole.ADMIN_GCSP
    ].some(role => activeRole.includes(role))
);

export const selectCanInspecterProjet = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.INSPECTEUR_HQD,
        UserRole.INSPECTEUR_EXTERNE,
        UserRole.CONTROLEUR_QUALITE_EXTERNE
    ].some(role => activeRole.includes(role))
);

export const selectCanCompleteProjet = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.INSPECTEUR_HQD,
        UserRole.INSPECTEUR_EXTERNE
    ].some(role => activeRole.includes(role))
);

export const selectCanOpenCreateProjet = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.PILOTE,
        UserRole.ADMIN_TIC,
        UserRole.ADMIN_EXTERNE,
        UserRole.TECH_INGENIEURS_RESEAU,
        UserRole.ADMIN_GCSP,
    ].some(role => activeRole.includes(role))
);

export const selectCanCreerProjetSansFirme = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.PILOTE,
        UserRole.ADMIN_TIC,
        UserRole.TECH_INGENIEURS_RESEAU
    ].some(role => activeRole.includes(role))
);

export const selectCanChooseFirmeOption = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.ADMIN_GCSP
    ].some(role => activeRole.includes(role))
);

export const selectCanAssignerProjet = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.PILOTE,
        UserRole.ADMIN_TIC,
        UserRole.TECH_INGENIEURS_RESEAU,
        UserRole.ADMIN_EXTERNE,
        UserRole.ADMIN_GCSP,
    ].some(role => activeRole.includes(role))
);

export const selectCanAnnulerProjet = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.PILOTE,
        UserRole.ADMIN_TIC,
        UserRole.TECH_INGENIEURS_RESEAU,
        UserRole.ADMIN_EXTERNE,
        UserRole.ADMIN_GCSP,
    ].some(role => activeRole.includes(role))
);

export const selectCanModifierProjet = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.PILOTE,
        UserRole.ADMIN_TIC,
        UserRole.TECH_INGENIEURS_RESEAU,
        UserRole.ADMIN_EXTERNE,
        UserRole.ADMIN_GCSP,
    ].some(role => activeRole.includes(role))
);

export const selectCanValiderProjet = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.PILOTE,
        UserRole.ADMIN_TIC,
        UserRole.ADMIN_EXTERNE,
    ].some(role => activeRole.includes(role))
);

export const selectCanApprouverProjet = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.PILOTE,
        UserRole.ADMIN_TIC,
        UserRole.TECH_INGENIEURS_RESEAU,
        UserRole.ADMIN_GCSP,
    ].some(role => activeRole.includes(role))
);

export const selectCanEnvoyerAvis = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.PILOTE,
        UserRole.ADMIN_TIC,
        UserRole.TECH_INGENIEURS_RESEAU,
        UserRole.ADMIN_EXTERNE,
    ].some(role => activeRole.includes(role))
);

export const selectCanRapportCreationAvis = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.PILOTE,
        UserRole.ADMIN_TIC,
        UserRole.TECH_INGENIEURS_RESEAU,
        UserRole.ADMIN_EXTERNE,
        UserRole.ADMIN_GCSP,
        UserRole.GESTION,
    ].some(role => activeRole.includes(role))
);

export const selectCanOpenProjetsList = createSelector( // TODO
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.ADMIN_EXTERNE,
        UserRole.ADMIN_GCSP,
        UserRole.ADMIN_TIC,
        UserRole.CONTROLEUR_QUALITE_EXTERNE,
        UserRole.GESTION,
        UserRole.INSPECTEUR_EXTERNE,
        UserRole.INSPECTEUR_HQD,
        UserRole.PILOTE,
        UserRole.TECH_INGENIEURS_RESEAU,
    ].some(role => activeRole.includes(role))
);

export const selectCanOpenProjetInspectionList = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.ADMIN_EXTERNE,
        UserRole.ADMIN_GCSP,
        UserRole.ADMIN_TIC,
        UserRole.CONTROLEUR_QUALITE_EXTERNE,
        UserRole.GESTION,
        UserRole.INSPECTEUR_EXTERNE,
        UserRole.INSPECTEUR_HQD,
        UserRole.PILOTE,
        UserRole.TECH_INGENIEURS_RESEAU,
    ].some(role => activeRole.includes(role))
);

export const selectCanLoadProjetAuditList = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.ADMIN_GCSP,
        UserRole.ADMIN_EXTERNE,
        UserRole.ADMIN_TIC,
        UserRole.PILOTE,
        UserRole.GESTION,
        UserRole.TECH_INGENIEURS_RESEAU,
        UserRole.AUDITEUR_EXTERNE,
        UserRole.AUDITEUR_HQD,
    ].some(role => activeRole.includes(role))
);

export const selectCanOpenProjetAuditList = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.ADMIN_GCSP,
        UserRole.ADMIN_TIC,
        UserRole.PILOTE,
        UserRole.GESTION,
        UserRole.TECH_INGENIEURS_RESEAU,
        UserRole.AUDITEUR_HQD,
        UserRole.AUDITEUR_EXTERNE,
    ].some(role => activeRole.includes(role))
);

export const selectCanCreateProjetAudit = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.ADMIN_GCSP
    ].some(role => activeRole.includes(role))
);

export const selectCanAssignProjetAudit = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.ADMIN_GCSP,
        UserRole.ADMIN_TIC,
        UserRole.PILOTE,
    ].some(role => activeRole.includes(role))
);

export const selectCanCancelProjetAudit = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.ADMIN_GCSP,
        UserRole.ADMIN_TIC,
        UserRole.PILOTE,
    ].some(role => activeRole.includes(role))
);

export const selectCanZoomProjetAudit = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.ADMIN_GCSP,
        UserRole.ADMIN_TIC,
        UserRole.PILOTE,
        UserRole.TECH_INGENIEURS_RESEAU
    ].some(role => activeRole.includes(role))
);

export const selectCanApproveProjetAudit = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.ADMIN_GCSP,
        UserRole.ADMIN_TIC,
        UserRole.PILOTE
    ].some(role => activeRole.includes(role))
);

export const selectCanRejectProjetAudit = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.ADMIN_GCSP,
        UserRole.ADMIN_TIC
    ].some(role => activeRole.includes(role))
);

export const selectCanCompleteProjetAudit = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.AUDITEUR_HQD,
        UserRole.AUDITEUR_EXTERNE,
    ].some(role => activeRole.includes(role))
);

export const selectCanAssignerProjetAQ = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.ADMIN_EXTERNE
    ].some(role => activeRole.includes(role))
);

export const selectCanInspecterProjetAQ = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.CONTROLEUR_QUALITE_EXTERNE
    ].some(role => activeRole.includes(role))
);

export const selectCanCompleteProjetAQ = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.CONTROLEUR_QUALITE_EXTERNE
    ].some(role => activeRole.includes(role))
);

export const selectCanAssignerFirmeProjet = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.ADMIN_GCSP
    ].some(role => activeRole.includes(role))
);

export const selectCanExtractAuditHistory = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.ADMIN_GCSP,
        UserRole.ADMIN_TIC,
        UserRole.PILOTE,
        UserRole.GESTION,
        UserRole.TECH_INGENIEURS_RESEAU
    ].some(role => activeRole.includes(role))
);

export const selectCanOpenCreatePoteauSansAnomalie = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.INSPECTEUR_EXTERNE,
        UserRole.INSPECTEUR_HQD,
        UserRole.CONTROLEUR_QUALITE_EXTERNE
    ].some(role => activeRole.includes(role))
);

export const selectCanAssignerProjetPourCorrection = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.ADMIN_EXTERNE
    ].some(role => activeRole.includes(role))
);

export const selectCanRejeterProjet = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.ADMIN_GCSP,
        UserRole.ADMIN_TIC
    ].some(role => activeRole.includes(role))
);

export const selectCanFetchAnomaliesPilotageData = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.ADMIN_EXTERNE,
        UserRole.ADMIN_GCSP,
        UserRole.AUDITEUR_EXTERNE,
        UserRole.AUDITEUR_HQD,
        UserRole.CONTROLEUR_QUALITE_EXTERNE,
        UserRole.INSPECTEUR_EXTERNE,
        UserRole.INSPECTEUR_HQD,
    ].some(role => activeRole.includes(role))
);

export const selectUtilisateurCanEditPointInspection = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.ADMIN_EXTERNE,
        UserRole.ADMIN_GCSP,
    ].some(role => activeRole.includes(role))
);

export const selectUtilisateurCanAuditer = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.AUDITEUR_HQD,
        UserRole.AUDITEUR_EXTERNE,
        UserRole.ADMIN_GCSP,
    ].some(role => activeRole.includes(role))
);

export const selectUtilisateurIsMarkAsAuditeur = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.ADMIN_GCSP,
        UserRole.AUDITEUR_EXTERNE,
        UserRole.AUDITEUR_HQD
    ].some(role => activeRole.includes(role))
);

export const selectUtilisateurCanEditPointAudit = createSelector(
    selectUtilisateurActiveRoles,
    (activeRole: UserRole[]) => [
        UserRole.ADMIN_GCSP
    ].some(role => activeRole.includes(role))
);

export const selectUtilisateurCanEditPointInspectionByProjet = (projetInspection: ProjetCompletDto) => createSelector(
    selectUtilisateurActiveRoles,
    selectUtilisateurFirmeName,
    (activeRole: UserRole[], utilisateurFirme: string) => {
        return [
            adminExterneCanEditPointInspectionByProjet(projetInspection, activeRole[0], utilisateurFirme),
            adminGCSPCanEditPointInspectionByProjet(projetInspection, activeRole[0], utilisateurFirme),
        ].some(value => value);
    }
);

export const selectUtilisateurCanEditPointAuditByProject = (projectAudit: ProjetAuditDto | undefined) => createSelector(
    selectUtilisateurActiveRoles,
    selectUtilisateurFirmeName,
    (activeRole: UserRole[]) => {
        if (projectAudit) {
            return [
                adminGCSPCanEditPointAuditByProject(projectAudit, activeRole[0]),
            ].some(value => value);
        }

        return false;
    }
);

/** function privé */
function adminExterneCanEditPointInspectionByProjet(projetInspection: ProjetCompletDto, activeRole: UserRole, firme: string): boolean {
    const canModifyStatus: string[] = [StatutProjet.inspectionCompletee, StatutProjet.aqCompletee, StatutProjet.nonConforme, StatutProjet.correctionCompletee];
    return [UserRole.ADMIN_EXTERNE].some(role => activeRole.includes(role)) && firme === projetInspection.firme && canModifyStatus.includes(projetInspection.statut);
}

function adminGCSPCanEditPointInspectionByProjet(projetInspection: ProjetCompletDto, activeRole: UserRole, firme: string): boolean {
    const canModifyStatus: string[] = [StatutProjet.inspectionCompletee, StatutProjet.nonConforme, StatutProjet.correctionCompletee];
    return [UserRole.ADMIN_GCSP].some(role => activeRole.includes(role)) && firme === projetInspection.firme && canModifyStatus.includes(projetInspection.statut);
}

function adminGCSPCanEditPointAuditByProject(projectAudit: ProjetAuditDto, activeRole: UserRole): boolean {
    const canModifyStatus: string[] = [StatutProjetAudit.auditComplete];
    return [UserRole.ADMIN_GCSP].some(role => activeRole.includes(role)) && canModifyStatus.includes(projectAudit.statut);
}
