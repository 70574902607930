<div class="p-d-flex tabs">
    <button type="button" pButton class="btn-toggle" [class.toggled]="currentTab === tabs.equipements"
        (click)="openTab(tabs.equipements)">{{tabValues.equipements}}</button>
    <button type="button" pButton class="btn-toggle" [class.toggled]="currentTab === tabs.anomalie"
        (click)="openTab(tabs.anomalie)" [disabled]="isAnomalieButtonPanelDisabled">{{tabValues.anomalie}}</button>
    <button type="button" *ngIf="showAnomaliesAuditData" pButton class="btn-toggle"
        [class.toggled]="currentTab === tabs.audit" (click)="openTab(tabs.audit)">{{tabValues.audit}}</button>
</div>
<div class="tabs_container">
    <ng-container *ngIf="currentTab === tabs.equipements">
        <div [formGroup]="equipementForm" *ngIf="equipementForm">
            <table class="equipement-table">
                <tr>
                    <th>Présence de luminaire:</th>
                    <td>
                        <p-selectButton [options]="selectionButtonOptions" formControlName="luminaire"
                            optionLabel="label" optionValue="value"></p-selectButton>
                    </td>
                </tr>
                <tr>
                    <th>Présence de mise à la terre:</th>
                    <td>
                        <p-selectButton [options]="selectionButtonOptions" formControlName="presenceMalt"
                            optionLabel="label" optionValue="value"></p-selectButton>
                    </td>
                </tr>
                <tr>
                    <th>Présence de jambe de force:</th>
                    <td>
                        <p-selectButton [options]="selectionButtonOptions" formControlName="presenceJambeDeForce"
                            optionLabel="label" optionValue="value"></p-selectButton>
                    </td>
                </tr>
                <tr>
                    <th>Présence de panneau:</th>
                    <td>
                        <p-selectButton [options]="selectionButtonOptions" formControlName="presencePanneau"
                            optionLabel="label" optionValue="value"></p-selectButton>
                    </td>
                </tr>
                <tr>
                    <th>Présence de tuteur:</th>
                    <td>
                        <p-selectButton [options]="selectionButtonOptions" formControlName="presenceTuteur"
                            optionLabel="label" optionValue="value"></p-selectButton>
                    </td>
                </tr>
                <tr>
                    <th>Présence de las:</th>
                    <td>
                        <p-selectButton [options]="selectionButtonOptions" formControlName="presenceLAS"
                            optionLabel="label" optionValue="value"></p-selectButton>
                    </td>
                </tr>
            </table>
        </div>
        <div class="footer">
            <button type="button" pButton label="Annuler" class="btn-annuler p-mr-1" (click)="cancelEquipement()"
                [disabled]="equipementForm.pristine"></button>
            <button type="button" pButton label="Enregistrer" class="btn-enregistrer p-ml-1" (click)="saveEquipement()"
                [disabled]="equipementForm.pristine || equipementForm.invalid"></button>
        </div>
    </ng-container>

    <ng-container *ngIf="currentTab === tabs.anomalie">
        <div class="table__container" *ngIf="anomalies$ | async as anomalies">
            <p-table [value]="anomalies" selectionMode="single" [(selection)]="selectedAnomalie" dataKey="id"
                responsiveLayout="scroll" styleClass="full-table-mobile" class="anomalie-table"
                [loading]="photosLoading">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Élément</th>
                        <th>Type</th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-anomalie let-rowIndex="rowIndex">
                    <tr [pSelectableRow]="anomalie" [pSelectableRowDisabled]="photosLoading"
                        (touchstart)="getAnomalieInfo(anomalie)" (click)="getAnomalieInfo(anomalie)">
                        <td>{{anomalie.element}}</td>
                        <td>{{anomalie.type}}</td>
                        <td class="p-text-center">
                            <button *ngIf="canEdit" type="button" class="p-button p-button-icon-only"
                                (click)="deleteAnomalie(anomalie); $event.stopPropagation()"
                                (touchstart)="deleteAnomalie(anomalie); $event.stopPropagation()">
                                X
                            </button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <div class="button-container">
                <div *ngIf="photosLoading" class="button-container_overlay"></div>
                <div class="bouton-ajouter p-mb-1">
                    <button *ngIf="canEdit" type="button" pButton class="p-button-rounded custom-bt fab-bt"
                        icon="pi pi-plus" (click)="initCreateAnomalie()" [disabled]="photosLoading"></button>
                </div>
            </div>
        </div>
        <div class="anomalie-info" [formGroup]="form" *ngIf="form && modeCreation">
            <table class="vertical-table">
                <tr>
                    <th>Élément<span class="required"> *</span></th>
                    <td>
                        <p-dropdown [options]="elementList" placeholder="Élément" formControlName="element"
                            [disabled]="isElementTypeCauseDisabled" appendTo="body" emptyMessage="Aucun résultat">
                        </p-dropdown>
                    </td>
                </tr>
                <tr>
                    <th>Type<span class="required"> *</span></th>
                    <td>
                        <p-dropdown [options]="typeList" placeholder="Type" formControlName="type"
                            [disabled]="isElementTypeCauseDisabled" appendTo="body" emptyMessage="Aucun résultat">
                        </p-dropdown>
                    </td>
                </tr>
                <tr>
                    <th>Cause<span class="required"> *</span></th>
                    <td>
                        <p-dropdown [options]="causeList" placeholder="Cause" formControlName="cause"
                            [disabled]="isElementTypeCauseDisabled" appendTo="body" emptyMessage="Aucun résultat">
                        </p-dropdown>
                    </td>
                </tr>
                <tr>
                    <th>Information</th>
                    <td>{{detail}}</td>
                </tr>
                <tr>
                    <td colspan="2">
                        <div class="photos">
                            <span class="p-column-title photos__title">Photos *</span>
                            <div *ngIf="modeEdition" class="photos__list">
                                <app-photos-container (removePhoto)="removePhoto($event)" [canEdit]="canEdit"
                                    [loading]="photosLoading || (photosUploading$ | async)"
                                    [photos]="photosWithData"></app-photos-container>
                            </div>
                            <div *ngIf="canEdit" class="photos__upload">
                                <app-photos-upload [currentPhotos]="photosWithData" (upload)="onUploadPhotos($event)"
                                    [maxPhotos]="maxPhotoAnomalie">
                                </app-photos-upload>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>Remarque</th>
                    <td>
                        <input pInputText type="text" formControlName="remarque" styleClass="input-inline"
                            maxlength="100">
                    </td>
                </tr>
                <tr>
                    <th>Urgent</th>
                    <td>
                        <p-checkbox formControlName="urgent" [binary]="true" inputId="binary"></p-checkbox>
                    </td>
                </tr>
            </table>

            <div *ngIf="!isAuditeur" class="p-d-flex">
                <button type="button" pButton class="btn-annuler p-mr-1" label="Annuler"
                    (click)="cancelCreateAnomalie()" [disabled]="photosLoading"></button>
                <button type="button" pButton class="btn-enregistrer p-ml-1" label="Enregistrer"
                    (click)="saveAnomalie()"
                    [disabled]="form.invalid || photosLoading || uploadedPhotoLoading"></button>
            </div>

            <div *ngIf="isAuditeur" class="footer-button">
                <button pButton type="button" label="Non conforme" class="btn-non-conforme"
                    (click)="onAnomalieAuditNonConforme()" [disabled]="isNonConformeButtonDisable()"></button>
                <button pButton type="button" label="Conforme" class="btn-conforme" (click)="onAnomalieAuditConforme()"
                    [disabled]="isConformeButtonDisable()"></button>
            </div>

        </div>
    </ng-container>
    <ng-container *ngIf="currentTab === tabs.audit">
        <app-anomalie-dialog-audit-data [isAuditeur]="isAuditeur" [pointInspection]="selectedPointInspection"
            [pointAudit]="selectedPointAudit" (openAnomalieAuditNonConformeDialog)="onAnomalieAuditNonConforme()"
            (deleteAnomalieAuditNonConforme)="deleteAnomalieAuditNonConformeConfirm()"
            (selectedAnomalie)="updateSelectedAnomalie($event)">
        </app-anomalie-dialog-audit-data>
    </ng-container>
</div>

<app-anomalie-audit-non-conforme-dialog *ngIf="selectedAnomalie && selectedPointAudit" [pointAudit]="selectedPointAudit"
    [anomalie]="selectedAnomalie" [currentIdentiteUtilisateur]="currentIdentiteUtilisateur"
    [(visible)]="anomalieAuditNonConformeDialogVisible" (closeSelectedAnomalie)="closeSelectedAnomalie()"
    (closeAnomalieDialog)="closeAnomalieDialog()">
</app-anomalie-audit-non-conforme-dialog>

<p-confirmDialog class="line-break" #cd header="Supprimer une anomalie" icon="pi pi-exclamation-triangle"
    key='deleteAnomalie'>
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-times" class="p-button-text p-mr-4" label="Non"
            (click)="cd.reject()"></button>
        <button type="button" pButton icon="pi pi-check" class="p-button-text p-ml-4" label="Oui"
            (click)="cd.accept()"></button>
    </ng-template>
</p-confirmDialog>

<p-confirmDialog class="line-break" #cdDelete header="Supprimer une non-conformité" icon="pi pi-exclamation-triangle"
    key='deleteAnomalieAuditNonConforme' appendTo="body">
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-times" class="p-button-text p-mr-4" label="Non"
            (click)="cdDelete.reject()"></button>
        <button type="button" pButton icon="pi pi-check" class="p-button-text p-ml-4" label="Oui"
            (click)="cdDelete.accept()"></button>
    </ng-template>
</p-confirmDialog>

<p-confirmDialog class="line-break" #das header="Important" icon="pi pi-exclamation-triangle" key='deleteAnomalieSap'>
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-times" class="p-button-text p-mr-1" label="Annuler"
            (click)="das.reject()"></button>
        <button type="button" pButton icon="pi pi-check" class="p-button-text p-ml-1" label="Poursuivre la suppression"
            (click)="das.accept()"></button>
    </ng-template>
</p-confirmDialog>

<p-confirmDialog class="line-break" #cma header="Important" icon="pi pi-exclamation-triangle"
    key='modificationAnomalie'>
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-times" class="p-button-text p-mr-1" label="Annuler"
            (click)="cma.reject()"></button>
        <button type="button" pButton icon="pi pi-check" class="p-button-text p-ml-1" label="Poursuivre la modification"
            (click)="cma.accept()"></button>
    </ng-template>
</p-confirmDialog>