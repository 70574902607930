import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuditState } from './audit.state';

const getAuditFeatureState = createFeatureSelector<AuditState>('audit');

export const getProjetAuditList = createSelector(
    getAuditFeatureState,
    state => state.projetAuditList!
);

export const getProjetAuditListLoading = createSelector(
    getAuditFeatureState,
    state => state.projetAuditListLoading
);

export const getLoadProjetAuditListError = createSelector(
    getAuditFeatureState,
    state => state.loadProjetAuditListError
);

export const getProjetAuditHistory = createSelector(
    getAuditFeatureState,
    state => state.projetAuditHistory
);

export const getProjetAuditHistoryError = createSelector(
    getAuditFeatureState,
    state => state.projetAuditHistoryError
);

export const getCreateProjetAuditLoading = createSelector(
    getAuditFeatureState,
    state => state.createProjetAuditLoading
);

export const getCreateProjetAuditSuccess = createSelector(
    getAuditFeatureState,
    state => {
        return {
            success: state.createProjetAuditSuccess,
            projetAudit: state.createProjetAudit
        };
    }
);

export const getCreateProjetAuditError = createSelector(
    getAuditFeatureState,
    state => state.createProjetAuditError
);

export const getAssignProjetAuditSuccess = createSelector(
    getAuditFeatureState,
    state => {
        return {
            success: state.assignProjetAuditSuccess,
            projetAudit: state.assignProjetAudit
        };
    }
);

export const getAssignProjetAuditError = createSelector(
    getAuditFeatureState,
    state => state.assignProjetAuditError
);

export const getActivateProjetAuditSuccess = createSelector(
    getAuditFeatureState,
    state => {
        return {
            success: state.activateProjetAuditSuccess,
            projetAudit: state.activateProjetAudit
        };
    }
);

export const getActivateProjetAuditError = createSelector(
    getAuditFeatureState,
    state => state.activateProjetAuditError
);

export const getCurrentActiveProjetAudit = createSelector(
    getAuditFeatureState,
    state => state.currentActiveProjetAudit
);

export const getSelectedPointAudit = createSelector(
    getAuditFeatureState,
    state => state.selectedPointAudit
);

export const getCancelProjetAuditSuccess = createSelector(
    getAuditFeatureState,
    state => {
        return {
            success: state.cancelProjetAuditSuccess,
            projetAudit: state.cancelProjetAudit
        };
    }
);

export const getCancelProjetAuditError = createSelector(
    getAuditFeatureState,
    state => state.cancelProjetAuditError
);

export const getRejectProjetAuditSuccess = createSelector(
    getAuditFeatureState,
    state => {
        return {
            success: state.rejectProjetAuditSuccess,
            projetAudit: state.rejectProjetAudit
        };
    }
);

export const getCompleteProjetAuditSuccess = createSelector(
    getAuditFeatureState,
    state => state.completeProjetAudit
);

export const getCompleteProjetAuditError = createSelector(
    getAuditFeatureState,
    state => state.completeProjetAuditError
);

export const getCompleteProjetAuditLoading = createSelector(
    getAuditFeatureState,
    state => state.completeProjetAuditLoading
);

export const getApproveProjetAuditSuccess = createSelector(
    getAuditFeatureState,
    state => {
        return {
            success: state.approveProjetAuditSuccess,
            projetAudit: state.approveProjetAudit
        };
    }
);

export const getApproveProjetAuditError = createSelector(
    getAuditFeatureState,
    state => state.approveProjetAuditError
);

export const getRejectProjetAuditError = createSelector(
    getAuditFeatureState,
    state => state.rejectProjetAuditError
);

export const getAddPointAuditSuccess = createSelector(
    getAuditFeatureState,
    state => state.addPointAudit
);

export const getCreatePointAuditSuccess = createSelector(
    getAuditFeatureState,
    state => state.createPointAudit
);

export const getUpdatePointAuditSuccess = createSelector(
    getAuditFeatureState,
    state => state.updatePointAudit
);

export const getUpdatePointAuditError = createSelector(
    getAuditFeatureState,
    state => state.updatePointAuditError
);

export const getPointsAuditBBoxSuccess = createSelector(
    getAuditFeatureState,
    state => state.pointsAudit
);

export const getPointsAuditLoading = createSelector(
    getAuditFeatureState,
    state => state.pointsAuditLoading
);

export const getPointsActiveProjetAuditSuccess = createSelector(
    getAuditFeatureState,
    state => state.pointsAudit
);

export const getProjetAuditById = (projetAuditId: string) => createSelector(
    getAuditFeatureState,
    (state) => state.projetAuditList?.find(projet => projet.id === projetAuditId)
);

export const getPointAuditById = (pointAuditId: string) => createSelector(
    getAuditFeatureState,
    state => state.pointsAudit?.find(point => point.id === pointAuditId)
);

export const getPointAuditByInspectionId = (pointInspectionId: string) => createSelector(
    getAuditFeatureState,
    state => state.pointsAudit?.filter(pointAudit => pointAudit.pointInspectionId === pointInspectionId) || []
);
