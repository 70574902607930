import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-pop-up-info-equipements-mineurs',
    templateUrl: './pop-up-info-equipements-mineurs.component.html',
    styleUrls: ['./pop-up-info-equipements-mineurs.component.scss']
})
export class PopUpInfoEquipementsMineursComponent implements OnChanges {
    public data: { [name: string]: string } = {};

    @Input() equipementsMineurs: { [name: string]: string } = {};

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.equipementsMineurs) {
            this.data = changes.equipementsMineurs.currentValue;
        }
    }
}
