import { Injectable } from '@angular/core';
import { LocalStorageIndex } from '../shared/enums/local-storage-index.enum';
import { UserRole } from '../shared/models/user-roles.model';

@Injectable()
export class LocalStorageService { // TODO DELETE THIS SERVICE WHEN PDL-655 IS DONE
    public setItem(key: string, value: string): void {
        window.localStorage.setItem(key, value);
    }

    public getItem(key: string): string | null {
        return window.localStorage.getItem(key);
    }

    public removeItem(key: string): void {
        window.localStorage.removeItem(key);
    }

    public hasCaptureRoleFromClaim() {
        const localStorageRoles = this.getItem(LocalStorageIndex.USER_ROLES);
        if (!localStorageRoles) {
            return false;
        }
        return Object.values(UserRole).some(role => localStorageRoles.includes(role));
    }
}
