import { Action, createReducer, on } from '@ngrx/store';
import * as ProjetsActions from './projets.actions';
import { ProjetsState } from './projets.state';
import { StatutProjet } from '../../inspection/models/statut-projet.enum';

export const projetsInitialState: ProjetsState = {
    projetsList: null,
    projetsListLoading: false,
    projetsListError: null,
    createProjetInspection: null,
    createProjetInspectionSuccess: false,
    createProjetInspectionError: null,
    assignProjetInspectionSuccess: false,
    assignProjetInspectionError: null,
    validateProjetInspectionSuccess: false,
    validateProjetInspectionError: null,
    approveProjetInspectionSuccess: false,
    approveProjetInspectionError: null,
    rejectProjetInspectionSuccess: false,
    rejectProjetInspectionError: null,
    updateProjetInspectionSuccess: false,
    updateProjetInspectionError: null,
    createAvisSap: null,
    createAvisSapSuccess: false,
    createAvisSapError: null,
    cancelProjetInspectionSuccess: false,
    cancelProjetInspectionError: null,
    deleteProjetInspectionSuccess: false,
    deleteProjetInspectionError: null,
    projetInspectionHistory: null,
    projetInspectionHistorySuccess: false,
    projetInspectionHistoryError: null,

    // Audits //
    projetAuditList: null,
    projetAuditListLoading: false,
    projetAuditListError: null,
    createProjetAudit: null,
    createProjetAuditLoading: false,
    createProjetAuditSuccess: false,
    createProjetAuditError: null,
    assignProjetAuditSuccess: false,
    assignProjetAuditError: null,
    approveProjetAuditSuccess: false,
    approveProjetAuditError: null,
    cancelProjetAuditSuccess: false,
    cancelProjetAuditError: null,
    rejectProjetAuditSuccess: false,
    rejectProjetAuditError: null,
    projetAuditHistory: null,
    projetAuditHistorySuccess: false,
    projetAuditHistoryError: null,
};

export const projetsReducer = createReducer<ProjetsState>(
    projetsInitialState,
    on(ProjetsActions.loadProjetsList, (state) => {
        return {
            ...state,
            projetsList: null,
            projetsListLoading: true,
            projetsListError: null
        };
    }),
    on(ProjetsActions.loadProjetsListSuccess, (state, action) => {
        return {
            ...state,
            projetsList: action.projetsList,
            projetsListLoading: false,
            projetsListError: null
        };
    }),
    on(ProjetsActions.loadProjetsListFailure, (state, action) => {
        return {
            ...state,
            projetsList: null,
            projetsListLoading: false,
            projetsListError: action.error,
        };
    }),
    on(ProjetsActions.loadProjetInspectionHistory, (state) => {
        return {
            ...state,
            projetInspectionHistory: null,
            projetInspectionHistorySuccess: false,
            projetInspectionHistoryError: null,
        };
    }),
    on(ProjetsActions.loadProjetInspectionHistorySuccess, (state, action) => {
        const updatedProjetInspectionHistory = action.projetInspectionHistory.map(projet => ({
            ...projet,
            nom: action.nomProjet
        }));

        return {
            ...state,
            projetInspectionHistory: updatedProjetInspectionHistory,
            projetInspectionHistorySuccess: true,
            projetInspectionHistoryError: null
        };
    }),
    on(ProjetsActions.loadProjetInspectionHistoryFailure, (state, action) => {
        return {
            ...state,
            projetInspectionHistory: null,
            projetInspectionHistorySuccess: false,
            projetInspectionHistoryError: action.error
        };
    }),
    on(ProjetsActions.startCreateProjetInspection, (state) => {
        return {
            ...state,
            createProjetInspection: null,
            createProjetInspectionSuccess: false,
            createProjetInspectionError: null
        };
    }),
    on(ProjetsActions.createProjetInspectionSuccess, (state, action) => {
        return {
            ...state,
            createProjetInspection: action.createProjetInspection,
            createProjetInspectionSuccess: true,
        };
    }),
    on(ProjetsActions.createProjetInspectionFailure, (state, action) => {
        return {
            ...state,
            createProjetInspectionSuccess: false,
            createProjetInspectionError: action.error
        };
    }),
    on(ProjetsActions.assignProjetInspection, (state) => {
        return {
            ...state,
            assignProjetInspectionSuccess: false,
            assignProjetInspectionError: null,
        };
    }),
    on(ProjetsActions.assignProjetInspectionSuccess, (state, action) => {
        const updatedProjetsList = state.projetsList.map(projet =>
            projet.id === action.projetInspection.id ? action.projetInspection : projet
        );

        return {
            ...state,
            projetsList: updatedProjetsList,
            assignProjetInspectionSuccess: true,
            assignProjetInspectionError: null,
        };
    }),
    on(ProjetsActions.assignProjetInspectionFailure, (state, action) => {
        return {
            ...state,
            assignProjetInspectionSuccess: false,
            assignProjetInspectionError: action.error,
        };
    }),
    on(ProjetsActions.validateProjetInspection, (state) => {
        return {
            ...state,
            validateProjetInspectionSuccess: false,
            validateProjetInspectionError: null,
        };
    }),
    on(ProjetsActions.validateProjetInspectionSuccess, (state, action) => {
        const updatedProjetsList = state.projetsList.map(projet =>
            projet.id === action.projetInspection.id ? action.projetInspection : projet
        );

        return {
            ...state,
            projetsList: updatedProjetsList,
            validateProjetInspectionSuccess: true,
            validateProjetInspectionError: null,
        };
    }),
    on(ProjetsActions.validateProjetInspectionFailure, (state, action) => {
        return {
            ...state,
            validateProjetInspectionSuccess: false,
            validateProjetInspectionError: action.error,
        };
    }),

    on(ProjetsActions.cancelProjetInspection, (state) => {
        return {
            ...state,
            cancelProjetInspectionSuccess: false,
            cancelProjetInspectionError: null,
        };
    }),
    on(ProjetsActions.cancelProjetInspectionSuccess, (state, action) => {
        const updatedProjetsList = state.projetsList.map(projet =>
            projet.id === action.projetInspection.id ? action.projetInspection : projet
        );

        return {
            ...state,
            projetsList: updatedProjetsList,
            cancelProjetInspectionSuccess: true,
            cancelProjetInspectionError: null,
        };
    }),
    on(ProjetsActions.cancelProjetInspectionFailure, (state, action) => {
        return {
            ...state,
            cancelProjetInspectionSuccess: false,
            cancelProjetInspectionError: action.error,
        };
    }),
    on(ProjetsActions.deleteProjetInspection, (state) => {
        return {
            ...state,
            deleteProjetInspectionSuccess: false,
            deleteProjetInspectionError: null,
        };
    }),
    on(ProjetsActions.deleteProjetInspectionSuccess, (state, action) => {
        const updatedProjetsList = state.projetsList.filter(projet =>
            projet.id !== action.projetInspectionId
        );

        return {
            ...state,
            projetsList: updatedProjetsList,
            deleteProjetInspectionSuccess: true,
            deleteProjetInspectionError: null,
        };
    }),
    on(ProjetsActions.deleteProjetInspectionFailure, (state, action) => {
        return {
            ...state,
            deleteProjetInspectionSuccess: false,
            deleteProjetInspectionError: action.error,
        };
    }),
    on(ProjetsActions.approveProjetInspection, (state) => {
        return {
            ...state,
            approveProjetInspectionSuccess: false,
            approveProjetInspectionError: null,
        };
    }),
    on(ProjetsActions.approveProjetInspectionSuccess, (state, action) => {
        const updatedProjetsList = state.projetsList.map(projet =>
            projet.id === action.projetInspection.id ? action.projetInspection : projet
        );

        return {
            ...state,
            projetsList: updatedProjetsList,
            approveProjetInspectionSuccess: true,
            approveProjetInspectionError: null,
        };
    }),
    on(ProjetsActions.approveProjetInspectionFailure, (state, action) => {
        return {
            ...state,
            approveProjetInspectionSuccess: false,
            approveProjetInspectionError: action.error,
        };
    }),
    on(ProjetsActions.rejectProjetInspection, (state) => {
        return {
            ...state,
            rejectProjetInspectionSuccess: false,
            rejectProjetInspectionError: null,
        };
    }),
    on(ProjetsActions.rejectProjetInspectionSuccess, (state, action) => {
        const updatedProjetsList = state.projetsList.map(projet =>
            projet.id === action.projetInspection.id ? action.projetInspection : projet
        );

        return {
            ...state,
            projetsList: updatedProjetsList,
            rejectProjetInspectionSuccess: true,
            rejectProjetInspectionError: null,
        };
    }),
    on(ProjetsActions.rejectProjetInspectionFailure, (state, action) => {
        return {
            ...state,
            rejectProjetInspectionSuccess: false,
            rejectProjetInspectionError: action.error,
        };
    }),
    on(ProjetsActions.updateProjetInspection, (state) => {
        return {
            ...state,
            updateProjetInspectionSuccess: false,
            updateProjetInspectionError: null,
        };
    }),
    on(ProjetsActions.updateProjetInspectionSuccess, (state, action) => {
        const updatedProjetsList = state.projetsList.map(projet =>
            projet.id === action.projetInspection.id ? action.projetInspection : projet
        );

        return {
            ...state,
            projetsList: updatedProjetsList,
            updateProjetInspectionSuccess: true,
            updateProjetInspectionError: null,
        };
    }),
    on(ProjetsActions.updateProjetInspectionFailure, (state, action) => {
        return {
            ...state,
            updateProjetInspectionSuccess: false,
            updateProjetInspectionError: action.error,
        };
    }),
    on(ProjetsActions.createAvisSap, (state) => {
        return {
            ...state,
            createAvisSap: null,
            createAvisSapSuccess: false,
            createAvisSapError: null,
        };
    }),
    on(ProjetsActions.createAvisSapSuccess, (state, action) => {
        return {
            ...state,
            createAvisSap: action.createAvisSap,
            createAvisSapSuccess: true,
            createAvisSapError: null,
        };
    }),
    on(ProjetsActions.createAvisSapFailure, (state, action) => {
        return {
            ...state,
            createAvisSap: null,
            createAvisSapSuccess: false,
            createAvisSapError: action.error,
        };
    }),

    /////////////////////////////////
    ////////////  Audit  ////////////
    /////////////////////////////////
    on(ProjetsActions.startCreateProjetAudit, (state) => {
        return {
            ...state,
            createProjetAudit: null,
            createProjetAuditLoading: true,
            createProjetAuditSuccess: false,
            createProjetAuditError: null
        };
    }),
    on(ProjetsActions.createProjetAuditSuccess, (state, action) => {
        const updatedProjetsList = state.projetsList.map(projet => {
            if (projet.id === action.createProjetAudit.projetId) {
                return {
                    ...projet,
                    statut: StatutProjet.auditEnCours,
                    audits: [...projet.audits, action.createProjetAudit]
                };
            } else {
                return projet;
            }
        });

        const updatedProjetAuditList = state.projetAuditList?.map(projetAudit =>
            projetAudit.id === action.createProjetAudit.id ? action.createProjetAudit : projetAudit
        );

        return {
            ...state,
            projetsList: updatedProjetsList,
            projetAuditList: updatedProjetAuditList,
            createProjetAudit: action.createProjetAudit,
            createProjetAuditLoading: false,
            createProjetAuditSuccess: true,
        };
    }),
    on(ProjetsActions.createProjetAuditFailure, (state, action) => {
        return {
            ...state,
            createProjetAuditLoading: false,
            createProjetAuditSuccess: false,
            createProjetAuditError: action.error
        };
    }),
    on(ProjetsActions.loadProjetAuditList, (state) => {
        return {
            ...state,
            projetAuditList: null,
            projetAuditListLoading: true,
            projetAuditListError: null
        };
    }),
    on(ProjetsActions.loadProjetAuditListSuccess, (state, action) => {
        return {
            ...state,
            projetAuditList: action.projetAuditList,
            projetAuditListLoading: false,
            projetAuditListError: null
        };
    }),
    on(ProjetsActions.loadProjetAuditListFailure, (state, action) => {
        return {
            ...state,
            projetAuditList: null,
            projetAuditListLoading: false,
            projetAuditListError: action.error,
        };
    }),
    on(ProjetsActions.assignProjetAudit, (state) => {
        return {
            ...state,
            assignProjetAuditSuccess: false,
            assignProjetAuditError: null,
        };
    }),
    on(ProjetsActions.assignProjetAuditSuccess, (state, action) => {
        const projetList = state.projetsList.map(projet =>
            projet.id === action.projetAudit.projetId
                ? { ...projet, audits: projet.audits.map(audit => audit.id === action.projetAudit.id ? action.projetAudit : audit) }
                : projet
        );
        return {
            ...state,
            projetsList: projetList,
            projetAuditList: state.projetAuditList?.map(projetAudit =>
                projetAudit.id === action.projetAudit.id ? action.projetAudit : projetAudit
            ),
            assignProjetAuditSuccess: true,
            assignProjetAuditError: null,
        };
    }),
    on(ProjetsActions.assignProjetAuditFailure, (state, action) => {
        return {
            ...state,
            assignProjetAuditSuccess: false,
            assignProjetAuditErrorr: action.error,
        };
    }),

    on(ProjetsActions.approveProjetAudit, (state) => {
        return {
            ...state,
            approveProjetAuditSuccess: false,
            approveProjetAuditError: null,
        };
    }),
    on(ProjetsActions.approveProjetAuditSuccess, (state, action) => {
        const projetList = state.projetsList.map(projet =>
            projet.id === action.projetAudit.projetId
                ? { ...projet, audits: projet.audits.map(audit => audit.id === action.projetAudit.id ? action.projetAudit : audit) }
                : projet
        );
        return {
            ...state,
            projetsList: projetList,
            projetAuditList: state.projetAuditList?.map(projetAudit =>
                projetAudit.id === action.projetAudit.id ? action.projetAudit : projetAudit
            ),
            approveProjetAuditSuccess: true,
            approveProjetAuditError: null,
        };
    }),
    on(ProjetsActions.approveProjetAuditFailure, (state, action) => {
        return {
            ...state,
            approveProjetAuditSuccess: false,
            approveProjetAuditError: action.error,
        };
    }),
    on(ProjetsActions.cancelProjetAudit, (state) => {
        return {
            ...state,
            cancelProjetAuditSuccess: false,
            cancelProjetAuditError: null
        };
    }),
    on(ProjetsActions.cancelProjetAuditSuccess, (state, action) => {
        const projetList = state.projetsList.map(projet =>
            projet.id === action.projetAudit.projetId
                ? { ...projet, audits: projet.audits.map(audit => audit.id === action.projetAudit.id ? action.projetAudit : audit) }
                : projet
        );
        return {
            ...state,
            projetsList: projetList,
            projetAuditList: state.projetAuditList?.map(projetAudit =>
                projetAudit.id === action.projetAudit.id ? action.projetAudit : projetAudit
            ),
            cancelProjetAuditSuccess: true,
            cancelProjetAuditError: null,
        };
    }),
    on(ProjetsActions.cancelProjetAuditFailure, (state, action) => {
        return {
            ...state,
            cancelProjetAuditSuccess: false,
            cancelProjetAuditError: action.error,
        };
    }),
    on(ProjetsActions.rejectProjetAudit, (state) => {
        return {
            ...state,
            rejectProjetAuditSuccess: false,
            rejectProjetAuditError: null,
        };
    }),
    on(ProjetsActions.rejectProjetAuditSuccess, (state, action) => {
        const projetList = state.projetsList.map(projet =>
            projet.id === action.projetAudit.projetId
                ? { ...projet, audits: projet.audits.map(audit => audit.id === action.projetAudit.id ? action.projetAudit : audit) }
                : projet
        );
        return {
            ...state,
            projetsList: projetList,
            projetAuditList: state.projetAuditList?.map(projetAudit =>
                projetAudit.id === action.projetAudit.id ? action.projetAudit : projetAudit
            ),
            rejectProjetAuditSuccess: true,
            rejectProjetAuditError: null,
        };
    }),
    on(ProjetsActions.rejectProjetAuditFailure, (state, action) => {
        return {
            ...state,
            rejectProjetAuditSuccess: false,
            rejectProjetAuditError: action.error,
        };
    }),
    on(ProjetsActions.loadProjetAuditHistory, (state) => {
        return {
            ...state,
            projetAuditHistory: null,
            projetAuditHistorySuccess: false,
            projetAuditHistoryError: null,
        };
    }),
    on(ProjetsActions.loadProjetAuditHistorySuccess, (state, action) => {
        return {
            ...state,
            projetAuditHistory: action.projetAuditHistory,
            projetAuditHistorySuccess: true,
            projetAuditHistoryError: null,
        };
    }),
    on(ProjetsActions.loadProjetAuditHistoryFailure, (state, action) => {
        return {
            ...state,
            projetAuditHistory: null,
            projetAuditHistorySuccess: false,
            projetAuditHistoryError: action.error,
        };
    })
);

export function reducer(state: any, action: Action) {
    return projetsReducer(state, action);
}

